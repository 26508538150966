import React from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { useSize } from 'react-hook-size';
import cn from 'classnames/bind';
import useSWR from '@zeit/swr';
import { Link } from 'react-router-dom';

import Sprite from '../../../../components/Sprite';

import axios from '../../../../utils/axios';
import styles from './PreviewTable.module.css';
import { isAdminOrModer } from '../../../../utils/user-role';

const cx = cn.bind(styles);

function PreviewTable(props) {
  const ref = React.useRef(null);
  let { height } = useSize(ref);
  const { data: user } = useSWR('/users/me/', axios);

  function handleRowClick(id) {
    props.history.push(`${props.linkUrl}/${props.links[id]}`);
  }

  return (
    <div>
      <section className={cx({ wrapper: true })}>
        <div className={cx({ header: true })}>
          <h2 className={cx({ title: true })}>
            <Link to={props.url}>{props.title}</Link>
          </h2>

          {isAdminOrModer(user?.data?.role) && (
            <Link className={cx({ link: true })} to={props.createLink}>
              <Sprite role="img" name="plus" />
            </Link>
          )}
        </div>

        {props.results ? (
          <div className={cx({ content: true })}>
            <Scrollbar noScrollY style={{ height }}>
              <table ref={ref} className={cx({ table: true })}>
                <thead>
                  <tr>
                    {props.headings.map((heading, id) => (
                      <th key={id} className={cx({ cell: true, 'cell-heading': true })}>
                        {heading}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {props.results.map((row, id) => (
                    <tr
                      key={id}
                      onClick={() => handleRowClick(id)}
                      className={cx({ 'row-content': true })}
                    >
                      {row.map((cell, id) => (
                        <td
                          key={id}
                          className={cx({ cell: true, 'cell-content': true }, props.cellClassName)}
                        >
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Scrollbar>
          </div>
        ) : (
          <p className={cx({ placeholder: true })}>{props.plaeholder}</p>
        )}
      </section>
    </div>
  );
}

export default PreviewTable;
