import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PrivateRoute from './components/PrivateRoute';
import Auth from './modules/auth/containers/Auth';
import Base from './modules/main/containers/Base/Base';

function App() {
  const token = useSelector((state) => state.logIn.token);
  const isAuth = token !== null;

  return (
    <Switch>
      <Route exact={!isAuth} path="/">
        <PrivateRoute isAccess={isAuth} redirectTo="/login" component={Base} />
      </Route>

      <Route to="/login" component={Auth} />
    </Switch>
  );
}

export default App;
