import { DateTime } from 'luxon';
import { getAsyncErrorMessage } from './async-errors';

export const isDev = process.env.NODE_ENV !== 'production';

export const consoleLog = (arg) => isDev && console.log(arg);
export const consoleInfo = (...args) => isDev && console.info(...args);
export const alertInfo = (...args) => !isDev && alert(getAsyncErrorMessage(args))
export const isObjEmpty = (obj) => !!Object.entries(obj).length;

export const filterObjByKeys = (obj, keys) =>
  Object.keys(obj)
    .filter((key) => keys.includes(key))
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

export const checkObjProps = (obj) => {
  for (let key in obj) {
    if (obj[key] !== null && obj[key] !== '') return false;
  }

  return true;
};

export const removeEmptyAttrsFromObj = (obj) =>
  Object.entries(obj).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {});

export const convertDate = (date, format) =>
  DateTime.fromISO(date)
    .setLocale('ru')
    .toFormat(format);

export const removeObjWithEmptyAttrsFromArr = (arr) =>
  // eslint-disable-next-line
  arr.filter(function(obj) {
    const temp = Object.keys(obj).map((item) => {
      return obj[item];
    });
    const key = temp.join('|');

    if (!this[key] && temp.join('')) {
      this[key] = true;

      return true;
    }
  }, Object.create(null));

export const convertDataDates = (arr, convert) => {
  Object.entries(arr).forEach((subArr) => {
    if (subArr?.[1]?.isArray) {
      subArr = subArr.forEach((obj) => {
        if (obj.date) obj.date = convert(obj.date);

        return obj;
      });
    }

    return subArr;
  });

  return arr;
};

export const convertFieldDate = (date, convert) => (date = convert(date));

export const convertResStr = (date) => {
  if (date !== '') {
    const [day, month, year] = date.toLocaleDateString().split('.');

    return DateTime.fromObject({ year, month, day }).toFormat('yyyy-LL-dd');
  }

  return date;
};

export const convertReqStr = (date) => {
  if (date !== '') {
    const [year, month, day] = date.toLocaleDateString().split('-');

    return DateTime.fromObject({ year, month, day }).toFormat('dd.LL.yyyy');
  }

  return date;
};

export const transformObjectDates = (keys, obj) => {
  let newObj = { ...obj };

  Object.entries(obj).forEach(([id, value]) => {
    for (let key of keys) {
      if (id === key) {
        newObj = { ...newObj, ...{ [key]: convertResStr(value) } };
      }
    }
  });

  return newObj;
};

export const isObject = (obj) => {
  return obj !== null && typeof obj === 'object' && Array.isArray(obj) === false;
};

export const checkArraysEqual = (arr1, arr2) => {
  return (
    arr1 === arr2 ||
    (arr1 !== null &&
      arr2 !== null &&
      arr1.length === arr2.length &&
      arr1
        .map(function(val, idx) {
          return val === arr2[idx];
        })
        .reduce(function(prev, cur) {
          return prev && cur;
        }, true))
  );
};

export const compareArrays = (arr1, arr2) => {
  arr1.sort();
  arr2.sort();

  return checkArraysEqual(arr1, arr2);
};

export const isObjContains = (obj, keys) => {
  // eslint-disable-next-line array-callback-return
  return Object.keys(obj).some((objKey) => {
    for (let filterKey of keys) {
      if (filterKey === objKey) {
        const value = obj[objKey];

        if (value === '' || value === null || value === []) return false;
        else return true;
      }
    }
  });
};

export function getPageCount(page, pageSize) {
  const count = Math.ceil(page / pageSize);

  return count;
}
export function extractProperties(obj, propNames){
  let resultObj = {};
  if (obj) {

    for (const propName of propNames) {
      if (obj.hasOwnProperty(propName)) {
        resultObj[propName] = obj[propName]
      }
    }
    if (Array.isArray(obj.fatherObj) && obj.fatherObj.length > 0) {
      resultObj.fatherObj = obj.fatherObj.map((item) => extractProperties(item, propNames))
    } else {
      resultObj.fatherObj = null;
    };

    if (Array.isArray(obj.motherObj) && obj.motherObj.length > 0) {
      resultObj.motherObj = obj.motherObj.map((item) => extractProperties(item, propNames))
    } else {
      resultObj.motherObj = null;
    }
    
     if (resultObj.hasOwnProperty('avatar')) {
      resultObj.avatar = resultObj.avatar;
    }
    
 }
  
  return resultObj
}
export const trimGoatName =(str) => {
  const dots = '...';
  let cuttedWord = '';
  if (str.length > 8) {
    cuttedWord = str.slice(0, 8) + dots;
    return cuttedWord;
  } else {
    return str;
  }
}