import React from 'react';
import cn from 'classnames/bind';
import Media from 'react-media';
import { useWindowSize } from '@react-hook/window-size';

import FilterCheckbox from '../FilterCheckbox/FilterCheckbox';
import FieldDatePicker from '../../../../components/FieldDatePicker/FieldDatePicker';
import Sprite from '../../../../components/Sprite';

import styles from './NotificationsFilter.module.css';

const cx = cn.bind(styles);

function NotificationsFilter(props) {
  const [width] = useWindowSize(768);

  function handleClearButton() {
    const values = props.values;

    props.actions.setValues({
      ...values,
      emr: false,
      goat: false,
      log: false,
      research: false,
      dateStart: '',
      dateEnd: '',
    });

    props.setState(
      {
        ...values,
        emr: false,
        goat: false,
        log: false,
        research: false,
        dateStart: '',
        dateEnd: '',
      },
      props.actions,
    );
  }

  return (
    <>
      <h3 className={cx({ 'filter-title': true })}>Фильтровать по</h3>

      <ul className={cx({ 'filter-list': true })}>
        <li className={cx({ 'filter-list-item': true })}>
          <FilterCheckbox name="emr" values={props.values} actions={props.actions}>
            ЭМК
          </FilterCheckbox>
        </li>

        <li className={cx({ 'filter-list-item': true })}>
          <FilterCheckbox name="goat" values={props.values} actions={props.actions}>
            Карточки коз
          </FilterCheckbox>
        </li>

        <li className={cx({ 'filter-list-item': true })}>
          <FilterCheckbox name="log" values={props.values} actions={props.actions}>
            Журналы
          </FilterCheckbox>
        </li>

        <li className={cx({ 'filter-list-item': true })}>
          <FilterCheckbox name="research" values={props.values} actions={props.actions}>
            Лаб. исследования
          </FilterCheckbox>
        </li>
      </ul>

      <h3 className={cx({ 'filter-title': true })}>Интервал</h3>

      <div className={styles.fields}>
        <FieldDatePicker
          name="dateStart"
          onChange={props.actions.setFieldValue}
          value={props.values.dateStart}
          className={styles.field}
          iconClassName={cx({ 'field-icon': true })}
          icon="calendar"
          selectedMaxDate={props.values.dateEnd}
          actions={props.actions}
          static
        />

        <Media query="(min-width: 992px)">
          <span className={styles.separator} />
        </Media>

        <FieldDatePicker
          name="dateEnd"
          onChange={props.actions.setFieldValue}
          value={props.values.dateEnd}
          className={styles.field}
          iconClassName={cx({ 'field-icon': true })}
          icon="calendar"
          selectedMinDate={props.values.dateStart}
          actions={props.actions}
          static
          popperPlacement={width <= 767 ? 'bottom-end' : 'bottom-start'}
        />
      </div>

      <div className={styles.buttons}>
        <button className={cx({ button: true, 'button-submit': true })} type="submit">
          Применить
        </button>

        <button
          onClick={handleClearButton}
          className={cx({ button: true, 'button-clear': true })}
          type="button"
        >
          <Sprite className={cx({ 'icon-clear': true })} name="clear" role="img" />
          Очистить
        </button>
      </div>
    </>
  );
}

export default NotificationsFilter;
