import React from 'react';
import cn from 'classnames/bind';

import CustomPagination from '../../components/CustomPagination/CustomPagination';
import PageSizeSelect from '../../components/PageSizeSelect/PageSizeSelect';
import SearchTable from '../SearchTable/SearchTable';
import Checkbox from '../Checkbox/Checkbox';
import ActionModal from '../../components/ActionModal/ActionModal';
import UserTableHeader from '../UserTableHeader/UserTableHeader';
import UserTableCell from '../UserTableCell/UserTableCell';

import useScrollLock from '../../../../hooks/use-scroll-lock';
import useUsers from '../../../../hooks/api/use-users';
import styles from './UsersTable.module.css';
import { compareArrays, getPageCount } from '../../../../utils/helpers';

const cx = cn.bind(styles);

const headers = ['ФИО', 'Права доступа', 'E-mail', 'Телефон'];
const options = [
  { value: 'admin', label: 'Администратор' },
  { value: 'moderator', label: 'Модератор' },
  { value: 'user', label: 'Пользователь' },
  { value: 'observer', label: 'Наблюдатель' },
];

function getUserRole(role) {
  const userObj = options.find(({ value }) => value === role);
  const userRole = userObj.label;

  return userRole;
}

function selectAllRows(rows) {
  return rows.map((_, index) => index);
}

function findIndex(index, indexes) {
  const result = indexes.find((i) => i === index);

  return index === result;
}

function checkIndexes(index, indexes, setIndexes) {
  const result = indexes.find((i) => i === index);

  if (result === index) indexes = indexes.filter((i) => i !== index);
  else indexes = [...indexes, index];

  setIndexes(indexes);
}

function UsersTable(props) {
  const { deleteUsers } = useUsers();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const compare = compareArrays(props.selectedRows, selectAllRows(props.values.results));
  const isRowEdit = (index) => props.isEdit && findRow(index);
  const { targetRef, targetElement, enableBodyScroll } = useScrollLock();

  function findRow(row) {
    return findIndex(row, props.selectedRows);
  }

  function checkRow(row) {
    checkIndexes(row, props.selectedRows, props.setSelectedRows);
  }

  function handleMainCheckboxClick() {
    if (props.selectedRows.length) props.setSelectedRows([]);
    if (!props.selectedRows.length) props.setSelectedRows(selectAllRows(props.values.results));
  }

  function handleCancelClick() {
    props.setSelectedRows([]);
    props.setIsEdit(false);
  }

  function handleRemoveUser() {
    const result = props.values.results.filter((_, index) => !props.selectedRows.includes(index));

    for (const row of props.selectedRows) {
      deleteUsers(props.values.results[row].id).then(() => {
        props.actions.setFieldValue('results', result);
        // FEAT: remove only 200 status id's. Not all
        props.setSelectedRows([]);
        setIsDeleteModalOpen(false);
      });
    }

    enableBodyScroll(targetElement);
  }

  return (
    <>
      <div className={styles.wrapper}>
        <UserTableHeader
          edit={props.isEdit}
          selectedRows={props.selectedRows}
          checked={props.selectedRows.length}
          type={compare ? 'check' : 'dash'}
          onCheckboxClick={handleMainCheckboxClick}
          onEditClick={() => props.setIsEdit(true)}
          onRemoveClick={() => setIsDeleteModalOpen(true)}
          onSaveClick={() => props.actions.submitForm()}
          onCancelClick={handleCancelClick}
          isEdit={props.isEdit}
        />

        <SearchTable>
          <thead>
            <tr>
              <UserTableCell role="title" />
              {headers.map((cell, index) => (
                <UserTableCell key={index} role="title">
                  {cell}
                </UserTableCell>
              ))}
            </tr>
          </thead>

          <tbody>
            {props.values.results.map(({ fullName, role, email, phone }, index) => {
              return (
                <tr key={index}>
                  <UserTableCell checkbox select={findRow(index)}>
                    {!props.isEdit && (
                      <Checkbox onClick={() => checkRow(index)} checked={findRow(index)} />
                    )}
                  </UserTableCell>

                  <UserTableCell
                    edit={isRowEdit(index)}
                    select={findRow(index)}
                    role="input"
                    name={`results[${index}].fullName`}
                  >
                    {fullName}
                  </UserTableCell>
                  <UserTableCell
                    edit={isRowEdit(index)}
                    select={findRow(index)}
                    role="select"
                    name={`results[${index}].role`}
                    actions={props.actions}
                    value={props.values.results[index].role}
                  >
                    {getUserRole(role)}
                  </UserTableCell>
                  <UserTableCell
                    edit={isRowEdit(index)}
                    select={findRow(index)}
                    role="input"
                    type="email"
                    name={`results[${index}].email`}
                  >
                    <a href={`mailto:${email}`}>{email}</a>
                  </UserTableCell>
                  <UserTableCell
                    edit={isRowEdit(index)}
                    select={findRow(index)}
                    role="phone"
                    name={`results[${index}].phone`}
                  >
                    {phone}
                  </UserTableCell>
                </tr>
              );
            })}
          </tbody>
        </SearchTable>

        <div className={cx({ footer: true })}>
          <div>
            {props.values.results.length ? (
              <CustomPagination
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                pageCount={getPageCount(props.values.count, props.values.pageSize)}
                onPageChange={(data) => props.onPageChange(data, props.values, props.actions)}
              />
            ) : null}
          </div>

          <PageSizeSelect
            name="pageSize"
            values={props.values}
            {...props.actions}
            onChange={(e) => props.onSelectChange(e, props.values, props.actions)}
          />
        </div>
      </div>

      <ActionModal
        isOpen={isDeleteModalOpen}
        title="Вы действительно хотите удалить пользователя?"
        btnAcceptText="Удалить"
        btnDeclineText="Отмена"
        btnAcceptDanger
        onClose={() => {
          setIsDeleteModalOpen(false);
          enableBodyScroll(targetElement);
        }}
        onAccept={handleRemoveUser}
        ref={targetRef}
        target={targetElement}
      />
    </>
  );
}

export default UsersTable;
