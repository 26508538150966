import React from 'react';
import cn from 'classnames/bind';

import BaseModal from '../../../../components/BaseModal/BaseModal';
import ButtonClose from '../../../../components/ButtonClose/ButtonClose';

import styles from './ActionModal.module.css';

const cx = cn.bind(styles);

const ActionModal = React.forwardRef((props, ref) => {
  return (
    <BaseModal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      shouldCloseOnOverlayClick={true}
      className={styles.modal}
      ref={ref}
      target={props.target}
    >
      <section className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>{props.title}</p>

          <ButtonClose
            onClick={props.onClose}
            stroke="var(--dark-gray)"
            className={cx({ 'btn-close': true })}
          />
        </div>

        {props.children && <p className={styles.text}>{props.children}</p>}
      </section>

      <section className={styles.buttons}>
        <button
          type="button"
          onClick={props.onAccept}
          className={cx({
            'btn-accept': true,
            'btn-accept-danger': props.btnAcceptDanger,
          })}
        >
          {props.btnAcceptText}
        </button>

        <button type="button" onClick={props.onClose} className={cx({ 'btn-decline': true })}>
          {props.btnDeclineText}
        </button>
      </section>
    </BaseModal>
  );
});

export default ActionModal;
