import { camelizeKeys, decamelizeKeys } from 'humps';

import axios from '../config/axios';
// import store from '../store';
import { getUID, getToken } from './url-parser';
import { phoneToStr } from './phone-parser';
import { setCookie } from './cookie';

// const token = store.getState().logIn.token;

const transformers = {
  transformRequest: [(data) => decamelizeKeys(data), ...axios.defaults.transformRequest],
  transformResponse: [...axios.defaults.transformResponse, (data) => camelizeKeys(data)],
};

export const users = {
  login: ({ email, password, isRemember }) =>
    axios.post('users/login/', { email, password }, { ...transformers }).then(({ data }) => {
      const token = data.authToken;
      const cookie = isRemember ? setCookie('token', token, 1e12) : setCookie('token', token);

      document.cookie = cookie;

      return { token };
    }),

  password: (location = '') => {
    const uid = getUID(location);
    const token = getToken(location);

    return {
      reset: ({ email }) => axios.post('users/reset-password/', { email }, { ...transformers }),

      check: () => {
        if (location) {
          // return axios.get(`/users/reset-password/check-uid-and-token?uid=${uid}&token=${token}/`, {
          //   ...transformers,
          // });
          return axios.get(`users/reset-password/check-uid-and-token/`, {
            params: {
              uid,
              token,
            },
            ...transformers,
          });
        }
      },

      confirm: ({ newPassword }) =>
        axios.post(
          'users/reset-password/confirm/',
          { uid, token, newPassword },
          { ...transformers },
        ),
    };
  },

  profile: {
    info: {
      get: () => {
        // if (token) {
        return axios.get('users/me/', {
          ...transformers,
        });
        // }
      },

      change: ({ fullName, phone, avatarId }) =>
        axios.patch(
          'users/me/',
          { fullName, phone: phone && phoneToStr(phone), avatarId },
          { ...transformers },
        ),
    },

    password: ({ currentPassword, newPassword }) =>
      axios.post('users/me/set-password/', { currentPassword, newPassword }, { ...transformers }),
  },
};

export const files = {
  images: (data) =>
    axios.post('files/images/', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  video: (data) =>
    axios.post('files/video/', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
};

export const goats = {
  post: ({ id = '', ...data }) => {
    return axios.post('goats/', { id, ...data }, { ...transformers });
  },

  get: ({ id = '' }) => {
    return axios.get(`goats/${id}/`, {
      ...transformers,
    });
  },
  get_stats: () => {
    return axios.get('goats/get_stats/', {...transformers});
  },
  goats_tree: (uuid) => {
    return axios.get(`/goats/tree?goat_id=${uuid}`, {...transformers})
  }
};

// -------------------------------------------------------------------------------------------------

export default {
  get: (url, config) => axios.get(url, { ...transformers, ...config }),
  post: (url, data, config) => axios.post(url, data, { ...transformers, ...config }),
  delete: (url, config) => axios.delete(url, { ...transformers, ...config }),
  patch: (url, data, config) => axios.patch(url, data, { ...transformers, ...config }),
  put: (url, config) => axios.put(url, { ...transformers, ...config }),
};
