import React from 'react';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';

import AuthForm from '../../containers/AuthForm/AuthForm';
import FieldSet from '../../components/FieldSet/FieldSet';
import PasswordField from '../../../../components/PasswordField/PasswordField';
import ChangePasswordModal from '../../components/ChangePasswordModal/ChangePasswordModal';

import styles from './ChangePassword.module.css';
import { users } from '../../../../utils/api';
import { getErrorCode, asyncErrorsFromCode } from '../../../../utils/async-errors';
import { ChangePasswordSchema } from '../../../../utils/validations';

function ChangePassword(props) {
  const location = props.location.pathname;
  const initialValues = {
    newPassword: '',
    confirmedPassword: '',
  };

  const [isTokenExpired, setIsTokenExpired] = React.useState(false);

  React.useEffect(() => {
    users
      .password(location)
      .check()
      .catch(() => setIsTokenExpired(true));
  }, [location]);

  const [headError, setHeadError] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  function handleSubmit({ confirmedPassword, newPassword }, actions) {
    if (confirmedPassword !== newPassword) {
      setHeadError('Пароли не совпадают. Повторите ввод');
      actions.setSubmitting(false);

      return;
    }

    users
      .password(location)
      .confirm({ newPassword })
      .then(() => {
        setHeadError('');
        setIsModalOpen(true);
        actions.resetForm();
      })
      .catch(({ response }) => {
        const code = getErrorCode(response.data.newPassword);
        const error = asyncErrorsFromCode(code);

        if (code === 'passwordTooCommon') {
          setHeadError(error);
        } else {
          setHeadError('');
          actions.setErrors({
            confirmedPassword: error,
            newPassword: error,
          });
        }
      })
      .finally(() => actions.setSubmitting(false));
  }

  if (isTokenExpired) {
    return <Redirect to="/reset-password" />;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        render={({ touched, errors, isSubmitting, handleBlur }) => (
          <AuthForm
            title="Изменение пароля"
            btnText="Сохранить"
            isSubmitting={isSubmitting}
            error={headError}
          >
            <FieldSet label="Введите новый пароль" className={styles.fieldset}>
              <PasswordField
                name="newPassword"
                error={touched.newPassword && errors.newPassword}
                onBlur={handleBlur}
              />
            </FieldSet>

            <FieldSet label="Повторите пароль" className={styles.fieldset}>
              <PasswordField
                name="confirmedPassword"
                error={touched.confirmedPassword && errors.confirmedPassword}
                onBlur={handleBlur}
              />
            </FieldSet>
          </AuthForm>
        )}
      />

      <ChangePasswordModal isOpen={isModalOpen} />
    </>
  );
}

export default ChangePassword;
