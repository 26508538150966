import React from 'react';
import cn from 'classnames';

import Sprite from '../../../../components/Sprite';

import styles from './ProfileAvatar.module.css';

const isEmpty = (obj) => Object.entries(obj).length;

function ProfileAvatar(props) {
  return (
    <div className={cn(styles.wrapper, props.className)}>
      {isEmpty(props.imagesList) ? (
        <picture>
          <source srcSet={props.imagesList.medium} media="(min-width: 992px)" />
          <source srcSet={props.imagesList.small} />

          <img src={props.imagesList.medium} alt={props.title} className={styles.img} />
        </picture>
      ) : (
        <Sprite
          name="userpic"
          className={styles.icon}
          role="img"
          aria-labelledby="user-pucture"
          title={props.title}
        />
      )}
    </div>
  );
}

export default ProfileAvatar;
