import React from 'react';
import cn from 'classnames/bind';
import { Field } from 'formik';

import Sprite from '../../../../components/Sprite';

import styles from './MultimediaStub.module.css';

const cx = cn.bind(styles);

function MultimediaStub(props) {
  return (
    <>
      <Field
        value={props.value}
        id={props.id}
        type="file"
        name={props.name}
        onChange={props.onChange}
        className={cx({ 'file-input': true })}
      />

      <button type="button">
        {props.isEdit && (
          <label htmlFor={props.id} className={cx({ 'btn-upload': true })}>
            <Sprite
              className={styles.icon}
              name={props.iconName}
              rel="img"
              id={props.id}
              title={props.iconTitle}
            />
            {props.title}
          </label>
        )}

        {!props.isEdit && (
          <p className={cx({ 'btn-upload': true })}>
            <Sprite
              className={styles.icon}
              name={props.iconName}
              rel="img"
              id={props.id}
              title={props.iconTitle}
            />
            {props.title}
          </p>
        )}
      </button>
    </>
  );
}

export default MultimediaStub;
