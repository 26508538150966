import React from 'react';
import { Form } from 'formik';

import ProfileBlockWrapper from '../../containers/ProfileBlockWrapper/ProfileBlockWrapper';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import { notify } from '../../../../components/Toast/Toast';

import { users } from '../../../../utils/api';
import styles from './ProfilePasswordBlock.module.css';
import { ProfilePasswordSchema } from '../../../../utils/validations';
import { getErrorCode, asyncErrorsFromCode } from '../../../../utils/async-errors';

function ProfilePasswordBlock() {
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmedPassword: '',
  };

  const [headError, setHeadError] = React.useState('');

  function handleSubmit({ currentPassword, newPassword, confirmedPassword }, actions) {
    if (newPassword !== confirmedPassword) {
      setHeadError('Пароли не совпадают');
      actions.setSubmitting(false);

      return;
    }

    users.profile
      .password({ currentPassword, newPassword })
      .then(() => {
        setHeadError('');
        actions.resetForm();
        notify('Пароль успешно изменен');
      })
      .catch(({ response }) => {
        const code = getErrorCode(response.data.currentPassword);
        const error = asyncErrorsFromCode(code);

        if (code === 'passwordTooCommon') {
          setHeadError(error);
        } else {
          setHeadError('');
          actions.setErrors({
            currentPassword: error,
          });
        }
      })
      .finally(() => actions.setSubmitting(false));
  }

  return (
    <>
      <ProfileBlockWrapper
        title="Изменение пароля"
        initialValues={initialValues}
        validationSchema={ProfilePasswordSchema}
        className={styles.wrapper}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        render={({ touched, errors, isSubmitting, handleBlur }) => (
          <Form className={styles.form} noValidate>
            {headError && <p className={styles.error}>{headError}</p>}

            <CustomFieldSet
              label="Старый пароль"
              name="currentPassword"
              type="password"
              error={touched.currentPassword && errors.currentPassword}
              onBlur={handleBlur}
            />

            <CustomFieldSet
              label="Новый пароль"
              name="newPassword"
              type="password"
              error={touched.newPassword && errors.newPassword}
              onBlur={handleBlur}
            />

            <CustomFieldSet
              label="Подтвердите пароль"
              name="confirmedPassword"
              type="password"
              error={touched.confirmedPassword && errors.confirmedPassword}
              onBlur={handleBlur}
            />

            <div className={styles.buttons}>
              <SubmitButton className={styles.btn} isSubmitting={isSubmitting}>
                Изменить
              </SubmitButton>
            </div>
          </Form>
        )}
      />
    </>
  );
}

export default ProfilePasswordBlock;
