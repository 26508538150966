import React from 'react';

import useComponentVisible from '../../../../hooks/use-component-visible';

import styles from './CommentActions.module.css';

function CommentActions(props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  function toggleMenu() {
    setIsComponentVisible(!isComponentVisible);
  }

  function removeCommentClick() {
    toggleMenu();
    props.onRemoveClick();
  }

  function editCommentClick() {
    toggleMenu();
    props.onEditClick();
  }

  return (
    <div ref={ref} className={styles.wrapper}>
      <button type="button" className={styles.dots} onClick={toggleMenu}>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
      </button>

      {isComponentVisible && (
        <ul className={styles.list}>
          <li>
            <button type="button" className={styles.btn} onClick={removeCommentClick}>
              Удалить комментарий
            </button>
          </li>

          <li>
            <button type="button" className={styles.btn} onClick={editCommentClick}>
              Редактировать
            </button>
          </li>
        </ul>
      )}
    </div>
  );
}

export default CommentActions;
