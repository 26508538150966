import React from 'react';
import cn from 'classnames';

import Sprite from '../../../../components/Sprite';

import styles from './Logo.module.css';

function Logo(props) {
  return (
    <Sprite
      name="logo-main"
      role="img"
      focusable="true"
      className={cn(styles.logo, props.className)}
    />
  );
}

export default Logo;
