import React from 'react';
import cn from 'classnames/bind';
import Textarea from 'react-textarea-autosize';
import MaskedInput from 'react-text-mask';
import { Field } from 'formik';

// import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker';

import styles from './LogTableCell.module.css';

const cx = cn.bind(styles);

function LogTableCell(props) {
  // console.log(props.values?.lines?.[props.index]?.cells?.[props.subindex]);

  return (
    <td
      colSpan={props.colSpan}
      rowSpan={props.rowSpan}
      className={cx(
        {
          cell: true,
          clickable: props.clickable,
          'input-cell': props.isEdit,
          'selected-cell': props.selected,
          'titled-cell': props.titled,
          'checked-cell': props.checked,
          'date-cell': props.isMasked,
        },
        props.className,
      )}
    >
      {!props.isMasked && props.isEdit && (
        <Field
          name={props.name}
          render={({ field }) => (
            <Textarea className={cx({ field: true, clickable: props.clickable })} {...field} />
          )}
        />
      )}

      {props.isMasked && props.isEdit && (
        <Field
          name={props.name}
          render={({ field }) => (
            <MaskedInput
              mask={props.mask}
              keepCharPositions
              guide={props.type === 'date'}
              {...field}
              render={(ref, props) => (
                <Textarea
                  inputRef={ref}
                  className={cx({ field: true, clickable: props.clickable })}
                  {...props}
                />
              )}
            />
          )}
        />
      )}

      {!props.isEdit && props.children}
    </td>
  );
}

export default LogTableCell;
