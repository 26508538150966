import React from 'react';
import cn from 'classnames';

import ErrorText from '../ErrorText/ErrorText';

import styles from './FieldSet.module.css';

function FieldSet(props) {
  return (
    <fieldset className={cn(styles.fieldset, props.className)}>
      <div className={styles.wrapper}>
        <p className={styles.label}>{props.label}</p>

        {props.link}
      </div>

      {props.children}

      {props.error && <ErrorText className={styles.error}>{props.error}</ErrorText>}
    </fieldset>
  );
}

export default FieldSet;
