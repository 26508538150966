import React from 'react';
import useSWR from '@zeit/swr';
import cn from 'classnames/bind';

import ActionButton from '../ActionButton/ActionButton';

import styles from './LambHeader.module.css';
import axios from '../../../../utils/axios';

const cx = cn.bind(styles);

function LambHeader(props) {
  const { data: goats } = useSWR('/goats/', axios);

  function handleReloadClick() {
    document.location.reload(false);
  }

  return (
    <div className={cn(styles.header, props.className)}>
      <h1 className={styles.title}>Статистика окота</h1>

      <div className={styles.buttons}>
        {!!goats?.data?.results.length && (
          <ActionButton
            className={cx({ button: true, 'button-reload': true })}
            role="button"
            icon="reload"
            onClick={handleReloadClick}
          >
            Обновить
          </ActionButton>
        )}
      </div>
    </div>
  );
}

export default LambHeader;
