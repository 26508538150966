import React from 'react';
import cn from 'classnames';

import styles from './SearchTableHead.module.css';

function SearchTableHead(props) {
  return (
    <thead className={styles.header}>
      <tr className={cn(styles.row, props.rowClassName)}>{props.children}</tr>
    </thead>
  );
}

export default SearchTableHead;
