import React from 'react';
import Media from 'react-media';
import Textarea from 'react-textarea-autosize';
import useSWR from '@zeit/swr';
import cn from 'classnames/bind';

import { notify } from '../../../../components/Toast/Toast';
import Sprite from '../../../../components/Sprite';
import Comment from '../Comment/Comment';
import DeletedComment from '../DeletedComment/DeletedComment';

import api from '../../../../utils/api';
import axios from '../../../../utils/axios';
import styles from './CommentsBlock.module.css';

const cx = cn.bind(styles);

function containsId(arr, id) {
  for (const item of arr) {
    if (item === id) return true;
  }
}

function CommentsBlock(props) {
  const formRef = React.useRef();
  const date = new Date();
  const commentBlockSize = window
    .getComputedStyle(document.body)
    .getPropertyValue('--comment-block-size');
  const checkCommentBlockSize = (size) => commentBlockSize.replace(/\s/g, '') === `${size}rem`;

  const [comment, setComment] = React.useState({});
  const [comments, setComments] = React.useState([]);
  const [isEditing, setIsEditing] = React.useState(false);
  const [text, setText] = React.useState('');
  const [deletedComments, setDeletedComments] = React.useState([]);
  const [editedCommentId, setEditedCommentId] = React.useState('');
  const [isCommentsOpen, setIsCommentsOpen] = React.useState(checkCommentBlockSize(23));
  const [isCommentEmpty, setIsCommentEmpty] = React.useState(true);

  const { data: userData } = useSWR('/users/me/', axios);
  const fullName = userData?.data?.fullName;
  const role = userData?.data?.role;

  React.useEffect(() => {
    api.get(`${props.page}/${props.uuid}/comments/`).then(({ data }) => setComments(data));
  }, [props.page, props.uuid]);

  function handleSubmit(e) {
    e.preventDefault();

    if (isEditing) {
      api
        .patch(`${props.page}/${props.uuid}/comments/${comment.id}/`, { ...comment, text })
        .then(() => {
          setIsEditing(false);

          api.get(`${props.page}/${props.uuid}/comments/`).then(({ data }) => {
            const localIndex = comments.findIndex((item) => item.id === editedCommentId);
            const dataIndex = data.findIndex((item) => item.id === editedCommentId);

            if (localIndex !== -1) {
              let newComments = [...comments];
              newComments[localIndex] = data[dataIndex];

              setComments(newComments);
            }
          });
        });
    } else {
      api
        .post(`${props.page}/${props.uuid}/comments/`, {
          text,
          author: {
            fullName,
          },
          created: date.toISOString,
        })
        .then(() => {
          api
            .get(`${props.page}/${props.uuid}/comments/`)
            .then(({ data }) => setComments([data[0], ...comments]));
        });
    }

    setText('');
    setIsCommentEmpty(true);
  }

  function handleEnterPress(e) {
    const value = e.target.value.trim();
    const isEnterKey = e.keyCode === 13;
    const isShiftKey = e.shiftKey === true;

    if (isEnterKey && !isShiftKey && value !== '') {
      handleSubmit(e);
    }
  }

  function handleTextareaChange(e) {
    const value = e.target.value;

    setText(value);

    if (value) setIsCommentEmpty(false);
    else setIsCommentEmpty(true);
  }

  function toggleComments() {
    function changeCommentsSize(size) {
      document.documentElement.style.setProperty('--comment-block-size', `${size}rem`);
    }

    isCommentsOpen ? changeCommentsSize(5) : changeCommentsSize(23);
    setIsCommentsOpen(!isCommentsOpen);
  }

  function removeCommentClick(id) {
    setDeletedComments([...deletedComments, id]);

    api
      .delete(`${props.page}/${props.uuid}/comments/${id}/`)
      .then(() => notify('Комментарий был удален'));
  }

  function editCommentClick(id, author) {
    setEditedCommentId(id);
    setComment({
      id,
      author: {
        fullName: author.fullName,
      },
      created: date.toISOString,
    });
    setIsCommentEmpty(false);
    setIsEditing(true);
  }

  function handleRestoreCommentClick(id) {
    api
      .put(`${props.page}/${props.uuid}/comments/${id}/restore/`)
      .then(() => setDeletedComments(deletedComments.filter((e) => e !== id)));
  }

  return (
    <section
      className={cx({
        wrapper: true,
        'wrapper-open': isCommentsOpen,
        'wrapper-close': !isCommentsOpen,
      })}
    >
      {isCommentsOpen ? (
        <>
          <div className={styles.header}>
            <h2 className={styles.title}>Комментарии</h2>

            <Media query="(min-width: 1200px)">
              <button type="button" className={cx({ 'close-btn': true })} onClick={toggleComments}>
                <Sprite
                  className={cx({ 'close-icon': true })}
                  name="arrow"
                  role="img"
                  id="comments-close"
                  title="Закрыть комментарии"
                />
              </button>
            </Media>
          </div>

          <form ref={formRef} className={styles.form} noValidate onSubmit={handleSubmit}>
            {!isCommentEmpty && (
              <div className={cx({ 'user-block': true })}>
                <div className={cx({ 'user-avatar': true })}>
                  <span className={cx({ 'user-char': true })}>
                    {(isEditing ? comment.author.fullName : fullName).charAt(0).toUpperCase()}
                  </span>
                </div>
                <p className={cx({ 'user-name': true })}>
                  {isEditing ? comment.author.fullName : fullName}
                </p>
              </div>
            )}

            <Textarea
              placeholder="Добавить комментарий"
              className={styles.textarea}
              onKeyDown={handleEnterPress}
              onChange={handleTextareaChange}
              value={text}
            />

            {!isCommentEmpty && (
              <div className={styles.btns}>
                <button
                  onClick={() => {
                    setIsCommentEmpty(true);
                    setText('');
                  }}
                  type="button"
                  className={cx({ btn: true, 'btn-cancel': true })}
                >
                  Отменить
                </button>

                <button type="submit" className={cx({ btn: true, 'btn-publish': true })}>
                  {isEditing ? 'Сохранить' : 'Опубликовать'}
                </button>
              </div>
            )}
          </form>

          <div className={styles.comments}>
            {comments.map(({ id, author, created, text, isEdited }) => {
              if (containsId(deletedComments, id))
                return (
                  <DeletedComment
                    key={`${id}-${author.fullName}`}
                    onClick={() => handleRestoreCommentClick(id)}
                  />
                );

              return (
                <Comment
                  key={`${id}-${author.fullName}`}
                  fullName={author.fullName}
                  created={created}
                  text={text}
                  isEdited={isEdited}
                  isMenuVisible={fullName === author.fullName || role === 'admin'}
                  onRemoveClick={() => removeCommentClick(id)}
                  onEditClick={() => {
                    setText(text);
                    editCommentClick(id, author);
                  }}
                />
              );
            })}
          </div>
        </>
      ) : (
        <div>
          <button type="button" className={cx({ 'open-btn': true })} onClick={toggleComments}>
            <Sprite
              className={cx({ 'open-icon': true })}
              name="comments"
              role="img"
              id="comments-open"
              title="Открыть комментарии"
            />

            <span className={cx({ 'open-text': true })}>Комментарии</span>
          </button>
        </div>
      )}
    </section>
  );
}

export default CommentsBlock;
