import React from 'react';
import Modal from 'react-modal';
import cn from 'classnames';

import styles from './BaseModal.module.css';
import useScrollLock from '../../hooks/use-scroll-lock';

Modal.setAppElement('#root');

const BaseModal = React.forwardRef((props, ref) => {
  const { disableBodyScroll } = useScrollLock();

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      onAfterOpen={() => disableBodyScroll(props.target)}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      closeTimeoutMS={300}
      className={cn(styles.modal, props.className)}
      overlayClassName={cn(styles.overlay, props.overlayClassName)}
      ref={ref}
    >
      {props.children}
    </Modal>
  );
});

export default BaseModal;
