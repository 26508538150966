import React from 'react';
import cn from 'classnames/bind';

import Sprite from '../../../../components/Sprite';

import styles from './MenuFilters.module.css';

const cx = cn.bind(styles);

function MenuFilters(props) {
  const [isComponentVisible, setIsComponentVisible] = React.useState(false);
  const toggleMenu = () => setIsComponentVisible(!isComponentVisible);

  return (
    <div className={styles.wrapper}>
      <button type="button" className={cx({ 'button-open': true })} onClick={toggleMenu}>
        <Sprite name="filter" role="img" className={cx({ 'filter-icon': true })} />

        <p className={styles.text}>Фильтр</p>
      </button>

      {isComponentVisible && (
        <div className={cn(styles.menu, props.menuClassName)}>
          <button type="button" className={cx({ 'button-close': true })} onClick={toggleMenu}>
            <Sprite
              name="close"
              role="img"
              className={cx({ 'close-icon': true })}
              stroke="var(--dark-gray)"
            />
          </button>

          {props.children}
        </div>
      )}
    </div>
  );
}

export default MenuFilters;
