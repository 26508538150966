import React from 'react';
import cn from 'classnames/bind';

import MultimediaStub from '../../components/MultimediaStub/MultimediaStub';
import ActionButton from '../../components/ActionButton/ActionButton';

import styles from './ResearchImage.module.css';
import * as errorMessages from '../../../../utils/error-messages';
import api, { files } from '../../../../utils/api';

const cx = cn.bind(styles);

function ResearchImage(props) {
  const MAX_IMAGE_SIZE = 1e7;

  const [error, setError] = React.useState('');

  function handleImageChange(e) {
    e.preventDefault();

    const formData = new FormData();
    const file = e.target.files[0];

    if (!file?.name.match(/.(jpe?g|png)$/i)) {
      setError(errorMessages.invalidImage);
      return;
    }

    if (file?.size >= MAX_IMAGE_SIZE) {
      setError(errorMessages.largeFile);
      return;
    }

    formData.append('source', file);
    setError('');

    if (props.isCreate) {
      files.images(formData).then(({ data }) => {
        props.actions.setFieldValue('imgId', data.id);
        props.actions.setFieldValue('img', data);
      });
    }

    if (!props.isCreate) {
      files.images(formData).then(({ data }) => {
        api
          .patch(`/research/${props.uuid}/`, { imgId: data.id })
          .then(({ data }) => props.actions.setFieldValue('img', data.img));
      });
    }
  }

  function handleDeleteImageClick() {
    if (props.isCreate) {
      props.actions.setFieldValue('img', null);
      props.actions.setFieldValue('imgId', null);
    }

    if (!props.isCreate) {
      api
        .patch(`/research/${props.values.id}/`, { imgId: null })
        .then(() => props.actions.setFieldValue('img', null));
    }
  }

  return (
    <div className={cx({ wrapper: true })}>
      <div className={cx({ container: true, 'container-empty': !props.values.img })}>
        {props.values.img ? (
          <img
            className={cx({ image: true })}
            src={props.values.img.large}
            alt="Фотография исследования"
          />
        ) : (
          <MultimediaStub
            iconName="camera-2"
            id="image-upload"
            onChange={handleImageChange}
            isEdit={props.isEdit}
            title={props.isEdit ? 'Загрузить фото' : 'Фото отсутствует'}
            iconTitile="Загрузить фото"
          />
        )}

        {props.values?.img && props.isEdit && (
          <ActionButton
            className={cx({ 'btn-remove': true })}
            icon="trash"
            role="button"
            type="button"
            onClick={handleDeleteImageClick}
          >
            Удалить
          </ActionButton>
        )}
      </div>

      {error && <p className={cx({ error: true })}>{error}</p>}
    </div>
  );
}

export default ResearchImage;
