import React from 'react';
import Media from 'react-media';
import generator from 'generate-password';
import cn from 'classnames/bind';
import { Field } from 'formik';

import CustomSelect from '../CustomSelect/CustomSelect';
import PhoneField from '../PhoneField/PhoneField';

import styles from './UserFieldSet.module.css';

const cx = cn.bind(styles);

const password = () => {
  let text = generator.generate({
    length: 12,
    numbers: true,
  });
  const rand = 0 + Math.random() * (9 + 1 - 0);

  text = `${text}${Math.floor(rand)}`;

  return text;
};

function UserFieldSet(props) {
  const error = props.touched[props.name] && props.errors[props.name];
  const options = [
    { value: 'admin', label: 'Администратор' },
    { value: 'moderator', label: 'Модератор' },
    { value: 'user', label: 'Пользователь' },
    { value: 'observer', label: 'Наблюдатель' },
  ];
  const button = () => (
    <button type="button" onClick={handleGenerateClick} className={cx({ 'btn-generate': true })}>
      Сгенерировать
    </button>
  );

  function handleGenerateClick() {
    props.actions.setFieldValue('password', password());
  }

  return (
    <>
      <div className={cx({ 'title-wrapper': true })}>
        <p className={cx({ 'field-title': true })}>{props.title}</p>

        <Media query="(max-width: 767px)">{props.type === 'password' && button()}</Media>
      </div>

      {(props.type === 'text' || props.type === 'email') && (
        <Field
          type={props.type}
          name={props.name}
          className={cx({ field: true, 'field-error': error })}
        />
      )}

      {props.type === 'phone' && (
        <PhoneField
          name={props.name}
          className={cx({ field: true, 'field-error': error })}
          fieldClassName={cx({ 'field-phone': true })}
        />
      )}

      {props.type === 'password' && (
        <div className={cx({ field: true, 'field-password': true, 'field-error': error })}>
          <Field name={props.name} />

          <Media query="(min-width: 768px)">{button()}</Media>
        </div>
      )}

      {props.type === 'select' && (
        <CustomSelect
          name={props.name}
          onBlur={props.actions.setFieldTouched}
          onChange={({ value }) => props.actions.setFieldValue(props.name, value)}
          value={props.values[props.name]}
          options={options}
          styles={{
            container: {
              height: '48px',
            },
            control: {
              height: '48px',
              border: '1px solid #d0dfe6',
              borderRadius: '4px',
            },
            menu: {
              border: '1px solid #d0dfe6',
            },
            option: {
              height: '48px',
            },
          }}
        />
      )}

      {error && <p className={cx({ error: true })}>{error}</p>}
    </>
  );
}

export default UserFieldSet;
