import React from 'react';
import { useSize } from 'react-hook-size';
import cn from 'classnames/bind';
import Scrollbar from 'react-scrollbars-custom';
import styles from './LambTableTotal.module.css';

const cx = cn.bind(styles);

function LambTableTotal({goatsTotal}){

  const ref = React.useRef(null);
  let { height } = useSize(ref);
  return (
    <section className={styles.wrapper}>
      <Scrollbar noScrollY style={{height}}>
        <div className={cx({'table-wrapper': true})}>
          <table ref={ref} className={styles.table}>
            <thead className={styles.header}>
              <tr className={cn(styles.row, styles.tableRow)}>
                <th className={cn(styles.cell, styles.tableCell)}>Всего</th>
                <th className={cn(styles.cell, styles.tableCell)}>Мертв</th>
                <th className={cn(styles.cell, styles.tableCell)}>Козлик</th>
                <th className={cn(styles.cell, styles.tableCell)}>Козочка</th>
              </tr>
            </thead>
            <tbody className={styles.body}>
              <tr className={cn(styles.tableRow, styles['table-body-row'])}>
                <td className={cx(styles.cell, styles.tableCell)}>{goatsTotal.totalAliveGoats}</td>
                <td className={cx(styles.cell, styles.tableCell)}>{goatsTotal.totalDeadGoats}</td>
                <td className={cx(styles.cell, styles.tableCell)}>{goatsTotal.totalAliveMales}</td>
                <td className={cx(styles.cell, styles.tableCell)}>{goatsTotal.totalAliveFemales}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Scrollbar>
    </section>
  )
};
export default LambTableTotal;
