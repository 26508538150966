import React from 'react';
import Modal from 'react-modal';
import cn from 'classnames/bind';
import useScrollLock from '../../../../hooks/use-scroll-lock';
import { Link } from 'react-router-dom';

import ButtonClose from '../../../../components/ButtonClose/ButtonClose';
import CustomPagination from '../CustomPagination/CustomPagination';

import styles from './BindingModal.module.css';

const cx = cn.bind(styles);

Modal.setAppElement('#root');

function BindingModal(props) {
  const { disableBodyScroll, targetRef, targetElement, enableBodyScroll } = useScrollLock();

  function handleClose() {
    props.onClose();
    enableBodyScroll(targetElement);
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={handleClose}
      onAfterOpen={() => disableBodyScroll(targetElement)}
      shouldCloseOnOverlayClick
      closeTimeoutMS={300}
      className={cx({ modal: true })}
      overlayClassName={cx({ overlay: true })}
      ref={targetRef}
    >
      <ButtonClose
        onClick={handleClose}
        className={cx({ 'btn-close': true })}
        stroke="var(--dark-gray)"
      />

      <h3 className={cx({ title: true })}>{props.title}</h3>

      {props.results?.length ? (
        <ul className={cx({ list: true })}>
          {props.results.map(({ id, name }, index) => (
            <li className={cx({ 'list-item': true })} key={`${index}-${id}`}>
              <Link
                to={`../../${props.link}/${id}`}
                onClick={handleClose}
                className={cx({ identifier: true })}
              >
                {name || id}
              </Link>{' '}
              <button
                className={cx({ 'binding-btn': true })}
                type="button"
                onClick={() => props.onBindClick(id)}
              >
                Привязать
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <div className={cx({ 'empty-block': true })}>
          <p className={cx({ 'empty-text': true })}>{`Не привязанные ${
            props.role === 'goat' ? 'ЭМК' : 'карточки'
          } отсутствуют`}</p>

          <Link
            className={cx({ 'make-btn': true })}
            onClick={handleClose}
            to={`../../${props.link}/create`}
          >{`Завести ${props.role === 'goat' ? 'ЭМК' : 'козу'}`}</Link>
        </div>
      )}

      {props.results?.length ? (
        <div className={cx({ 'pagination-wrapper': true })}>
          <CustomPagination
            className={cx({ pagination: true })}
            pageCount={props.pageCount}
            marginPagesDisplayed={props.marginPagesDisplayed}
            pageRangeDisplayed={props.pageRangeDisplayed}
            onPageChange={props.onPageChange}
          />
        </div>
      ) : null}
    </Modal>
  );
}

export default BindingModal;
