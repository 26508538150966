import React from 'react';
import cn from 'classnames/bind';
import Media from 'react-media';
import { Formik, Form } from 'formik';

import Container from '../../components/Container/Container';
import MenuFilters from '../../components/MenuFilters/MenuFilters';
import NotificationsFilter from '../../components/NotificationsFilter/NotificationsFilter';
import PageNotificationsList from '../../components/PageNotificationsList/PageNotificationsList';

import useNotifications from '../../../../hooks/api/use-notifications';
import styles from './Notifications.module.css';

const cx = cn.bind(styles);

function Notifications() {
  const {
    notifications,
    submitNotifications,
    loadNotifications,
    setNotifications,
    notificationsCount,
  } = useNotifications();

  const initialValues = {
    emr: '',
    goat: '',
    log: '',
    research: '',
    dateStart: '',
    dateEnd: '',
  };

  const isNotificationsExists = notificationsCount > 0;
  const isNextNotificationsExists = notifications?.next;

  function handleSubmit(values, actions) {
    submitNotifications(values, actions);
  }

  function handleButtonClick(values, actions) {
    const cursor = notifications?.next?.match(/\?cursor=(.*)$/)[1];

    if (notifications?.next) {
      loadNotifications({ ...values, cursor }, actions);
    }
  }

  return (
    <Container
      className={styles.container}
      wrapperClassName={cx({ wrapper: true, 'wrapper-empty': !isNotificationsExists })}
    >
      {isNotificationsExists ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          render={({ values, ...actions }) => (
            <Form className={styles.form} noValidate>
              <div className={cx({ header: true })}>
                <h1 className={cx({ title: true })}>Уведомления</h1>

                <Media query="(max-width: 991px)">
                  <MenuFilters menuClassName={cx({ menu: true })}>
                    <NotificationsFilter
                      values={values}
                      actions={actions}
                      setState={submitNotifications}
                    />
                  </MenuFilters>
                </Media>
              </div>

              <Media query="(min-width: 992px)">
                <div className={cx({ 'filters-wrapper': true })}>
                  <NotificationsFilter
                    values={values}
                    actions={actions}
                    setState={submitNotifications}
                  />
                </div>
              </Media>

              <PageNotificationsList
                notifications={notifications}
                setNotifications={setNotifications}
              />

              {isNextNotificationsExists && (
                <button
                  type="button"
                  onClick={() => handleButtonClick(values, actions)}
                  className={cx({ 'load-btn': true })}
                >
                  Показать еще 20
                </button>
              )}
            </Form>
          )}
        />
      ) : (
        <p className={cx({ 'empty-results': true })}>У вас пока нет уведомлений</p>
      )}
    </Container>
  );
}

export default Notifications;
