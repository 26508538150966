import React from 'react';

import sprites from '../assets/images/sprites.svg';

function Sprite(props) {
  return (
    <svg
      className={props.className}
      role={props.role}
      focusable={props.focusable}
      aria-labelledby={props.id}
    >
      {props.title && <title id={props.id}>{props.title}</title>}

      <use
        xlinkHref={`${sprites}#${props.name}`}
        stroke={props.stroke}
        fill={props.fill}
        style={props.style}
      />
    </svg>
  );
}

export default Sprite;
