import React from 'react';
import { Field, ErrorMessage } from 'formik';
import cn from 'classnames/bind';

import styles from './TextField.module.css';

const cx = cn.bind(styles);

function TextField(props) {
  function handleBlur(e) {
    if (props.removeSpacesOnBlur) {
      const value = (e.target.value || '').split(' ').join('');

      props.setFieldValue(props.name, value);
      props.handleBlur(e);
    }
  }

  return (
    <>
      <Field
        name={props.name}
        type={props.type || 'text'}
        id={props.id}
        onBlur={handleBlur}
        className={cx(
          {
            field: true,
            'field-error': !!props.error,
          },
          props.fieldClassName,
        )}
      />

      <ErrorMessage
        name={props.name}
        component="p"
        className={cn(styles.error, props.errorClassName)}
      />
    </>
  );
}

export default TextField;
