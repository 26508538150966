import React from 'react';
import cn from 'classnames';

import styles from './SearchTableBodyCell.module.css';

function SearchTableBodyCell(props) {
  const handleClick = (e) => {
    e.stopPropagation()
  };
  const onclick = props.canClick ? handleClick : undefined;
  return <td onClick={onclick} className={cn(styles.cell, props.className)}>{props.children}</td>;
}

export default SearchTableBodyCell;
