import React from 'react';
import useSWR from '@zeit/swr';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from '../../../../components/PrivateRoute';
import Layout from '../../components/Layout/Layout';
import Main from '../../pages/Main/Main';
import Lamb from '../../pages/Labm/Lamb';
import Profile from '../../pages/Profile/Profile';
import Goat from '../../pages/Goat/Goat';
import Goats from '../../pages/Goats/Goats';
import ElectronicMediaclRecord from '../../pages/ElectronicMediaclRecord/ElectronicMediaclRecord';
import ElectronicMediaclRecords from '../../pages/ElectronicMediaclRecords/ElectronicMediaclRecords';
import Logs from '../../pages/Logs/Logs';
import Log from '../../pages/Log/Log';
import LaboratoryResearches from '../../pages/LaboratoryResearches/LaboratoryResearches';
import LaboratoryResearch from '../../pages/LaboratoryResearch/LaboratoryResearch';
import Users from '../../pages/Users/Users';
import Notifications from '../../pages/Notifications/Notifications';
import Statistics from '../../pages/Statistics/Statistics';

import axios from '../../../../utils/axios';
import { isAdmin, isAdminOrModer } from '../../../../utils/user-role';
import Lamb2 from '../../pages/Lamb2/Lamb2';


function Base(props) {
  const { data } = useSWR('/users/me/', axios);
  const userRole = data?.data?.role;

  return userRole ? (
    <Layout history={props.history}>
      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="/statistics" component={Statistics} />
        <Route exact path="/lamb">
          <PrivateRoute isAccess={isAdminOrModer(userRole)} redirectTo="/" component={Lamb2} />
        </Route>

        <Route path="/profile" component={Profile} />
        <Route path="/goats" exact component={Goats} />
        <Route exact path="/goat/create">
          <PrivateRoute isAccess={isAdminOrModer(userRole)} redirectTo="/goats" component={Goat} />
        </Route>
        <Route exact path="/goat/:id" component={Goat} />

        <Route path="/electronic-medical-records" exact component={ElectronicMediaclRecords} />
        <Route exact path="/electronic-medical-record/create">
          <PrivateRoute
            isAccess={isAdminOrModer(userRole)}
            redirectTo="/electronic-medical-records"
            component={ElectronicMediaclRecord}
          />
        </Route>
        <Route exact path="/electronic-medical-record/:id" component={ElectronicMediaclRecord} />

        <Route path="/logs" exact component={Logs} />
        <Route exact path="/log/create">
          <PrivateRoute isAccess={isAdminOrModer(userRole)} redirectTo="/logs" component={Log} />
        </Route>
        <Route path="/log/:id" exact component={Log} />

        <Route path="/laboratory-researches" exact component={LaboratoryResearches} />
        <Route exact path="/laboratory-research/create">
          <PrivateRoute
            isAccess={isAdminOrModer(userRole)}
            redirectTo="/laboratory-researches"
            component={LaboratoryResearch}
          />
        </Route>
        <Route path="/laboratory-research/:id" exact component={LaboratoryResearch} />

        <Route exact path="/notifications">
          <PrivateRoute isAccess={isAdmin(userRole)} redirectTo="/" component={Notifications} />
        </Route>

        <Route exact path="/users">
          <PrivateRoute isAccess={isAdmin(userRole)} redirectTo="/" component={Users} />
        </Route>

        <Redirect to="/" />
      </Switch>
    </Layout>
  ) : null;
}

export default Base;
