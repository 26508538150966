import React from 'react';
import cn from 'classnames/bind';
import styles from './GoatsTreeButton.module.css';
import TreeWrapper from '../TreeWrapper/TreeWrapper'
import GoatContentModal from '../GoatContentModal/GoatContentModal';

const cx = cn.bind(styles);

function GoatsTreeButton({goatsTree}){
  const [isModalOpen, setIsModalOpen] = React.useState(false); 
  
  return (
    <>
      <button
       className={cx({ button: true, 'button-show': true })}
       type="button"
       onClick={() => setIsModalOpen(true)}
      >
        Показать
      </button>

      <GoatContentModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      >
        <TreeWrapper goatsTree={goatsTree} />
      </GoatContentModal>
    </>
  )
};
export default GoatsTreeButton;
