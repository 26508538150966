import React from 'react';
import cn from 'classnames/bind';
import { Field } from 'formik';
import useSWR from '@zeit/swr';

import styles from './LogHeader.module.css';
import axios from '../../../../utils/axios';
import { isAdminOrModer } from '../../../../utils/user-role';

const cx = cn.bind(styles);

function LogHeader(props) {
  const { data: user } = useSWR('/users/me/', axios);
  const isPersonal = isAdminOrModer(user?.data?.role);

  return (
    <section className={cn(styles.header, props.className)}>
      <div className={cx({ container: true, 'containder-edit': props.isEdit })}>
        <div className={styles.info}>
          {props.isEdit ? (
            <Field
              name={props.name}
              className={cx({ field: true })}
              placeholder="Название журнала"
              type="text"
            />
          ) : (
            <h1 className={styles.title}>{props.values[props.name]}</h1>
          )}
        </div>

        {isPersonal && props.children && <div className={styles.actions}>{props.children}</div>}
      </div>
    </section>
  );
}

export default LogHeader;
