import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'webp-in-css/polyfill';

import App from './App';

import store from './store';
import './assets/styles/libs.css';
import './assets/styles/index.css';

const app = (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

const root = document.getElementById('root');

render(app, root);
