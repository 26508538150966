import React from "react";
import Tree from "../Tree/Tree";
import Branch from "../Branch/Branch";
import LeafBranch from "../LeafBranch/LeafBranch";
import cn from 'classnames/bind';
import styles from './TreeWrapper.module.css';
const cx = cn.bind(styles);
function TreeWrapper({goatsTree}){
  const generateTree = (goatsTree) => {
    const generateBranch = (goat) => {
        if (goat) {
          return (
            <Branch>
              {goat.fatherObj && goat.motherObj 
                ? (
                    <>
                      <LeafBranch tag={goat.fatherObj[0].tag} id={goat.fatherObj[0].id} leaf={goat.fatherObj[0].name}>
                        { goat.fatherObj !== null && generateBranch(goat.fatherObj[0])}
                      </LeafBranch>
                      <LeafBranch tag={goat.motherObj[0].tag} id={goat.motherObj[0].id} leaf={goat.motherObj[0].name}>
                      {generateBranch(goat.motherObj[0])}
                      </LeafBranch>
                    </>
                  ) 
                : goat.fatherObj
                ? ( 
                    <LeafBranch tag={goat.fatherObj[0].tag} id={goat.fatherObj[0].id} leaf={goat.fatherObj[0].name}>
                       {generateBranch(goat.fatherObj)}
                    </LeafBranch>
                  )
                : goat.motherObj
                ? (
                    <LeafBranch tag={goat.motherObj[0].tag} id={goat.motherObj[0].id} leaf={goat.motherObj[0].name}>
                      {generateBranch(goat.motherObj)}
                    </LeafBranch>
                  )
                : null
              }
            
            </Branch>
          )
        } else {
            return null;
        }
    }
     return (
      <div className={cx({treeWrapper: true})}>
        <Tree>
          <Branch
          >
           <LeafBranch tag={goatsTree.tag} id={goatsTree.id} leaf={goatsTree.name}>
              {generateBranch(goatsTree)}
           </LeafBranch>
          </Branch>
        </Tree>
      </div>
    )
  }
  return generateTree(goatsTree);
};
export default TreeWrapper;
