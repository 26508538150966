import React from 'react';
import Textarea from 'react-textarea-autosize';
import cn from 'classnames/bind';

import styles from './TitledAnnotationWrapper.module.css';

const cx = cn.bind(styles);

function TitledAnnotationWrapper(props) {
  const annotation = props.values.annotation;

  const handleAnnotationChange = ({ setFieldValue }) => (e) => {
    const value = e.target.value;

    setFieldValue('annotation', value);
  };

  return (
    <div className={styles.container}>
      <section className={styles.wrapper}>
        <h2 className={cx({ title: true })}>Дополнительная информация</h2>

        {props.isEdit && (
          <Textarea
            placeholder="Добавить запись"
            className={styles.textarea}
            onChange={handleAnnotationChange(props.actions)}
            value={annotation}
          />
        )}

        {!!annotation && !props.isEdit && (
          <p className={cx({ text: true, 'text-full': true })}>{annotation}</p>
        )}

        {!!!annotation && !props.isEdit && (
          <p className={cx({ text: true, 'text-empty': true })}>Записи отсутствуют</p>
        )}
      </section>
    </div>
  );
}

export default TitledAnnotationWrapper;
