import React from 'react';
import cn from 'classnames/bind';
import Scrollbar from 'react-scrollbars-custom';
import { FieldArray } from 'formik';

import styles from './TitledTableWrapper.module.css';

const cx = cn.bind(styles);

function TitledTableWrapper(props) {
  const isContent = props.values[props.name]?.length > 0;
  const isEdit = props.isEdit;
  const ref = React.useRef(null);

  return (
    <div className={cx({ container: true })}>
      <section className={cx({ wrapper: true, 'wrapper-empty': !isContent && !isEdit })}>
        <h2 className={cx({ title: true, 'title-empty': !isContent && !isEdit })}>{props.title}</h2>

        <FieldArray
          name={props.name}
          render={({ push }) => (
            <>
              {isContent && (
                <div className={cn(styles.content, props.contentClassName)}>
                  <Scrollbar noScrollY translateContentSizeYToHolder>
                    <table ref={ref} className={cx({ table: true, 'table-readable': !isEdit })}>
                      {props.children}
                    </table>
                  </Scrollbar>
                </div>
              )}

              {!isContent && !isEdit && <p className={styles.text}>{props.text}</p>}

              {isEdit && (
                <div className={cx({ 'button-wrapper': true })}>
                  {props.isModal ? (
                    <button
                      type="button"
                      className={styles.button}
                      // onClick={() => {
                      //   props.onClick(push, props.name);
                      //   props.setModalIndex(props.values.ills.length);
                      //   props.setIsDiseasesModalOpen(true);
                      // }}
                      onClick={props.onClick}
                    >
                      Добавить
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={styles.button}
                      onClick={() => props.onClick(push, props.name)}
                    >
                      Добавить
                    </button>
                  )}
                </div>
              )}
            </>
          )}
        />
      </section>
    </div>
  );
}

export default TitledTableWrapper;
