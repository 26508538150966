import React from 'react';
import cn from 'classnames/bind';
import { Formik, Field } from 'formik';

import CardHeader from '../../components/CardHeader/CardHeader';
import ActionButton from '../../components/ActionButton/ActionButton';
import BindingBlock from '../../components/BindingBlock/BindingBlock';
import TitledTableWrapper from '../../components/TitledTableWrapper/TitledTableWrapper';
import TitledAnnotationWrapper from '../../components/TitledAnnotationWrapper/TitledAnnotationWrapper';
import CommentsBlock from '../../components/CommentsBlock/CommentsBlock';
import ActionModal from '../../components/ActionModal/ActionModal';
import DatepickerField from '../../components/DatepickerField/DatepickerField';
import DiseasesModal from '../../components/DiseasesModal/DiseasesModal';
import Sprite from '../../../../components/Sprite';

import styles from './ElectronicMediaclRecord.module.css';
import api from '../../../../utils/api';
import useScrollLock from '../../../../hooks/use-scroll-lock';
import { EMRSchema } from '../../../../utils/validations';
// import { removeObjWithEmptyAttrsFromArr } from '../../../../utils/helpers';
import { notify } from '../../../../components/Toast/Toast';
import emr from '../../../../api/emr';
import { getCardUUID, getKeysFromUrl } from '../../../../utils/url-parser';
import { convertFormDates, convertDateToDMY } from '../../../../utils/dates';
import * as regExp from '../../../../utils/regular-expressions';
import { transformTimeDate, sortLowerDates, sortHigherDates } from '../../../../utils/dates';

const cx = cn.bind(styles);

function checkCreateUrl(url) {
  const [value] = getKeysFromUrl(url, 2);

  return value === 'create';
}

function checkIsFieldValueExists(arr, field) {
  for (const obj of arr) {
    if (obj?.[field]) return true;
    else return false;
  }
}

function ElectronicMediaclRecord(props) {
  const url = props.location.pathname;
  const uuid = getCardUUID(url);
  const goatId = props.location?.state?.goatId
  const isCreate = checkCreateUrl(url);
  const { targetRef, targetElement, enableBodyScroll } = useScrollLock();

  const [initialValues, setInitialValues] = React.useState({
    id: '',
    annotation: '',
    vaccinations: [],
    vaccinationsCustomCol: '',
    antiparasitics: [],
    ills: [],
    matings: [],
    lambings: [],
    feed: [],
  });

  React.useEffect(() => {
    if (!isCreate) {
      emr
        .get(uuid)
        .then(({ data }) => {
          // setInitialValues(convertFormDates(data, ['date', 'dateTo', 'dateFrom']));
          setInitialValues(convertFormDates(data, ['date']));
        })
        .catch(({ response }) => {
          if (response.status) props.history.push('/electronic-medical-records');
        });
    }
  }, [isCreate, props.history, uuid]);

  const [isEdit, setIsEdit] = React.useState(isCreate);
  const [isCloseModalOpen, setIsCloseModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const handleSubmitForm = (values, { handleSubmit, setSubmitting }) => (e) => {
    e.preventDefault();
    handleSubmit();

    // Check blocks arrayFields. If on submit items in array are empty
    // then this objects needs to remove from array. After this submit values

    if (isEdit && !isCreate) {
      return emr.edit(uuid, values).then(() => {
        props.history.push('/electronic-medical-records');
        setSubmitting(false);
      });
    }

    if (isCreate) {
      return emr.create(values, goatId).then(() => {
        setSubmitting(false);
        props.history.push('/electronic-medical-records');
      });
    }
  };

  function handleRemoveCard() {
    emr.remove(uuid).then(() => props.history.push('/electronic-medical-records'));
    enableBodyScroll(targetElement);
  }

  function handleAddTableClick(push, name) {
    const fields = {
      vaccinations: {
        date: '',
        drugTitle: '',
        dosage: '',
        customCol: '',
      },
      antiparasitics: {
        date: '',
        drugTitle: '',
        dosage: '',
      },
      // ills: {
      //   name: '',
      //   date: null,
      //   clinicalSigns: '',
      //   diagnosis: '',
      //   conclusion: '',
      //   recommendations: '',
      //   modified: null,
      // },
      matings: {
        date: '',
        nameMale: '',
        nameFemale: '',
      },
      lambings: {
        date: '',
        maleQuantity: null,
        femaleQuantity: null,
      },
      feed: {
        title: '',
      },
    };

    push(fields[name]);
  }

  function handleBindCard(id, cb) {
    api.patch(`/emr/${uuid}/`, { goatId: id }).then(({ data }) => {
      notify(`Карточка успешно привязана`);
      setInitialValues({ ...initialValues, goat: data.goat });
      cb();
    });
  }

  function handleUnbindCard(cb) {
    api.patch(`/emr/${uuid}/`, { goatId: null }).then(() => {
      notify(`Карточка успешно отвязана`);
      setInitialValues({ ...initialValues, goat: null });
      cb();
    });
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={EMRSchema}
        enableReinitialize
        render={({ values, ...actions }) => {
          return (
            <div className={styles.wrapper}>
              <div className={styles.container}>
                <form
                  noValidate
                  className={cx({ form: true, 'form-create': isCreate })}
                  onSubmit={handleSubmitForm(values, actions)}
                >
                  <CardHeader
                    className={styles.head}
                    title="ЭМК"
                    label={
                      !isCreate && (
                        <BindingBlock
                          name="goat"
                          modalTitle="Выберите карточку козы, непривязанную ни к одной ЭМК"
                          values={values}
                          url="goat"
                          history={props.history}
                          isBinded={values.goat}
                          role="emr"
                          onBindClick={handleBindCard}
                          onUnbindClick={handleUnbindCard}
                        />
                      )
                    }
                    actions={
                      <>
                        {(isCreate || isEdit) && (
                          <ActionButton
                            className={cx({ 'btn-save': true })}
                            role="button"
                            icon="check-bold"
                            type="submit"
                          >
                            Сохранить
                          </ActionButton>
                        )}

                        {(isCreate || isEdit) && (
                          <ActionButton
                            className={cx({ 'btn-cancel': true })}
                            role="button"
                            icon="close-bold"
                            type="button"
                            fill="#0c1116"
                            onClick={() => setIsCloseModalOpen(true)}
                          >
                            Отменить
                          </ActionButton>
                        )}

                        {!isCreate && !isEdit && (
                          <ActionButton
                            className={cx({ 'btn-edit': true })}
                            role="button"
                            icon="pen"
                            type="button"
                            onClick={() => setIsEdit(true)}
                          >
                            Редактировать
                          </ActionButton>
                        )}

                        {!isCreate && !isEdit && (
                          <ActionButton
                            className={cx({ 'btn-remove': true })}
                            role="button"
                            icon="trash"
                            type="button"
                            onClick={() => setIsDeleteModalOpen(true)}
                          >
                            Удалить
                          </ActionButton>
                        )}
                      </>
                    }
                  />

                  <TitledTableWrapper
                    title="Прививки"
                    text="Прививки ранее не делались"
                    name="vaccinations"
                    values={values}
                    onClick={handleAddTableClick}
                    isEdit={isEdit}
                  >
                    <TableContent
                      name="vaccinations"
                      head={
                        <>
                          <TableHeadCell>Дата</TableHeadCell>
                          <TableHeadCell>Доза</TableHeadCell>
                          <TableHeadCell>Препарат</TableHeadCell>
                          {isEdit ? (
                            <TableHeadCell type="field" name="vaccinationsCustomCol" />
                          ) : (
                            values?.vaccinationsCustomCol
                          )}
                        </>
                      }
                      className={cx({
                        'vaccinations-row-expanded':
                          isEdit ||
                          values?.vaccinationsCustomCol ||
                          checkIsFieldValueExists(values?.vaccinations, 'customCol'),
                      })}
                    >
                      {values?.vaccinations?.map((_, index) => (
                        <TableRow
                          key={index}
                          name="vaccinations"
                          className={cx({
                            'vaccinations-row-expanded':
                              isEdit ||
                              values?.vaccinationsCustomCol ||
                              checkIsFieldValueExists(values?.vaccinations, 'customCol'),
                          })}
                        >
                          <TableDataCell
                            name="vaccinations"
                            attr="date"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                            role="date"
                          />
                          <TableDataCell
                            name="vaccinations"
                            attr="dosage"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                            isInputRestrict
                            onChange={(e) => {
                              const value = e.target.value;
                              if (regExp.dosage.test(value)) {
                                actions.setFieldValue(`vaccinations[${index}].dosage`, value);
                              }
                            }}
                          />
                          <TableDataCell
                            name="vaccinations"
                            attr="drugTitle"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                          />
                          {(isEdit || values.vaccinations?.[index]?.customCol) && (
                            <TableDataCell
                              name="vaccinations"
                              attr="customCol"
                              index={index}
                              values={values}
                              actions={actions}
                              isEdit={isEdit}
                            />
                          )}
                        </TableRow>
                      ))}
                    </TableContent>
                  </TitledTableWrapper>

                  <TitledTableWrapper
                    title="Противопаразитарные мероп."
                    text="Мероприятия не проводились"
                    name="antiparasitics"
                    values={values}
                    onClick={handleAddTableClick}
                    isEdit={isEdit}
                  >
                    <TableContent
                      name="antiparasitics"
                      head={
                        <>
                          <TableHeadCell>Дата</TableHeadCell>
                          <TableHeadCell>Доза</TableHeadCell>
                          <TableHeadCell>Препарат</TableHeadCell>
                        </>
                      }
                    >
                      {values?.antiparasitics?.map((_, index) => (
                        <TableRow key={index} name="antiparasitics">
                          <TableDataCell
                            name="antiparasitics"
                            attr="date"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                            role="date"
                          />
                          <TableDataCell
                            name="antiparasitics"
                            attr="dosage"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                          />
                          <TableDataCell
                            name="antiparasitics"
                            attr="drugTitle"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                          />
                        </TableRow>
                      ))}
                    </TableContent>
                  </TitledTableWrapper>

                  <DiseasesTable
                    values={values}
                    isEdit={isEdit}
                    handleAddTableClick={handleAddTableClick}
                    actions={actions}
                    isCreate={isCreate}
                    uuid={uuid}
                  />

                  <TitledTableWrapper
                    title="Случка"
                    text="Случка ранее не выполнялась"
                    name="matings"
                    values={values}
                    onClick={handleAddTableClick}
                    isEdit={isEdit}
                  >
                    <TableContent
                      name="matings"
                      head={
                        <>
                          <TableHeadCell>Дата</TableHeadCell>
                          <TableHeadCell>Коза</TableHeadCell>
                          <TableHeadCell>Козел производитель</TableHeadCell>
                        </>
                      }
                    >
                      {values?.matings?.map((_, index) => (
                        <TableRow key={index} name="matings">
                          <TableDataCell
                            name="matings"
                            attr="date"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                            role="date"
                          />
                          <TableDataCell
                            name="matings"
                            attr="nameMale"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                          />
                          <TableDataCell
                            name="matings"
                            attr="nameFemale"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                          />
                        </TableRow>
                      ))}
                    </TableContent>
                  </TitledTableWrapper>

                  <TitledTableWrapper
                    title="Окот козы"
                    text="Окот ранее не выполнялся"
                    name="lambings"
                    values={values}
                    onClick={handleAddTableClick}
                    isEdit={isEdit}
                  >
                    <TableContent
                      name="lambings"
                      head={
                        <>
                          <TableHeadCell>Дата</TableHeadCell>
                          <TableHeadCell>Пол козлят</TableHeadCell>
                          <TableHeadCell>Живых</TableHeadCell>
                          <TableHeadCell>Мертвых</TableHeadCell>
                          <TableHeadCell>Аборт</TableHeadCell>
                        </>
                      }
                    >
                      {values?.lambings?.map((_, index) => (
                        <TableRow key={index} name="lambings">
                          <TableDataCell
                            name="lambings"
                            attr="date"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                            role="date"
                            className={cx({ 'lambings-date-cell': true })}
                          />
                          <td className={cx({ cell: true, text: true, 'text-cell': true })}>
                            Самка
                          </td>
                          <TableDataCell
                            name="lambings"
                            attr="femaleQuantity"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                            type="number"
                            className={cx({
                              'lambings-female-data-cell': true,
                              'lambings-tight-cell': true,
                            })}
                          />
                          {/* новые */}
                          <TableDataCell
                            name="lambings"
                            attr="deadFemaleQuantity"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                            type="number"
                            className={cx({ 'lambings-tight-cell': true })}
                          />
                          <TableDataCell
                            name="lambings"
                            attr="abortionFemaleQuantity"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                            type="number"
                            className={cx({ 'lambings-tight-cell': true })}
                          />
                          {/* --- */}
                          <td
                            className={cx({
                              cell: true,
                              text: true,
                              'text-cell': true,
                              'last-text-cell': true,
                            })}
                          >
                            Самец
                          </td>
                          <TableDataCell
                            name="lambings"
                            attr="maleQuantity"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                            type="number"
                            className={cx({ 'lambings-tight-cell': true })}
                          />
                          {/* новые */}
                          <TableDataCell
                            name="lambings"
                            attr="deadMaleQuantity"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                            type="number"
                            className={cx({ 'lambings-tight-cell': true })}
                          />
                          <TableDataCell
                            name="lambings"
                            attr="abortionMaleQuantity"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                            type="number"
                            className={cx({ 'lambings-tight-cell': true })}
                          />
                          {/* --- */}
                        </TableRow>
                      ))}
                    </TableContent>
                  </TitledTableWrapper>

                  <TitledTableWrapper
                    title="Витаминизация"
                    text="Записи отсутствуют"
                    name="feed"
                    values={values}
                    onClick={handleAddTableClick}
                    isEdit={isEdit}
                    contentClassName={cx({ 'feed-table-content': true })}
                  >
                    <TableContent name="feed">
                      {values?.feed?.map((_, index) => (
                        <TableRow key={index} name="feed">
                          <td className={styles.text}>{index + 1}.</td>
                          <TableDataCell
                            name="feed"
                            attr="title"
                            index={index}
                            values={values}
                            actions={actions}
                            isEdit={isEdit}
                          />
                        </TableRow>
                      ))}
                    </TableContent>
                  </TitledTableWrapper>

                  <TitledAnnotationWrapper values={values} actions={actions} isEdit={isEdit} />
                </form>
              </div>

              {!isCreate && <CommentsBlock uuid={uuid} page="emr" />}
            </div>
          );
        }}
      />

      <ActionModal
        isOpen={isCloseModalOpen}
        title="Закрыть документ?"
        btnAcceptText="Закрыть"
        btnDeclineText="Продолжить редактирование"
        onClose={() => {
          enableBodyScroll(targetElement);
          setIsCloseModalOpen(false);
        }}
        onAccept={() => {
          props.history.push('/electronic-medical-records');
          enableBodyScroll(targetElement);
        }}
        ref={targetRef}
        target={targetElement}
      >
        Если вы закроете документ, внесенные изменения не сохранятся
      </ActionModal>

      <ActionModal
        isOpen={isDeleteModalOpen}
        title="Вы действительно хотите удалить выбранную карточку?"
        btnAcceptText="Удалить"
        btnDeclineText="Отмена"
        onClose={() => {
          enableBodyScroll(targetElement);
          setIsDeleteModalOpen(false);
        }}
        onAccept={handleRemoveCard}
        ref={targetRef}
        target={targetElement}
      />
    </>
  );
}

function TableContent(props) {
  return (
    <>
      <thead>
        <tr
          className={cx(
            { row: true, 'head-row': true, [`${props.name}-row`]: true },
            props.className,
          )}
        >
          {props.head}
        </tr>
      </thead>

      <tbody>{props.children}</tbody>
    </>
  );
}

function TableRow(props) {
  return (
    <tr
      key={props.index}
      className={cx({ row: true, [`${props.name}-row`]: true }, props.className)}
    >
      {props.children}
    </tr>
  );
}

function TableHeadCell(props) {
  if (props.type === 'field') {
    return (
      <th className={cx({ cell: true, 'head-cell': true })}>
        <Field
          name={props.name}
          placeholder="Пусто"
          type="text"
          className={cx({ 'new-field': true })}
        />
      </th>
    );
  }

  return <th className={cx({ cell: true, 'head-cell': true })}>{props.children}</th>;
}

function TableDataCell(props) {
  const { touched, errors } = props.actions;
  const text = props.values?.[props.name]?.[props.index]?.[props.attr];
  const error = errors?.[props.name]?.[props.index]?.[props.attr];
  const touch = touched?.[props.name]?.[props.index]?.[props.attr];

  return (
    <td className={cx({ cell: true, 'data-cell': true }, props.className)}>
      {props.isEdit && props.role !== 'date' && !props.isInputRestrict && (
        <Field
          className={styles.field}
          name={`${props.name}[${props.index}].${props.attr}`}
          placeholder="Пусто"
          type={props.type || 'text'}
        />
      )}

      {props.isEdit && props.role !== 'date' && props.isInputRestrict && (
        <Field
          className={styles.field}
          name={`${props.name}[${props.index}].${props.attr}`}
          placeholder="Пусто"
          type={props.type || 'text'}
          onChange={props.onChange}
        />
      )}

      {props.isEdit && props.role === 'date' && (
        <Field
          name={`${props.name}[${props.index}].${props.attr}`}
          render={({ field }) => (
            <DatepickerField
              {...field}
              className={styles.field}
              value={props.values[props.name][props.index][props.attr]}
              onChange={props.actions.setFieldValue}
              onBlur={() =>
                props.actions.setFieldTouched(`${props.name}[${props.index}].${props.attr}`)
              }
            />
          )}
        />
      )}

      {!props.isEdit && (
        <p className={styles.text}>{props.role === 'date' ? convertDateToDMY(text) : text}</p>
      )}

      {/* {Object.keys(errors?.[props.name]?.[0] || {})[0] === Object.keys(errors?.[props.name]?.[props.index] || {})[0]} */}

      {touch && error && <p className={styles.error}>{error}</p>}
    </td>
  );
}

// diseases table components
// -----------------------------------------------------------------------------

function DiseasesTable(props) {
  const [ills, setIlls] = React.useState([]);

  React.useEffect(() => {
    setIlls(props.values?.ills);
  }, [props]);

  const [isDiseasesModalOpen, setIsDiseasesModalOpen] = React.useState(false);

  const [isDateSort, setIsDateSort] = React.useState(true);
  const toggleDateSort = () => setIsDateSort(!isDateSort);

  function handleDateSort() {
    toggleDateSort();

    if (isDateSort) sortLowerDates(ills);
    else sortHigherDates(ills);
  }

  const [isModifiedSort, setIsModifiedSort] = React.useState(true);
  const toggleModifiedSort = () => setIsModifiedSort(!isModifiedSort);

  function handleModifiedSort() {
    toggleModifiedSort();
  }

  const [modalIndex, setModalIndex] = React.useState(null);

  function handleAddClick() {
    setIsDiseasesModalOpen(true);
    setModalIndex('-1');
  }

  function handleTitleClick(index) {
    setIsDiseasesModalOpen(true);
    setModalIndex(index);
  }

  return (
    <>
      <TitledTableWrapper
        title="Болезни"
        text="Болезни отсутствуют"
        name="ills"
        values={props.values}
        isModal
        // onClick={props.handleAddTableClick}
        // modalIndex={modalIndex}
        // setModalIndex={setModalIndex}
        // setIsDiseasesModalOpen={setIsDiseasesModalOpen}
        onClick={handleAddClick}
        isEdit={props.isEdit}
      >
        <TableContent
          name="ills"
          head={
            <>
              <TableHeadCell>
                <DiseaseFilterButton isSort={isDateSort} onClick={handleDateSort}>
                  Дата приема
                </DiseaseFilterButton>
              </TableHeadCell>

              <TableHeadCell>Название</TableHeadCell>

              <TableHeadCell>
                <DiseaseFilterButton isSort={isModifiedSort} onClick={handleModifiedSort}>
                  Обновлено
                </DiseaseFilterButton>
              </TableHeadCell>
            </>
          }
        >
          {ills.map(({ date, name, modified }, index) => (
            <TableRow key={index} name="ills">
              <DiseaseContentCell>
                {props.isCreate
                  ? transformTimeDate(date, 'dmyt', 0)
                  : transformTimeDate(date, 'dmyt')}
              </DiseaseContentCell>
              <DiseaseContentCell role="button" onClick={() => handleTitleClick(index)}>
                {name || 'Название болезни'}
              </DiseaseContentCell>
              <DiseaseContentCell>
                {props.isCreate ? modified : transformTimeDate(modified, 'dmyt', 9)}
              </DiseaseContentCell>
            </TableRow>
          ))}
        </TableContent>
      </TitledTableWrapper>

      <DiseasesModal
        isOpen={isDiseasesModalOpen}
        onClose={() => setIsDiseasesModalOpen(false)}
        values={props.values}
        actions={props.actions}
        index={modalIndex}
        setIndex={setModalIndex}
        isCreate={props.isCreate}
        uuid={props.uuid}
      />
    </>
  );
}

function DiseaseFilterButton(props) {
  const [isHover, setIsHover] = React.useState(false);
  const toggleHover = () => setIsHover(!isHover);

  return (
    <div className={cx({ 'disease-button-wrapper': true })}>
      <button
        type="button"
        onClick={props.onClick}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        className={cx({ 'disease-head-button': true })}
      >
        {props.children}
      </button>

      {isHover && (
        <Sprite
          role="img"
          name="sort-arrow"
          className={cx({ 'sort-icon': true, 'sort-icon-invert': props.isSort })}
        />
      )}
    </div>
  );
}

function DiseaseContentCell(props) {
  return (
    <td className={cx({ cell: true, text: true, 'disease-cell': true })}>
      {props.role === 'button' ? (
        <button
          type="button"
          onClick={props.onClick}
          className={cx({ 'disease-content-button': true })}
        >
          {props.children}
        </button>
      ) : (
        props.children
      )}
    </td>
  );
}

export default ElectronicMediaclRecord;
