import React from 'react';
import Scrollbar from 'react-scrollbars-custom';
import cn from 'classnames/bind';
import { useSize } from 'react-hook-size';
import { useWindowSize } from '@react-hook/window-size';

import Sprite from '../../../../components/Sprite';
import Checkbox from '../Checkbox/Checkbox';
import LogTableCell from '../LogTableCell/LogTableCell';
import LogTableRow from '../LogTableRow/LogTableRow';

import styles from './LogTable.module.css';
import { compareArrays } from '../../../../utils/helpers';

const cx = cn.bind(styles);

function splitIndexes(arr) {
  const getMatchesItems = (regExp) => arr.filter((item) => regExp.test(item));
  const getIndexes = (arr) => arr.map((item) => item.replace(/[^0-9]/g, ''));

  const cols = getIndexes(getMatchesItems(/^col-/));
  const lines = getIndexes(getMatchesItems(/^row-/));

  return { cols, lines };
}

function LogTable({ isEdit, values, actions, initialValues, table }) {
  const [selectedIndexes, setSelectedIndexes] = React.useState([]);
  const tableRef = React.useRef(null);
  let { height } = useSize(tableRef);
  const [width] = useWindowSize(768);
  const colsLength = values.cols?.length;
  const compare = compareArrays(selectedIndexes, setAllIndexes());

  function findIndex(index) {
    return selectedIndexes.find((item) => item === index);
  }

  function checkboxFindIndex(index) {
    if (findIndex(index)) return true;
    else return '';
  }

  function findFlatIndex(col, line) {
    if (findIndex(col)) return true;
    if (findIndex(line)) return true;
  }

  function checkIndexes(index) {
    let indexes = [...selectedIndexes];

    if (indexes.find((item) => item === index)) {
      indexes = indexes.filter((item) => item !== index);
    } else {
      indexes = [...indexes, index];
    }

    setSelectedIndexes(indexes);
  }

  function setAllIndexes() {
    let indexes = [];

    values.cols && values.cols.map((_, index) => (indexes = [...indexes, `col-${index}`]));
    values.lines && values.lines.map((_, index) => (indexes = [...indexes, `row-${index}`]));

    return indexes;
  }

  function handleControlCheckboxClick() {
    const indexes = selectedIndexes.length;

    if (indexes) setSelectedIndexes([]);
    if (!indexes) setSelectedIndexes(setAllIndexes());
  }

  function handleDeleteClick() {
    const { cols, lines } = splitIndexes(selectedIndexes);
    // console.log(`cols: ${cols}`);
    // console.log(`lines: ${lines}`);

    const newCols = values.cols.filter((_, i) => cols.map(Number).indexOf(i) === -1);
    let newLines = values.lines.filter((_, i) => lines.map(Number).indexOf(i) === -1);
    // remove selected cells from row
    newLines = newLines.map((line) => ({
      name: line.name,
      cells: line.cells.filter((_, i) => cols.map(Number).indexOf(i) === -1),
    }));

    const newData = {
      ...values,
      cols: newCols,
      lines: newLines,
    };
    const initialData = { ...values, ...table };

    setSelectedIndexes([]);

    if (compare) {
      actions.setValues(initialData);
    } else {
      actions.setValues(newData);
    }
  }

  function handleAddRowClick() {
    const cells = Array(colsLength).fill(null);
    const line = {
      name: null,
      cells,
    };

    actions.setValues({
      title: values.title,
      cols: values.cols,
      lines: [...values.lines, line],
    });
  }

  function handleAddColClick() {
    const lines = values.lines.map((line) => ({ name: line.name, cells: [...line.cells, ''] }));

    actions.setValues({
      cols: [...values.cols, { name: null, type: 'text' }],
      lines,
    });
  }

  return (
    <section className={cx({ wrapper: true })}>
      <div className={cx({ 'table-wrapper': true })}>
        <Scrollbar noScrollY style={{ height: height + (width >= 768 ? 39 : 24) }}>
          <table className={cx({ table: true })} ref={tableRef}>
            <thead>
              {isEdit && (
                <LogTableRow>
                  <LogTableCell
                    checked
                    colSpan="2"
                    rowSpan="2"
                    className={cx({ 'control-cell': true })}
                  >
                    <div className={cx({ control: true })}>
                      <Checkbox
                        type={compare ? 'check' : 'dash'}
                        onClick={handleControlCheckboxClick}
                        checked={selectedIndexes.length ? true : ''}
                      />

                      {selectedIndexes.length ? (
                        <button
                          type="button"
                          className={cx({ btn: true, 'btn-remove': true })}
                          onClick={handleDeleteClick}
                        >
                          <Sprite className={cx({ 'remove-icon': true })} name="trash" role="img" />{' '}
                          Удалить
                        </button>
                      ) : null}
                    </div>
                  </LogTableCell>

                  {values.cols?.map((_, index) => {
                    return (
                      <LogTableCell
                        key={`col-checkbox-${index}`}
                        selected={findIndex(`col-${index}`)}
                        checked
                      >
                        <Checkbox
                          onClick={() => checkIndexes(`col-${index}`)}
                          checked={checkboxFindIndex(`col-${index}`)}
                        />
                      </LogTableCell>
                    );
                  })}

                  {isEdit && <LogTableCell />}
                </LogTableRow>
              )}

              <LogTableRow>
                {!isEdit && <LogTableCell />}

                {values.cols?.map((col, index) => {
                  const isReadOnly = col.readonly;

                  return (
                    <LogTableCell
                      key={`col-title-${index}`}
                      isEdit={!isReadOnly && isEdit}
                      titled
                      clickable={!isReadOnly && isEdit}
                      selected={findIndex(`col-${index}`)}
                      name={`cols[${index}].name`}
                    >
                      {col.name}
                    </LogTableCell>
                  );
                })}

                {isEdit && (
                  <LogTableCell
                    colSpan={colsLength + 1}
                    className={cx({ 'adding-cell': true, 'cell-plus': true })}
                    checked
                  >
                    <button
                      type="button"
                      className={cx({ 'btn-add': true })}
                      onClick={handleAddColClick}
                    >
                      <Sprite className={cx({ 'plus-icon': true })} role="img" name="plus" />
                    </button>
                  </LogTableCell>
                )}
              </LogTableRow>
            </thead>

            <tbody>
              {values.lines?.map(({ name, cells }, index) => {
                return (
                  <LogTableRow key={`row-${index}`}>
                    {isEdit && (
                      <LogTableCell checked selected={findIndex(`row-${index}`)}>
                        <Checkbox
                          onClick={() => checkIndexes(`row-${index}`)}
                          checked={checkboxFindIndex(`row-${index}`)}
                        />
                      </LogTableCell>
                    )}

                    <LogTableCell
                      onClick={() => checkIndexes(`row-${index}`)}
                      clickable={isEdit}
                      selected={findIndex(`row-${index}`)}
                      titled
                      isEdit={isEdit}
                      name={`lines[${index}].name`}
                    >
                      {name}
                    </LogTableCell>

                    {cells.map((cell, subindex) => {
                      const isDate = values.cols[subindex].type === 'date';
                      const isInt = values.cols[subindex].type === 'integer';

                      const mask = () => {
                        if (isDate)
                          return [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
                        if (isInt) return [/\d/, /\d/, /\d/, /\d/, /\d/];
                      };

                      return (
                        <LogTableCell
                          type={values.cols[subindex].type}
                          isEdit={isEdit}
                          key={`cell-${index}-${subindex}`}
                          clickable={isEdit}
                          isMasked={isDate || isInt}
                          mask={mask()}
                          selected={findFlatIndex(`col-${subindex}`, `row-${index}`)}
                          name={`lines[${index}].cells[${subindex}]`}
                          values={values}
                          index={index}
                          subindex={subindex}
                        >
                          {cell}
                        </LogTableCell>
                      );
                    })}

                    {isEdit && <LogTableCell />}
                  </LogTableRow>
                );
              })}

              {isEdit && (
                <LogTableRow className={cx({ 'adding-row': true })}>
                  <td className={cx({ 'adding-cell': true })}></td>
                  <td colSpan={colsLength + 1} className={cx({ 'adding-cell': true })}>
                    <button
                      type="button"
                      className={cx({ 'btn-add': true })}
                      onClick={handleAddRowClick}
                    >
                      <Sprite
                        className={cx({ 'plus-icon': true, 'plus-icon-filled': true })}
                        role="img"
                        name="plus"
                      />
                      Добавить строку
                    </button>
                  </td>
                  {isEdit && <td className={cx({ 'adding-cell': true })} />}
                </LogTableRow>
              )}
            </tbody>
          </table>
        </Scrollbar>
      </div>
    </section>
  );
}

export default LogTable;
