import React from 'react';

import styles from './DeletedComment.module.css';

function DeletedComment(props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.comment}>
        <p className={styles.text}>Комментарий был удален</p>

        <button type="button" onClick={props.onClick} className={styles.button}>
          Восстановить?
        </button>
      </div>
    </div>
  );
}

export default DeletedComment;
