import React from 'react';
import { Form } from 'formik';

import ErrorText from '../../components/ErrorText/ErrorText';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';

import styles from './AuthForm.module.css';

function AuthForm(props) {
  return (
    <Form className={styles.form} noValidate>
      <h1 className={styles.title}>{props.title}</h1>

      {props.error && <ErrorText className={styles.error}>{props.error}</ErrorText>}

      {props.children}

      <SubmitButton isSubmitting={props.isSubmitting} type="submit" className={styles.btn}>
        {props.btnText}
      </SubmitButton>
    </Form>
  );
}

export default AuthForm;
