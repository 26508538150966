import React from 'react';
import api from '../../../../utils/api';
import { getPageCount } from '../../../../utils/helpers';

import BindingModal from '../BindingModal/BindingModal';

function EMRBindingModal(props) {
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    api
      .get('/emr/', {
        params: {
          unattached: true,
          page_size: 5,
        },
      })
      .then(({ data }) => setData(data));
  }, [props.isOpen]);

  function handlePageChange(page) {
    api
      .get('/emr/', {
        params: {
          unattached: true,
          page_size: 5,
          page: page + 1,
        },
      })
      .then(({ data }) => setData(data));
  }

  return (
    <BindingModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      role={props.role}
      title={props.title}
      results={data.results}
      marginPagesDisplayed={3}
      pageRangeDisplayed={3}
      pageCount={getPageCount(data.count, 5)}
      onPageChange={({ selected }) => handlePageChange(selected)}
      onBindClick={props.onBindClick}
      link="electronic-medical-record"
    />
  );
}

export default EMRBindingModal;
