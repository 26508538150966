import React from 'react';
import cn from 'classnames/bind';
import useSWR from '@zeit/swr';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { clearCookies } from '../../../../utils/cookie';

import Sprite from '../../../../components/Sprite';

import axios from '../../../../utils/axios';
import styles from './UserMenu.module.css';
import useComponentVisible from '../../../../hooks/use-component-visible';

const cx = cn.bind(styles);

function UserMenu(props) {
  const { data: user } = useSWR('/users/me/', axios);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const toggle = () => setIsComponentVisible(!isComponentVisible);

  function handleLogoutClick() {
    clearCookies();
    props.history.go('/login');
  }

  return (
    //eslint-disable-next-line
    <div ref={ref} onClick={toggle} className={cx({ container: true, 'container-active': isComponentVisible })}>
      <div className={cx({ 'image-wrapper': true, 'image-wrapper-active': isComponentVisible })}>
        {user?.data?.avatar ? (
          <img
            className={cx({ image: true })}
            src={user?.data?.avatar?.small}
            alt="Изображение пользователя"
          />
        ) : (
          <div>
            <Sprite className={cx({ 'user-icon': true })} name="userpic" />
          </div>
        )}
      </div>

      <Media query="(min-width: 768px)">
        <>
          <p className={cx({ 'user-name': true })}>{user?.data?.fullName}</p>

          <span className={cx({ triangle: true, 'triangle-active': isComponentVisible })}></span>
        </>
      </Media>

      {isComponentVisible && (
        <ul className={cx({ list: true })}>
          <li>
            <Link to="/profile" className={cx({ button: true })}>
              Профиль
            </Link>
          </li>

          <li>
            <button type="button" onClick={handleLogoutClick} className={cx({ button: true })}>
              Выйти
            </button>
          </li>
        </ul>
      )}
    </div>
  );
}

export default UserMenu;
