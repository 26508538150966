import React from 'react';
import Media from 'react-media';
import cn from 'classnames/bind';
import { Formik, Form } from 'formik';

import Container from '../../components/Container/Container';
import GoatsHeader from '../../components/GoatsHeader/GoatsHeader';
import HeadFilters from '../../components/HeadFilters/HeadFilters';
import MenuFilters from '../../components/MenuFilters/MenuFilters';
import GoatsSearchTable from '../../components/GoatsSearchTable/GoatsSearchTable';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import PageSizeSelect from '../../components/PageSizeSelect/PageSizeSelect';

import useGoats from '../../../../hooks/api/use-goats';
import styles from './Goats.module.css';
import { getPageCount } from '../../../../utils/helpers';

const cx = cn.bind(styles);

function Goats(props) {
  const initialValues = {
    ills: '',
    vaccinations: '',
    lambings: '',
    matings: '',
    condFrom: '',
    condTo: '',
    tag: '',
    chip: '',
    name: '',
    emrId: '',
    birthDate: '',
    modified: '',
    orderBy: '',
    page: '1',
    pageSize: '20',
  };

  const { goats, setGoats } = useGoats();

  function handleSubmit(values, actions) {
    setGoats(values, actions);
  }

  function handlePageClick(data, values, actions) {
    setGoats({ ...values, page: data.selected + 1 }, actions);
  }

  function handleSelectChange({ name, value, setFieldValue }, values, actions) {
    setFieldValue(name, value);
    setGoats({ ...values, pageSize: value }, actions);
  }

  return (
    <Container className={styles.container} wrapperClassName={styles.wrapper}>
      <GoatsHeader className={styles.header} />

      {goats?.results ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          render={({ values, ...actions }) => (
            <Form className={styles.form} noValidate>
              <Media query="(max-width: 991px)">
                <MenuFilters>
                  <HeadFilters values={values} actions={actions} setState={setGoats} />
                </MenuFilters>
              </Media>

              <Media query="(min-width: 992px)">
                <div className={cx({ 'filters-wrapper': true })}>
                  <HeadFilters values={values} actions={actions} setState={setGoats} />
                </div>
              </Media>

              <GoatsSearchTable
                goats={goats}
                history={props.history}
                values={values}
                actions={actions}
                onFilter={handleSubmit}
              >
                <>
                  <div>
                    {goats.results.length ? (
                      <CustomPagination
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        pageCount={getPageCount(goats.count, values.pageSize)}
                        onPageChange={(data) => handlePageClick(data, values, actions)}
                      />
                    ) : null}
                  </div>

                  <PageSizeSelect
                    name="pageSize"
                    values={values}
                    onChange={(e) => handleSelectChange(e, values, actions)}
                    {...actions}
                  />
                </>
              </GoatsSearchTable>
            </Form>
          )}
        />
      ) : (
        <p className={cx({ 'empty-results': true })}>Список коз пуст</p>
      )}
    </Container>
  );
}

export default Goats;
