import React from 'react';
import cn from 'classnames';

import Sprite from '../Sprite';

import styles from './ButtonClose.module.css';

function ButtonClose(props) {
  return (
    <button type="button" className={cn(styles.btn, props.className)} onClick={props.onClick}>
      <Sprite
        name="close"
        className={styles.icon}
        role="img"
        focusable="true"
        id="close-modal"
        title="Закрыть"
        stroke={props.stroke}
      />
    </button>
  );
}

export default ButtonClose;
