import React from 'react';

import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Toast from '../../../../components/Toast/Toast';

import useComponentVisible from '../../../../hooks/use-component-visible';
import styles from './Layout.module.css';

function Layout(props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <div className={styles.wrapper}>
      <Header onOpenSidebar={() => setIsComponentVisible(true)} history={props.history} />

      <main>{props.children}</main>

      <Sidebar ref={ref} isOpen={isComponentVisible} onClose={() => setIsComponentVisible(false)} />

      <Toast />
    </div>
  );
}

export default Layout;
