import React, { useEffect, useState, useRef } from 'react';
import Sprite from '../../../../components/Sprite';
import cn from 'classnames/bind';
import styles from './Slider.module.css';

const cx = cn.bind(styles);

export const Slider = (props) => {
    const { children, totalSlides } = props
    const [current, setCurrent] = useState(0)
    const ref = useRef(null)
    const next = () => {
        if (current >= totalSlides - 1) return
        else setCurrent(current + 1)
    }

    const prev = () => {
        if (current === 0) return
        else setCurrent(current - 1)
    }

    useEffect(() => {
        ref.current.style.transition = 'all 0.2s ease-in-out'
        ref.current.style.transform = `translateX(-${current}00%)`
    }, [current])
    return (
        <div className={styles.wrapper}>
            <div className={styles.frame}>
                <div className={styles.container} ref={ref}>
                    {children}
                </div>
            </div>
            <div className={styles.buttons}>
                <div
                className={cx({'arrow-wrapper': true, disabled: current === 0})}
                onClick={prev}>
                    <Sprite
                        name="slider-arrow"
                        role="img"
                        className={cx({ arrow: true })}
                    />
                </div>
                <div
                className={cx({'arrow-wrapper': true, disabled: current >= totalSlides - 1})}
                onClick={next}>
                    <Sprite
                        name="slider-arrow"
                        role="img"
                        className={cx({ arrow: true, 'arrow-right': true })}
                    />
                </div>
            </div>
        </div>

    )
}