import { useState, useEffect } from 'react';
import { decamelizeKeys } from 'humps';

import api from '../../utils/api';
import { removeEmptyAttrsFromObj } from '../../utils/helpers';
import { convertFormDates } from '../../utils/dates';

export default () => {
  const [state, setState] = useState({});

  useEffect(() => {
    api.get('/emr/?page_size=20').then(({ data }) => setState(data));
  }, []);

  return {
    emr: state,

    setEMR: async (values, { setValues, setSubmitting }) => {
      const keys = ['modifierFrom', 'modifierTo', 'birthDate', 'modified'];

      setValues(values);

      values =
        values |> removeEmptyAttrsFromObj |> ((_) => convertFormDates(_, keys)) |> decamelizeKeys;

      return await api
        .get('/emr/', {
          params: convertFormDates(values, keys),
        })
        .then(({ data }) => {
          setState(data);
          setSubmitting(false);
        });
    },
  };
};
