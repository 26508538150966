import React from 'react';
import MaskedInput from 'react-text-mask';

import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker';

function DatepickerField(props) {
  const handleChange =
      (props.name === 'start') ||
      (props.name === 'projectedLamb') ||
      (props.name === 'actualLamb')
      ?
      props.onChange
      :
      (value) => {props.onChange(props.name, value)}

  return (
    <CustomDatePicker
      name={props.name}
      onChange={handleChange}
      value={props.value}
      popperPlacement={props.popperPlacement}
      className={props.className}
      onBlur={props.onBlur}
      customInput={
        <MaskedInput
          mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
          keepCharPositions
          guide={false}
          type="search"
          disabled={props.disabled}
        />
      }
    />
  );
}

export default DatepickerField;
