import React from 'react';
import cn from 'classnames/bind';

import Checkbox from '../Checkbox/Checkbox';
import UserControlButton from '../UserControlButton/UserControlButton';

import styles from './UserTableHeader.module.css';

const cx = cn.bind(styles);

function UserTableHeader(props) {
  return (
    <div className={cx({ header: true })}>
      <div className={cx({ 'header-cell': true, 'cell-checkbox': true })}>
        {!props.edit && (
          <Checkbox onClick={props.onCheckboxClick} checked={props.checked} type={props.type} />
        )}
      </div>

      {props.selectedRows.length ? (
        <div className={cx({ buttons: true })}>
          <UserControlButton name="pen" onClick={props.onEditClick} visible={!props.isEdit}>
            Редактировать
          </UserControlButton>
          <UserControlButton name="trash" onClick={props.onRemoveClick} visible={!props.isEdit}>
            Удалить
          </UserControlButton>
          <UserControlButton name="check-bold" onClick={props.onSaveClick} visible={props.isEdit}>
            Сохранить
          </UserControlButton>
          <UserControlButton name="close-bold" onClick={props.onCancelClick} visible={props.isEdit}>
            Отмена
          </UserControlButton>
        </div>
      ) : null}
    </div>
  );
}

export default UserTableHeader;
