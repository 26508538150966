import React from 'react';
import cn from 'classnames/bind';

import Sprite from '../../../../components/Sprite';
import Container from '../../components/Container/Container';
import PreviewTable from '../../components/PreviewTable/PreviewTable';

import api from '../../../../utils/api';
import styles from './Main.module.css';
import { convertDate } from '../../../../utils/helpers';

const cx = cn.bind(styles);

function fetchData(url, keys, setData, setLinks) {
  api.get(`/${url}/?page_size=7`).then(({ data }) => {
    let linksList = [];
    for (let { id } of data.results) linksList.push(id);

    const rows = Array.from(data.results).map((cell) => {
      let cells = [];
      // for (let key of keys) cells.push(cell?.[key]);
      for (let key of keys) {
        // const currentCell = cell?.[key];

        let currentCell = null;

        if (key !== null && key !== '' && key.includes('.')) {
          const arr = key.split('.');
          currentCell = cell?.[arr[0]]?.[arr[1]];
        } else {
          currentCell = cell?.[key];
        }

        if (key === 'modified' || key === 'birthDate' || key === 'created') {
          let value;

          if (currentCell === '' || currentCell !== null) {
            if (key === 'modified') value = convertDate(currentCell, 'dd.LL.yyyy HH:mm');
            else value = convertDate(currentCell, 'dd.LL.yyyy');
          } else {
            value = currentCell;
          }

          cells.push(value);
        } else {
          cells.push(currentCell);
        }
      }
      return cells;
    });

    setData(rows);
    setLinks(linksList);
  });
}

function Main(props) {
  const [goats, setGoats] = React.useState([]);
  const [emr, setEmr] = React.useState([]);
  const [logs, setLogs] = React.useState([]);
  const [researches, setResearches] = React.useState([]);

  const [goatsLinks, setGoatsLinks] = React.useState([]);
  const [emrLinks, setEmrLinks] = React.useState([]);
  const [logsLinks, setLogsLinks] = React.useState([]);
  const [researchesLinks, setResearchesLinks] = React.useState([]);

  React.useEffect(() => {
    fetchData('goats', ['name', 'tag', 'birthDate', 'emr', 'modified'], setGoats, setGoatsLinks);
    fetchData('emr', ['id', 'goat.name', 'goat.tag', 'modified'], setEmr, setEmrLinks);
    fetchData('logs', ['serialNumber', 'title', 'created', 'modified'], setLogs, setLogsLinks);
    fetchData(
      'research',
      ['id', 'title', 'goatName', 'modified'],
      setResearches,
      setResearchesLinks,
    );
  }, []);

  function handleReloadClick() {
    document.location.reload(false);
  }

  return (
    <Container className={cx({ container: true })}>
      <button className={cx({ 'button-reload': true })} type="button" onClick={handleReloadClick}>
        <Sprite role="img" name="reload" />
        <p>Обновить</p>
      </button>

      <h1 className={cx({ title: true })}>Главная</h1>

      <div className={cx({ grid: true })}>
        <PreviewTable
          title="Список коз"
          headings={['Кличка', 'Номер бирки', 'Дата рождения', 'Номер ЭМК', 'Обновлено']}
          results={goats}
          plaeholder="Список коз пуст"
          createLink="goat/create"
          url="goats"
          linkUrl="goat"
          links={goatsLinks}
          cellClassName={cx({ 'cell-goats': true })}
          history={props.history}
        />

        <PreviewTable
          title="Реестр ЭМК"
          headings={['Номер ЭМК', 'Кличка', 'Номер бирки', 'Обновлено']}
          results={emr}
          plaeholder="Реестр ЭМК пуст"
          createLink="electronic-medical-record/create"
          url="electronic-medical-records"
          linkUrl="electronic-medical-record"
          links={emrLinks}
          cellClassName={cx({ 'cell-emr': true })}
          history={props.history}
        />

        <PreviewTable
          title="Список журналов"
          headings={['Номер журнала', 'Наименование', 'Дата создания', 'Обновлено']}
          results={logs}
          plaeholder="Список журналов пуст"
          createLink="log/create"
          url="logs"
          linkUrl="log"
          links={logsLinks}
          cellClassName={cx({ 'cell-logs': true })}
          history={props.history}
        />

        <PreviewTable
          title="Лабораторные исследования"
          headings={['№ исследования', 'Название', 'Кличка', 'Обновлено']}
          results={researches}
          plaeholder="Список лабораторных исследований пуст"
          createLink="laboratory-research/create"
          url="laboratory-researches"
          linkUrl="laboratory-research"
          links={researchesLinks}
          cellClassName={cx({ 'cell-researches': true })}
          history={props.history}
        />
      </div>
    </Container>
  );
}

export default Main;
