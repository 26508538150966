import React from 'react';
import cn from 'classnames/bind';
import { Field } from 'formik';

import BlockWrapper from '../../containers/BlockWrapper/BlockWrapper';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import DatepickerField from '../../components/DatepickerField/DatepickerField';

import styles from './CardInfoBlock.module.css';
import * as errorMessages from '../../../../utils/error-messages';
import * as regExp from '../../../../utils/regular-expressions';
import { convertDateToDMY } from '../../../../utils/dates';
import GoatsTreeButton from '../GoatsTreeButton/GoatsTreeButton';
import SearchSelect from '../SearchSelect/SearchSelect';
import AdopterGalleryField from '../AdopterGalleryField/AdopterGalleryField';
import DetailedGalleryField from '../DetailedGalleryField/DetailedGalleryField';

const cx = cn.bind(styles);
const selectStyles = {
  backgroundColor: 'var(--gray)',
  border: '1px solid #d2e1e7',
  borderHover: '1px solid #b0c9d3',
};

function statusSwitch(value) {
  switch (value) {
    case 'young':
      return 'Молодняк';
    case 'grown':
      return 'Взрослые особи, находящиеся в нагуле и дойное стадо';
    case 'rejected':
      return 'Выбракованные из основного стада на продажу (без откорма)';
    default:
      return '';
  }
};
function statusIsDeadSwitch(isDead, isBorked, isAdopted) {
  if (!isDead && !isBorked && !isAdopted) {
    return 'Жив';
  } else if (isDead) {
    return 'Мертв';
  } else if (isBorked) {
    return 'Выбракован'
  } else if (isAdopted && !isDead && !isBorked) {
    return 'Хорошие руки'
  }

}

// function grownStatusSwitch(value) {
//   switch (value) {
//     case 'milk':
//       return 'Дойные козы';
//     case 'producers':
//       return 'Козлы производители';
//     default:
//       return '';
//   }
// }

function rejectedStatusSwitch(value) {
  switch (value) {
    case 'ill':
      return 'Заболевшие';
    case 'defect':
      return 'Брак';
    default:
      return '';
  }
}

function genderSwitch(value) {
  switch (value) {
    case 'f':
      return 'Самка';
    case 'm':
      return 'Самец';
    default:
      return '';
  }
}

function isCastratedSwitch(value) {
  switch (value) {
    case true:
      return 'Кастрирован';
    case false:
      return 'Не кастрирован';
    default:
      return '';
  }
}

function truthnessSwicth(value) {
  switch (value) {
    case true:
      return 'Да';
    case false:
      return 'Нет';
    default:
      return '';
  }
}

function CardInfoBlock(props) {
  const [statusSelect, setStatusSelect] = React.useState('');
  const [genderSelect, setGenderSelect] = React.useState('');
  const [isDeadSelect, setIsDeadSelect] = React.useState(false);
  const [isBorkedSelect, setIsBorkedSelect] = React.useState(false);
  const [isAdoptedSelect, setIsAdoptedSelect] = React.useState(false)
  console.log(props)
  function handleSelectChange({ name, value, label, setFieldValue }) {
    // if (value === 'grown') setFieldValue(name, { value, label });
    // else setFieldValue(name, value);
    setFieldValue(name, value);
    // Status select
    if (value === 'grown' || value === 'rejected') setStatusSelect(value);
    else if (value === 'young') {
      setStatusSelect('');
      setFieldValue('grownType', '');
      setFieldValue('rejectedType', '');
    }

    // Gender select
    if (value === 'm') setGenderSelect(value);
    else if (value === 'f') {
      setGenderSelect('');
      setFieldValue('isCastrated', null);
    };
    // isDead select
    if (value === 'dead' && label === "Мертв") {
      setFieldValue('isDead', true);
      setIsDeadSelect(true);
      setIsAdoptedSelect(false);
      setIsBorkedSelect(false)
      setFieldValue('isAdopted', false);
      setFieldValue('adopter', '');
      setFieldValue('borkedCause', '');
      setFieldValue('borkedDate', '');
    }
    if (value === 'borked' && label === "Выбракован") {
      setFieldValue('isBorked', true);
      setIsBorkedSelect(true);
      setIsDeadSelect(false);
      setIsAdoptedSelect(false);
      setFieldValue('isDead', false);
      setFieldValue('deathCause', '');
      setFieldValue('deathDate', '');
      setFieldValue('isAdopted', false);
      setFieldValue('adopter', '');
    } else if (value === 'alive' && label === "Жив") {
      setIsDeadSelect(false);
      setIsBorkedSelect(false);
      setIsAdoptedSelect(false);
      setFieldValue('isDead', false);
      setFieldValue('isBorked', false);
      setFieldValue('deathCause', '');
      setFieldValue('deathDate', '');
      setFieldValue('borkedCause', '');
      setFieldValue('borkedDate', '');
      setFieldValue('isAdopted', false);
      setFieldValue('adopter', '');
    } else if (value === 'adopted' && label === "Хорошие руки") {
      setFieldValue('isAdopted', true);
      setIsAdoptedSelect(true);
      setIsBorkedSelect(false);
      setIsDeadSelect(false);
      setFieldValue('isDead', false);
      setFieldValue('isBorked', false);
      setFieldValue('deathCause', '');
      setFieldValue('deathDate', '');
      setFieldValue('borkedCause', '');
      setFieldValue('borkedDate', '');

    }
  }
  function handleSearchSelectChange({ name, value, label, setFieldValue }) {
    setFieldValue(name, value)
  }
  function removeDuplicatesOptions(arr) {
    return arr
      .filter(Boolean)
      .filter((v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i);
  }
  return (
    <div className={styles.container}>
      <BlockWrapper className={styles.wrapper}>
        <Table title="Основная информация">
          <FieldRow
            name="tag"
            title="Номер бирки"
            onValidate={(value) => !regExp.onlyNumeric.test(value)}
            maxLength="4"
            text={props.values.tag}
            type="text"
            {...props}
          />

          <FieldRow
            name="chip"
            title="Номер чипа"
            onValidate={(value) => !regExp.onlyNumeric.test(value)}
            maxLength="15"
            text={props.values.chip}
            type="text"
            {...props}
          />

          <FieldRow
            name="name"
            title="Кличка"
            onValidate={(value) => regExp.newOnlyLetAndNum.test(value)}
            maxLength="128"
            text={props.values.name}
            type="text"
            {...props}
          />
          {props.values.isAdopted && (
            <AdopterGalleryField
              id={props.values.id}
              setFieldValue={props.setFieldValue}
              isEditable={props.isEditable}
              ownerPhotos={props.values.ownerPhotos}
            />
          )}
          <DetailedGalleryField
            id={props.values.id}
            setFieldValue={props.setFieldValue}
            isEditable={props.isEditable}
            detailedPhotos={props.values.detailedPhotos}
          />

          <SelectRow
            name="status"
            title="Статус"
            onChange={handleSelectChange}
            text={statusSwitch(props.values.status)}
            options={[
              { value: 'young', label: 'Молодняк' },
              { value: 'grown', label: 'Взрослые особи, находящиеся в нагуле и дойное стадо' },
              {
                value: 'rejected',
                label: 'Выбракованные из основного стада на продажу (без откорма)',
              },
            ]}
            {...props}
          />

          {(statusSelect === 'grown' ||
            (props.values.status === 'grown' && props.values.grownType)) && (
              <SelectRow
                isCreateble
                name="grownType"
                onChange={handleSelectChange}
                text={props.values.grownType}
                options={removeDuplicatesOptions([
                  { value: 'Дойные козы', label: 'Дойные козы' },
                  { value: 'Козлы производители', label: 'Козлы производители' },
                  props.values.grownType && {
                    value: props.values.grownType,
                    label: props.values.grownType,
                  },
                ])}
                validate={(value) => {
                  if (props.values.status === 'grown' && !value) return errorMessages.selectBlank;
                }}
                {...props}
              />
            )}

          {(statusSelect === 'rejected' ||
            (props.isEditable &&
              props.values.status === 'rejected' &&
              props.values.rejectedType)) && (
              <SelectRow
                name="rejectedType"
                onChange={handleSelectChange}
                text={rejectedStatusSwitch(props.values.rejectedType)}
                options={[
                  { value: 'ill', label: 'Заболевшие' },
                  { value: 'defect', label: 'Брак' },
                ]}
                validate={(value) => {
                  if (props.values.status === 'rejected' && !value) return errorMessages.selectBlank;
                }}
                {...props}
              />
            )}

          <SelectRow
            name="gender"
            title="Пол"
            onChange={handleSelectChange}
            options={[
              { value: 'f', label: 'Самка' },
              { value: 'm', label: 'Самец' },
            ]}
            text={genderSwitch(props.values.gender)}
            {...props}
          />

          {(genderSelect === 'm' || (props.values.gender === 'm' && props.values.isCastrated)) && (
            <SelectRow
              name="isCastrated"
              onChange={handleSelectChange}
              text={isCastratedSwitch(props.values.isCastrated)}
              options={[
                { value: true, label: 'Кастрирован' },
                { value: false, label: 'Не кастрирован' },
              ]}
              validate={(value) => {
                if (props.values.gender === 'm' && value === null) return errorMessages.selectBlank;
              }}
              {...props}
            />
          )}

          <FieldRow
            name="annotation"
            title="Дополнительно"
            maxLength="128"
            text={props.values.annotation}
            type="text"
            {...props}
          />
          <FieldRow
            name="dateMating"
            title="Дата случки"
            text={props.values.dateMating ? convertDateToDMY(props.values.dateMating) : ''}
            render={({ field }) => (
              <DatepickerField
                {...field}
                className={styles.field}
                value={props.values.dateMating}
                onChange={props.setFieldValue}
                onBlur={() => props.setFieldTouched('dateMating')}
              />
            )}
            {...props}
          />
          <FieldRow
            name="father"
            title="Имя отца"
            onValidate={(value) => regExp.newOnlyLetAndNum.test(value)}
            maxLength="128"
            text={(props.values.fatherObj && props.values.fatherObj[0].name)}
            type="text"
            {...props}
          />
          <SearchSelectRow
            name="fatherGoat"
            title="Карточка отца"
            onChange={handleSearchSelectChange}
            text={(props.values.fatherObj && props.values.fatherObj[0].name) || props.values.father}
            {...props}
          />
          <SearchSelectRow
            name="motherGoat"
            title="Карточка матери"
            onChange={handleSearchSelectChange}
            text={(props.values.motherObj && props.values.motherObj[0].name) || props.values.mother}
            {...props}
          />
          <FieldRow
            name="mother"
            title="Имя матери"
            onValidate={(value) => regExp.newOnlyLetAndNum.test(value)}
            maxLength="128"
            text={(props.values.motherObj && props.values.motherObj[0].name)}
            type="text"
            {...props}
          />

          <SelectRow
            name="statusLive"
            title="Статус"
            onChange={handleSelectChange}
            text={statusIsDeadSwitch(props.values.isDead, props.values.isBorked, props.values.isAdopted)}
            options={[
              { value: 'dead', label: 'Мертв' },
              { value: 'alive', label: 'Жив' },
              { value: 'borked', label: 'Выбракован' },
              { value: 'adopted', label: 'Хорошие руки' }
            ]}

            {...props}
          />
          {(isDeadSelect === true || (props.values.isDead === true && props.values.deathCause)) &&

            (<FieldRow
              isCreateble
              name="deathCause"
              title="Причина смерти"
              onValidate={(value) => regExp.newOnlyLetAndNum.test(value)}
              maxLength="128"
              text={props.values.deathCause}
              type="text"
              validate={(value) => {
                if (props.values.isDead === true && !value) return errorMessages.blank;
              }}
              {...props}
            />)


          }
          {(isDeadSelect === true || (props.values.isDead === true && props.values.deathDate)) && (
            <FieldRow
              isCreateble
              name="deathDate"
              title="Дата смерти"
              text={props.values.deathDate ? convertDateToDMY(props.values.deathDate) : ''}
              render={({ field }) => (
                <DatepickerField
                  {...field}
                  className={styles.field}
                  value={props.values.deathDate}
                  onChange={props.setFieldValue}
                  onBlur={() => props.setFieldTouched('deathDate')}
                />
              )}
              validate={(value) => {
                if (props.values.isDead === true && !value) return errorMessages.dateBlank;
              }}
              {...props}
            />
          )}

          {(isBorkedSelect === true && !isDeadSelect || (props.values.isBorked === true && props.values.borkedCause)) &&

            (<FieldRow
              isCreateble
              name="borkedCause"
              title="Причина выбраковки"
              onValidate={(value) => regExp.newOnlyLetAndNum.test(value)}
              maxLength="128"
              text={props.values.borkedCause}
              type="text"
              validate={(value) => {
                if (props.values.isBorked === true && !value) return errorMessages.blank;
              }}
              {...props}
            />)

          }

          {(isBorkedSelect === true && !isDeadSelect || (props.values.isBorked === true && props.values.borkedDate)) &&

            <FieldRow
              isCreateble
              name="borkedDate"
              title="Дата выбраковки"
              text={props.values.borkedDate ? convertDateToDMY(props.values.borkedDate) : ''}
              render={({ field }) => (
                <DatepickerField
                  {...field}
                  className={styles.field}
                  value={props.values.borkedDate}
                  onChange={props.setFieldValue}
                  onBlur={() => props.setFieldTouched('borkedDate')}
                />
              )}
              validate={(value) => {
                if (props.values.isBorked === true && !value) return errorMessages.dateBlank;
              }}
              {...props}
            />

          }
          {(isAdoptedSelect === true || (props.values.isAdopted === true && props.values.adopter)) && (
            <FieldRow
              name="adopter"
              title="Владелец"
              onValidate={(value) => regExp.newOnlyLetAndNum.test(value)}
              maxLength="128"
              text={props.values.adopter}
              type="text"
              validate={(value) => {
                if (props.values.isAdopted === true && !value) return errorMessages.blank;
              }}
              {...props}
            />
          )}
          {props.values.fatherObj && props.values.motherObj && (
            <tr className={styles.row}>
              <th className={cx({ 'row-title': true, 'row-read': true })}>Родственники</th>
              <td className={cx({ cell: true })}>
                <GoatsTreeButton goatsTree={props.values} />
              </td>
            </tr>
          )}
        </Table>

        <Table title="Дополнительная информация" {...props}>
          <FieldRow
            name="birthDate"
            title="Дата рождения"
            text={props.values.birthDate ? convertDateToDMY(props.values.birthDate) : ''}
            render={({ field }) => (
              <DatepickerField
                {...field}
                className={styles.field}
                value={props.values.birthDate}
                onChange={props.setFieldValue}
                onBlur={() => props.setFieldTouched('birthDate')}
              />
            )}
            {...props}
          />

          <FieldRow
            name="color"
            title="Цвет"
            onValidate={(value) => value.match(regExp.multiLetterMatch)}
            maxLength="128"
            text={props.values.color}
            type="text"
            {...props}
          />

          <FieldRow
            name="breed"
            title="Порода"
            onValidate={(value) => value.match(regExp.multiLetterMatch)}
            maxLength="128"
            text={props.values.breed}
            type="text"
            {...props}
          />

          <FieldRow
            name="fatherBreed"
            title="Порода отца"
            onValidate={(value) => value.match(regExp.multiLetterMatch)}
            maxLength="128"
            text={props.values.fatherBreed}
            type="text"
            {...props}
          />

          <FieldRow
            name="motherBreed"
            title="Порода матери"
            onValidate={(value) => value.match(regExp.multiLetterMatch)}
            maxLength="128"
            text={props.values.motherBreed}
            type="text"
            {...props}
          />

          {/* <SelectRow
            name="isDehydrated"
            title="Обезрожены"
            onChange={handleSelectChange}
            text={truthnessSwicth(props.values.isDehydrated)}
            options={[{ value: true, label: 'Да' }, { value: false, label: 'Нет' }]}
            {...props}
          /> */}

          <SelectRow
            name="isHornless"
            title="Комолые"
            onChange={handleSelectChange}
            text={truthnessSwicth(props.values.isHornless)}
            options={[
              { value: true, label: 'Да' },
              { value: false, label: 'Нет' },
            ]}
            {...props}
          />
        </Table>
      </BlockWrapper>
    </div>
  );
}

function Table(props) {
  return (
    <table className={styles.table}>
      <caption className={cx({ 'table-title': true })}>{props.title}</caption>

      <tbody>{props.children}</tbody>
    </table>
  );
}

function Row(props) {
  const name = props.name;
  const error = props.errors[name];
  const touched = props.touched[name];
  const isEditable = props.isEditable;

  return (
    <tr className={styles.row}>
      <th className={cx({ 'row-title': true, 'row-read': !isEditable })}>{props.title}</th>

      <td className={cx({ cell: true })}>
        {isEditable ? props.children : <p className={styles.text}>{props.text}</p>}
      </td>

      {touched && error && isEditable && <td className={styles.error}>{error}</td>}
    </tr>
  );
}

function Select(props) {
  const [value, setValue] = React.useState('');
  const [options, setOptions] = React.useState(props.options);

  function handleAddOptionClick() {
    setOptions([...options, { value, label: value }]);
    props.setFieldValue(props.name, value);
    setValue('');
  }

  return (
    <>
      <CustomSelect
        name={props.name}
        onBlur={props.setFieldTouched}
        onChange={({ value, label }) =>
          props.onChange({ name: props.name, value, label, setFieldValue: props.setFieldValue })
        }
        value={props.values[props.name]}
        error={props.errors[props.name]}
        touched={props.touched[props.name]}
        options={options}
        styles={selectStyles}
        validate={props.validate}
      />

      {props.isCreateble && (
        <div className={cx({ 'adding-field-wrapper': true })}>
          <input
            className={cx({ field: true })}
            type="text"
            onChange={({ target }) => setValue(target.value)}
            value={value}
          />
          <button
            onClick={handleAddOptionClick}
            type="button"
            className={cx({ 'adding-button': true })}
          >
            Добавить
          </button>
        </div>
      )}
    </>
  );
}

function FieldRow(props) {
  const name = props.name;
  const error = props.errors[name];
  const touched = props.touched[name];

  return (
    <Row {...props}>
      <Field
        name={props.name}
        maxLength={props.maxLength}
        type={props.type}
        validate={props.validate || null}
        onChange={(e) => {
          const value = e.target.value;

          if (props.onValidate) {
            if (props.onValidate(value)) {
              props.setFieldValue(props.name, value);
            }
          } else {
            props.setFieldValue(props.name, value);
          }
        }}
        render={props.render}
        className={cx({ field: true, 'field-error': touched && error })}
      />
    </Row>
  );
}

function SelectRow(props) {

  return (
    <Row {...props}>
      <Select {...props} />
    </Row>
  );
}
function SearchSelectRow(props) {

  const handleChange = ({ value, label }) => {
    props.onChange({ name: props.name, value, label, setFieldValue: props.setFieldValue })
  }
  return (
    <Row {...props}>
      <Field
        name={props.name}
        onBlur={props.setFieldTouched}
        error={props.errors[props.name]}
        touched={props.touched[props.name]}
        styles={selectStyles}
        component={SearchSelect}
        onChange={(data) => {
          handleChange(data)
        }}
      />
    </Row>
  )
}

export default CardInfoBlock;
