// НЕ ИСПОЛЬЗУЕТСЯ БОЛЬШЕ !!!!!
import React from 'react';

import cn from 'classnames/bind';

import SearchTable from '../../components/SearchTable/SearchTable';
import SearchTableHeadCell from '../../components/SearchTableHeadCell/SearchTableHeadCell';
import DatepickerTableHeadCell from '../../components/DatepickerTableHeadCell/DatepickerTableHeadCell';
import SearchTableBody from '../../components/SearchTableBody/SearchTableBody';
import SearchTableBodyCell from '../../components/SearchTableBodyCell/SearchTableBodyCell';

// import styles from './LambSearchTable.module.css';
import styles from './LambSearchTableAnother.module.css';
import ColorPicker from '../ColorPicker/ColorPicker';
import Note from '../Note/Note';
import LambTableHead from '../LambTableHead/LambTableHead';
// import {convertDate} from "../../../../utils/helpers";
import DatepickerField from '../DatepickerField/DatepickerField';

const cx = cn.bind(styles);

function LambSearchTable(props) {
  function handleRowClick(id) {
    // props.history.push(`/goat/${id}/`);
  }

  const todayDate = new Date().toLocaleDateString();
  const yearNow = new Date().getFullYear();

  return (
    <section className={styles.wrapper}>
      <SearchTable>
        <thead>
          <tr className={styles.preHead}>
            <th className={styles.year}>Окот в {yearNow} году</th>
            <th className={styles.today}>Сегодня {todayDate}</th>
            <th className={styles.gland}>Состояние железы</th>
            <th className={styles.child}>Кол-во приплода</th>
          </tr>
        </thead>
        <LambTableHead rowClassName={cx({ 'table-row': true })}>
          <th className={cx({ 'table-cell': true })}>Цвет</th>
          <SearchTableHeadCell
            className={cx({ 'table-cell': true })}
            name="chip"
            icon="magnifier"
            actions={props.actions}
          >
            Номер чипа
          </SearchTableHeadCell>
          <SearchTableHeadCell
            className={cx({ 'table-cell': true })}
            name="house"
            icon="magnifier"
            actions={props.actions}
          >
            Номер дома
          </SearchTableHeadCell>
          <SearchTableHeadCell
            className={cx({ 'table-cell': true })}
            name="name"
            icon="magnifier"
            actions={props.actions}
          >
            {/*<button*/}
            {/*  type="button"*/}
            {/*  icon="magnifier"*/}
            {/*  onClick={() => handleOrderClick('tag', props.values, props.actions)}*/}
            {/*>*/}
            Коза (кличка)
            {/*  <Sprite*/}
            {/*    name="sort-arrow"*/}
            {/*    role="img"*/}
            {/*    className={cx({ arrow: true, 'arrow-ordered': props.values.orderBy === '-tag' })}*/}
            {/*  />*/}
            {/*</button>*/}
          </SearchTableHeadCell>

          <SearchTableHeadCell
            className={cx({ 'table-cell': true })}
            name="father"
            icon="magnifier"
            actions={props.actions}
          >
            Производитель (кличка)
          </SearchTableHeadCell>

          <DatepickerTableHeadCell
            className={cx({ 'table-cell': true })}
            name="date_mating"
            icon="calendar"
            actions={props.actions}
            value={props.values.date_mating}
          >
            Дата случки
          </DatepickerTableHeadCell>

          <DatepickerTableHeadCell
            className={cx({ 'table-cell': true })}
            name="start"
            icon="calendar"
            actions={props.actions}
            popperPlacement="bottom-end"
            value={props.values.start}
          >
            Запуск
          </DatepickerTableHeadCell>

          <DatepickerTableHeadCell
            className={cx({ 'table-cell': true })}
            name="projected_lamb"
            icon="calendar"
            actions={props.actions}
            popperPlacement="bottom-end"
            value={props.values.projected_lamb}
          >
            Прогноз окота
          </DatepickerTableHeadCell>

          <SearchTableHeadCell
            className={cx({ 'table-cell': true })}
            name="days_for_lamb"
            icon="magnifier"
            actions={props.actions}
          >
            Осталось до окота
          </SearchTableHeadCell>

          <DatepickerTableHeadCell
            className={cx({ 'table-cell': true })}
            name="actual_lamb"
            icon="calendar"
            actions={props.actions}
            popperPlacement="bottom-end"
            value={props.values.actual_lamb}
          >
            Фактический окот
          </DatepickerTableHeadCell>

          <th className={cx({ 'table-cell': true })}>Левая (%)</th>
          <th className={cx({ 'table-cell': true })}>Правая (%)</th>

          <th className={cx({ 'table-cell': true })}>Всего</th>

          <SearchTableHeadCell
            className={cx({ 'table-cell': true })}
            name="dead_children"
            icon="magnifier"
            actions={props.actions}
          >
            Мертв
          </SearchTableHeadCell>

          <SearchTableHeadCell
            className={cx({ 'table-cell': true })}
            name="goat_male"
            icon="magnifier"
            actions={props.actions}
          >
            Козлик
          </SearchTableHeadCell>

          <SearchTableHeadCell
            className={cx({ 'table-cell': true })}
            name="goat_female"
            icon="magnifier"
            actions={props.actions}
          >
            Козочка
          </SearchTableHeadCell>

          <th className={cx({ 'table-cell': true })}>Примечание</th>
        </LambTableHead>

        <SearchTableBody results={props.goats.results.length}>
          {props.goats.results.map(
            ({
              id,
              name,
              father,
              dateMating,
              start,
              projectedLamb,
              daysForLamb,
              actualLamb,
              leftMammaryGland,
              rightMammaryGland,
              totalChildren,
              deadChildren,
              goatMale,
              goatFemale,
              columnColor,
              note,
              chip,
              house,
            }) => {
              // projectedLamb = projectedLamb && convertDate(projectedLamb, 'd.LL.yyyy');
              // actualLamb = actualLamb && convertDate(actualLamb, 'd.LL.yyyy');
              // dateMating = dateMating && convertDate(dateMating, 'd.LL.yyyy');
              // start = start && convertDate(start, 'd.LL.yyyy');

              return (
                <tr
                  key={id}
                  onClick={() => handleRowClick(id)}
                  className={cx({ 'table-row': true, 'table-body-row': true })}
                  bgcolor={columnColor}
                  style={columnColor === '#333333' || columnColor === '#000000' ? {color: '#fff'} : {color: '#0c1116'}}
                >
                  <td className={cx({ 'table-cell': true })}>
                    {props.idGoat === id && (
                      <ColorPicker changeHandler={(e) => props.colorChangeHandler(id, e)} />
                    )}
                    <button
                      className={styles.button}
                      onClick={() => props.handleClickColor(id, columnColor)}
                    >
                      {columnColor ? 'Снять' : 'Выделить'}
                    </button>
                  </td>
                  <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                    {chip}
                  </SearchTableBodyCell>

                  <td className={cx({ 'table-cell': true })}>
                    <Note
                      value={house ? house : ''}
                      handleChangeNote={(e) => props.handleChangeNote(e, id)}
                      handleInputNote={(e) => props.handleInputNote(e, id, 'house')}
                      handleEnterPressNote={(e) => props.handleEnterPressNote(e, id, 'house')}
                    />
                  </td>

                  <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                    {name}
                  </SearchTableBodyCell>

                  <td className={cx({ 'table-cell': true })}>
                    <Note
                      value={father ? father : ''}
                      handleChangeNote={(e) => props.handleChangeNote(e, id)}
                      handleInputNote={(e) => props.handleInputNote(e, id, 'father')}
                      handleEnterPressNote={(e) => props.handleEnterPressNote(e, id, 'father')}
                    />
                  </td>

                  <td className={cx({ 'table-cell': true })}>
                    <DatepickerField
                      name="dateMating"
                      onChange={(value) => props.handleDateChange(value, id, 'dateMating')}
                      className={styles.datepicker}
                      value={dateMating}
                      icon="calendar"
                      popperPlacement="bottom-end"
                    />
                  </td>

                  <td className={cx({ 'table-cell': true })}>
                    <DatepickerField
                      name="start"
                      onChange={(value) => props.handleDateChange(value, id, 'start')}
                      className={styles.datepicker}
                      value={start}
                      icon="calendar"
                      popperPlacement="bottom-end"
                    />
                  </td>

                  <td className={cx({ 'table-cell': true })}>
                    <DatepickerField
                      name="projectedLamb"
                      onChange={(value) => props.handleDateChange(value, id, 'projectedLamb')}
                      className={styles.datepicker}
                      value={projectedLamb}
                      icon="calendar"
                      popperPlacement="bottom-end"
                    />
                  </td>

                  {/*<SearchTableBodyCell className={cx({ 'table-cell': true })}>*/}
                  {/*  {daysForLamb}*/}
                  {/*</SearchTableBodyCell>*/}

                  <td className={cx({ 'table-cell': true })}>
                    <Note
                      name="daysForLamb"
                      value={daysForLamb ? daysForLamb : 0}
                      handleChangeNote={(e) => props.handleChangeNote(e, id)}
                      handleInputNote={(e) => props.handleInputNote(e, id, 'daysForLamb')}
                      handleEnterPressNote={(e) => props.handleEnterPressNote(e, id, 'daysForLamb')}
                    />
                  </td>

                  <td className={cx({ 'table-cell': true })}>
                    <DatepickerField
                      name="actualLamb"
                      onChange={(value) => props.handleDateChange(value, id, 'actualLamb')}
                      className={styles.datepicker}
                      value={actualLamb}
                      icon="calendar"
                      popperPlacement="bottom-end"
                    />
                  </td>

                  <td className={cx({ 'table-cell': true })}>
                    <Note
                      name="leftMammaryGland"
                      value={leftMammaryGland ? leftMammaryGland : 0}
                      handleChangeNote={(e) => props.handleChangeNote(e, id)}
                      handleInputNote={(e) => props.handleInputNote(e, id, 'leftMammaryGland')}
                      handleEnterPressNote={(e) => props.handleEnterPressNote(e, id, 'leftMammaryGland')}
                    />
                  </td>

                  <td className={cx({ 'table-cell': true })}>
                    <Note
                      name="rightMammaryGland"
                      value={rightMammaryGland ? rightMammaryGland : 0}
                      handleChangeNote={(e) => props.handleChangeNote(e, id)}
                      handleInputNote={(e) => props.handleInputNote(e, id, 'rightMammaryGland')}
                      handleEnterPressNote={(e) =>
                        props.handleEnterPressNote(e, id, 'rightMammaryGland')
                      }
                    />
                  </td>

                  <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                    {totalChildren}
                  </SearchTableBodyCell>

                  {/*<td className={cx({ 'table-cell': true })}>*/}
                  {/*  <Note*/}
                  {/*    name='totalChildren'*/}
                  {/*    value={totalChildren ? totalChildren : 0}*/}
                  {/*    handleChangeNote={(e) => props.handleChangeNote(e, id)}*/}
                  {/*    handleInputNote={(e) => props.handleInputNote(e, id, 'totalChildren')}*/}
                  {/*  />*/}
                  {/*</td>*/}

                  <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                    {deadChildren}
                  </SearchTableBodyCell>

                  <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                    {goatMale}
                  </SearchTableBodyCell>

                  <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                    {goatFemale}
                  </SearchTableBodyCell>

                  {/* <td className={cx({ 'table-cell': true })}>
                    <Note
                      name="deadChildren"
                      value={deadChildren ? deadChildren : 0}
                      handleChangeNote={(e) => props.handleChangeNote(e, id)}
                      handleInputNote={(e) => props.handleInputNote(e, id, 'deadChildren')}
                    />
                  </td>

                  <td className={cx({ 'table-cell': true })}>
                    <Note
                      name="goatMale"
                      value={goatMale ? goatMale : 0}
                      handleChangeNote={(e) => props.handleChangeNote(e, id)}
                      handleInputNote={(e) => props.handleInputNote(e, id, 'goatMale')}
                    />
                  </td>

                  <td className={cx({ 'table-cell': true })}>
                    <Note
                      name="goatFemale"
                      value={goatFemale ? goatFemale : 0}
                      handleChangeNote={(e) => props.handleChangeNote(e, id)}
                      handleInputNote={(e) => props.handleInputNote(e, id, 'goatFemale')}
                    />
                  </td> */}

                  <td className={cx({ 'table-cell': true })}>
                    <Note
                      value={note ? note : ''}
                      handleChangeNote={(e) => props.handleChangeNote(e, id)}
                      handleInputNote={(e) => props.handleInputNote(e, id, 'note')}
                      handleEnterPressNote={(e) => props.handleEnterPressNote(e, id, 'note')}
                    />
                  </td>
                </tr>
              );
            },
          )}
        </SearchTableBody>
      </SearchTable>

      <div className={styles.footer}>{props.children}</div>
    </section>
  );
}

export default LambSearchTable;
