import React from 'react';
import MaskedInput from 'react-text-mask';
import { Field, ErrorMessage } from 'formik';
import cn from 'classnames/bind';

import styles from './PhoneField.module.css';

const cx = cn.bind(styles);

function TextField(props) {
  return (
    <>
      <Field
        name={props.name}
        render={({ field }) => (
          <MaskedInput
            mask={[
              '+',
              /[1-9]/,
              ' ',
              '(',
              /[1-9]/,
              /\d/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
            ]}
            guide={false}
            {...field}
            type="tel"
            id={props.id}
            placeholder="+_ (___) ___-__-__"
            className={cx(
              {
                field: true,
                'field-error': !!props.error,
              },
              props.fieldClassName,
            )}
          />
        )}
      />

      <ErrorMessage
        name={props.name}
        component="p"
        className={cn(styles.error, props.errorClassName)}
      />
    </>
  );
}

export default TextField;
