import React from 'react';

import styles from './PageTitle.module.css';

function PageTitle(props) {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{props.title}</h1>

      {props.children}
    </div>
  );
}

export default PageTitle;
