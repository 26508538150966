import React from 'react';
import cn from 'classnames/bind';
import { FieldArray, Field } from 'formik';

import BlockWrapper from '../../containers/BlockWrapper/BlockWrapper';
import DatepickerField from '../../components/DatepickerField/DatepickerField';

import styles from './WeighingBlock.module.css';
import * as regExp from '../../../../utils/regular-expressions';

const cx = cn.bind(styles);

function WeighingBlock(props) {
  const { values, errors, touched } = props;
  const [isShowAllWeighing, setIsShowAllWeighing] = React.useState(false);

  function handleClick(arrayHelpers) {
    if (!errors.weighing) {
      arrayHelpers.push({ date: '', weight: '' });
    }
  }

  return (
    <BlockWrapper>
      {!values.weighing[0] && !props.isEditable ? (
        <div className={cx({ 'empty-block': true })}>
          <h2 className={styles.title}>Взвешивания</h2>

          <p className={cx({ 'empty-text': true })}>Взвешивания отсутствуют</p>
        </div>
      ) : (
        <div className={cx({ 'full-block': !props.isEditable })}>
          <div className={styles.header}>
            <h2 className={styles.title}>Взвешивания</h2>
          </div>

          <FieldArray
            name="weighing"
            render={(arrayHelpers) => (
              <div className={styles.content}>
                {values.weighing[0] && (
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th className={cx({ cell: true, 'head-cell': true })}>Дата</th>
                        <th className={cx({ cell: true, 'head-cell': true })}>Кг</th>
                      </tr>
                    </thead>

                    <tbody>
                      {values.weighing[0] &&
                        values.weighing.map((_, index) => (
                          <Row
                            key={index}
                            dateName={`weighing[${index}].date`}
                            weightName={`weighing[${index}].weight`}
                            placeholder="Пусто"
                            dateValue={values.weighing[index].date}
                            error={errors.weighing && errors.weighing[index]}
                            touched={touched.weighing && touched.weighing[index]}
                            setFieldValue={props.setFieldValue}
                            setFieldTouched={props.setFieldTouched}
                            disabled={!props.isEditable}
                            className={cx({
                              'hidden-row': !props.isEditable && index > 3,
                              'visible-row': isShowAllWeighing,
                            })}
                          />
                        ))}
                    </tbody>
                  </table>
                )}

                {props.isEditable && (
                  <button
                    className={cx({ 'btn-add': true })}
                    type="button"
                    onClick={() => handleClick(arrayHelpers)}
                  >
                    Добавить
                  </button>
                )}

                {!props.isEditable && values?.weighing.length > 4 && !isShowAllWeighing && (
                  <button
                    type="button"
                    className={cx({ 'show-more-button': true })}
                    onClick={() => setIsShowAllWeighing(true)}
                  >
                    Смотреть все
                  </button>
                )}
              </div>
            )}
          />
        </div>
      )}
    </BlockWrapper>
  );
}

function Row(props) {
  const error = props.error && Object.keys(props.error)[0];
  const touched = props.touched;

  return (
    <tr className={cn(styles.row, props.className)}>
      <td className={cx({ cell: true, 'data-cell': true })}>
        <Field
          name={props.dateName}
          render={({ field }) => (
            <DatepickerField
              {...field}
              className={styles.field}
              value={props.dateValue}
              onChange={props.setFieldValue}
              disabled={props.disabled}
              onBlur={() => props.setFieldTouched(props.dateName)}
            />
          )}
        />

        {touched?.date && error === 'date' && (
          <p
            className={cx({
              error: true,
              'error-date': true,
            })}
          >
            {props.error?.date}
          </p>
        )}
      </td>

      <td className={cx({ cell: true, 'data-cell': true })}>
        <Field
          name={props.weightName}
          className={cx({ field: true, 'field-weight': true })}
          placeholder={props.placeholder}
          disabled={props.disabled}
          type="text"
          onChange={(e) => {
            const value = e.target.value;

            if (regExp.weightNum.test(value)) {
              props.setFieldValue(props.weightName, value);
            }
          }}
        />

        {touched?.weight && error === 'weight' && (
          <p
            className={cx({
              error: true,
              'error-weight': true,
            })}
          >
            {props.error?.weight}
          </p>
        )}
      </td>
    </tr>
  );
}

export default WeighingBlock;
