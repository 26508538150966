export const symbolValue = 'Пароль должен содержать одну цифру и одну букву';
export const invalidInput = 'Некорректный ввод';
export const selectBlank = 'Необходимо выбрать один из предложенных вариантов';
export const weightBlank = 'Пожалуйста, укажите вес';
export const dateBlank = 'Пожалуйста, укажите дату';

export const maxLength = 'Убедитесь, что это поле содержит не более 256 символов';
export const blank = 'Это поле не может быть пустым';
export const emailNotFound = 'Этот адрес электронной почты не принадлежит ни одной учетной записи';
export const invalidCredentials = 'Некорректный Email или пароль';
export const largeFile = 'Изображение не загружено. Максимальный размер изображения 10 Mb';
export const invalidImage =
  'Изображение имеет неверный формат. Поддерживаются изображения только в форматах JPEG, JPG и PNG';
export const passwordTooShort = 'Пароль должен содержать не менее 8 символов';
export const passwordEntirelyNumeric = 'Пароль должен содержать хотя бы одну цифру';
export const passwordEntirelyAlpha = 'Пароль должен содержать хотя бы одну букву';
export const passwordTooCommon = 'Пароль слишком простой';
export const passwordNotValidChars =
  'Пароль может состоять из символов A-z, 0-9 и специальных символов';
export const invalidPhone = 'Некорректный номер телефона';
export const invalidPassword = 'Неверный пароль';
export const emailIsUsed = 'Этот email адрес был ранее использован';
export const invalidVideo = 'Видео не может быть загружено. Допустимые форматы: WEBM, OGV и MP4';

// EMR errors
export const drugBlank = 'Пожалуйста, укажите  название препарата';
export const doseBlank = 'Пожалуйста, укажите дозу препарата';
export const illStartBlank = 'Пожалуйста, укажите начало болезни';
export const illEndBlank = 'Пожалуйста, укажите окончание болезни';
export const tagBlank = 'Пожалуйста, укажите номер бирки';
export const quantityBlank = 'Пожалуйста, укажите количество';
