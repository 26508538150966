import React from 'react';
import Media from 'react-media';
import cn from 'classnames/bind';
import { Formik, Form } from 'formik';

import Container from '../../components/Container/Container';
import EMRHeader from '../../components/EMRHeader/EMRHeader';
import HeadFilters from '../../components/HeadFilters/HeadFilters';
import MenuFilters from '../../components/MenuFilters/MenuFilters';
import EMRSearchTable from '../../components/EMRSearchTable/EMRSearchTable';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import PageSizeSelect from '../../components/PageSizeSelect/PageSizeSelect';

import useEMR from '../../../../hooks/api/use-emr';
import styles from './ElectronicMediaclRecords.module.css';
import { getPageCount } from '../../../../utils/helpers';

const cx = cn.bind(styles);

function ElectronicMediaclRecords(props) {
  const initialValues = {
    id: '',
    goatTag: '',
    goatName: '',
    modified: '',
    ills: '',
    matings: '',
    lambings: '',
    vaccinations: '',
    modifierFrom: '',
    modifierTo: '',
    orderBy: 'id',
    page: '1',
    pageSize: '20',
  };

  const { emr, setEMR } = useEMR();

  function handleSubmit(values, actions) {
    setEMR(values, actions);
  }

  function handlePageClick(data, values, actions) {
    setEMR({ ...values, page: data.selected + 1 }, actions);
  }

  function handleSelectChange({ name, value, setFieldValue }, values, actions) {
    setFieldValue(name, value);
    setEMR({ ...values, pageSize: value }, actions);
  }

  return (
    <Container className={styles.container} wrapperClassName={styles.wrapper}>
      <EMRHeader className={styles.header} />

      {emr?.results ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          render={({ values, ...actions }) => (
            <Form className={styles.form} noValidate>
              <Media query="(max-width: 991px)">
                <MenuFilters>
                  <HeadFilters values={values} actions={actions} setState={setEMR} />
                </MenuFilters>
              </Media>

              <Media query="(min-width: 992px)">
                <div className={cx({ 'filters-wrapper': true })}>
                  <HeadFilters values={values} actions={actions} setState={setEMR} />
                </div>
              </Media>

              <EMRSearchTable
                emr={emr}
                history={props.history}
                values={values}
                actions={actions}
                onFilter={handleSubmit}
              >
                <>
                  <div>
                    {emr?.results.length ? (
                      <CustomPagination
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        pageCount={getPageCount(emr?.count, values.pageSize)}
                        onPageChange={(data) => handlePageClick(data, values, actions)}
                      />
                    ) : null}
                  </div>

                  <PageSizeSelect
                    name="pageSize"
                    values={values}
                    onChange={(e) => handleSelectChange(e, values, actions)}
                    {...actions}
                  />
                </>
              </EMRSearchTable>
            </Form>
          )}
        />
      ) : (
        <p className={cx({ 'empty-results': true })}>Реестр ЭМК пуст</p>
      )}
    </Container>
  );
}

export default ElectronicMediaclRecords;
