import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Sprite from '../Sprite';
import ButtonClose from '../ButtonClose/ButtonClose';

import styles from './Toast.module.css';

function ToastContent(props) {
  return (
    <>
      <div className={styles.info}>
        <Sprite
          name="info"
          className={styles.icon}
          role="img"
          aria-labelledby="info-notify"
          title="Информация"
        />
      </div>

      <p className={styles.text}>{props.text}</p>
    </>
  );
}

export function notify(text) {
  return toast(<ToastContent text={text} />);
}

function Toast() {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={4000}
      hideProgressBar
      newestOnTop
      pauseOnVisibilityChange
      className={styles.container}
      toastClassName={styles.toast}
      bodyClassName={styles.body}
      closeButton={<ButtonClose stroke="black" className={styles.btn} />}
    />
  );
}

export default Toast;
