import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';

import ProfileBlockWrapper from '../../containers/ProfileBlockWrapper/ProfileBlockWrapper';
import ProfileInfoForm from '../ProfileInfoForm/ProfileInfoForm';
import ActionModal from '../../components/ActionModal/ActionModal';
import { notify } from '../../../../components/Toast/Toast';

import useScrollLock from '../../../../hooks/use-scroll-lock';
import { users } from '../../../../utils/api';
import { phoneToStr } from '../../../../utils/phone-parser';
import { ProfileInfoSchema } from '../../../../utils/validations';
import { asyncErrors } from '../../../../utils/async-errors';

function ProfileInfoBlock() {
  const initialValues = {
    fullName: '',
    phone: '',
  };
  const { targetRef, targetElement, enableBodyScroll } = useScrollLock();

  function checkPhoneLength({ values }) {
    return phoneToStr(values.phone).length < 10;
  }

  function handleSubmit({ fullName, phone }, actions) {
    users.profile.info
      .change({ fullName, phone })
      .then(() => notify('Данные успешно изменены'))
      .catch(({ response }) => {
        const data = response.data;

        actions.setErrors({
          fullName: asyncErrors(data.fullName),
          phone: asyncErrors(data.phone),
        });
      })
      .finally(() => actions.setSubmitting(false));
  }

  function handleSaveClick(actions, onCancel) {
    onCancel();
    enableBodyScroll(targetElement);
    actions.submitForm();
  }

  return (
    <>
      <ProfileBlockWrapper
        title="Персональные данные"
        initialValues={initialValues}
        validationSchema={ProfileInfoSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        render={(formikProps) => (
          <>
            <ProfileInfoForm {...formikProps} />

            <NavigationPrompt when={() => checkPhoneLength(formikProps)}>
              {({ onConfirm, onCancel }) => (
                <ActionModal
                  isOpen
                  onClose={() => {
                    onConfirm();
                    enableBodyScroll(targetElement);
                  }}
                  onAccept={() => handleSaveClick(formikProps, onCancel)}
                  title="Вы покидаете страницу"
                  btnAcceptText="Сохранить"
                  btnDeclineText="Не сохранять"
                  ref={targetRef}
                  target={targetElement}
                >
                  Сохранить внесенные изменения?
                </ActionModal>
              )}
            </NavigationPrompt>
          </>
        )}
      />
    </>
  );
}

export default ProfileInfoBlock;
