import React from 'react';
import cn from 'classnames/bind';

import SearchTable from '../../components/SearchTable/SearchTable';
import SearchTableHead from '../../components/SearchTableHead/SearchTableHead';
import SearchTableHeadCell from '../../components/SearchTableHeadCell/SearchTableHeadCell';
import DatepickerTableHeadCell from '../../components/DatepickerTableHeadCell/DatepickerTableHeadCell';
import SearchTableBody from '../../components/SearchTableBody/SearchTableBody';
import SearchTableBodyCell from '../../components/SearchTableBodyCell/SearchTableBodyCell';
import Sprite from '../../../../components/Sprite';

import styles from './LambSearchTable2.module.css';
import { convertDate } from '../../../../utils/helpers';
import DatepickerField from '../DatepickerField/DatepickerField';

const cx = cn.bind(styles);

function LambSearchTable2(props){
    function handleRowClick(id) {
        props.history.push(`/goat/${id}/`);
      }
    
      function handleOrderClick(name, values, actions) {
        if (values.orderBy === name) {
          actions.setFieldValue('orderBy', `-${name}`);
          props.onFilter({ ...values, orderBy: `-${name}` }, actions);
        } else {
          actions.setFieldValue('orderBy', name);
          props.onFilter({ ...values, orderBy: name }, actions);
        }
      }
    
      return (
        <section className={styles.wrapper}>
          <SearchTable>
            <SearchTableHead rowClassName={cx({ 'table-row': true })}>
             
              <SearchTableHeadCell
                className={cx({ 'table-cell': true })}
                name="chip"
                icon="magnifier"
                actions={props.actions}
              >
                Номер чипа
              </SearchTableHeadCell>
    
              <SearchTableHeadCell
               className={cx({'table-cell': true})}
               name="house"
               icon="magnifier"
               actions={props.actions}
              >
                Номер дома
              </SearchTableHeadCell>
              
              <SearchTableHeadCell
               className={cx({'table-cell': true})}
               name="name"
               icon="magnifier"
               actions={props.actions}
              >
                Коза (кличка)
              </SearchTableHeadCell>

              <SearchTableHeadCell
               className={cx({'table-cell': true})}
               name="father"
               icon="magnifier"
               actions={props.actions}
              >
                Производитель (кличка)
              </SearchTableHeadCell>

              <DatepickerTableHeadCell
               className={cx({'table-cell': true})}
               name="date_mating"
               icon="calendar"
               actions={props.actions}
               value={props.values.date_mating}
              >
                Дата случки
              </DatepickerTableHeadCell>
              
              <DatepickerTableHeadCell
               className={cx({'table-cell': true})}
               name="start"
               icon="calendar"
               actions={props.actions}
               popperPlacement="bottom-end"
               value={props.values.start}
              >
                Запуск
              </DatepickerTableHeadCell>

              <DatepickerTableHeadCell
               className={cx({'table-cell': true})}
               name="projected_lamb"
               icon="calendar"
               actions={props.actions}
               popperPlacement="bottom-end"
               value={props.values.projected_lamb}
              >
                Прогноз окота
              </DatepickerTableHeadCell>

              <SearchTableHeadCell
               className={cx({ 'table-cell': true })}
               name="days_for_lamb"
               icon="magnifier"
               actions={props.actions}
              >
                Осталось до окота
              </SearchTableHeadCell>

              <DatepickerTableHeadCell
               className={cx({ 'table-cell': true })}
               name="actual_lamb"
               icon="calendar"
               actions={props.actions}
               popperPlacement="bottom-end"
               value={props.values.actual_lamb}
              >
                Фактический окот
              </DatepickerTableHeadCell>

              <th className={cx({'table-cell': true})}>Всего</th>
              
              <SearchTableHeadCell
               className={cx({'table-cell': true})}
               name="dead_children"
               icon="magnifier"
               actions={props.actions}
              >
                Мертв
              </SearchTableHeadCell>

              <SearchTableHeadCell
               className={cx({ 'table-cell': true })}
               name="goat_male"
               icon="magnifier"
               actions={props.actions}
              >
                Козлик
              </SearchTableHeadCell>

              <SearchTableHeadCell
               className={cx({ 'table-cell': true })}
               name="goat_female"
               icon="magnifier"
               actions={props.actions}
              >
                Козочка
              </SearchTableHeadCell>

            </SearchTableHead>
    
            <SearchTableBody results={props.goats.results.length}>
              {props.goats.results.map(({
                id,
                chip,
                house,
                name,
                father,
                dateMating,
                start,
                projectedLamb,
                daysForLamb,
                actualLamb,
                totalChildren,
                deadChildren,
                goatMale,
                goatFemale,
               
              }) => {
                return (
                  <tr
                    key={id}
                    onClick={() => handleRowClick(id)}
                    className={cx({ 'table-row': true, 'table-body-row': true })}
                  >
    
                    <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                      {chip}
                    </SearchTableBodyCell>
                    <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                      {house}
                    </SearchTableBodyCell>
    
                    <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                      {name}
                    </SearchTableBodyCell>
                    <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                      {father}
                    </SearchTableBodyCell>
  
                    <SearchTableBodyCell canClick={true} className={cx({ 'table-cell': true })}>
                      <DatepickerField
                       name="dateMating"
                       onChange={(value) => props.handleDateChange(value, id, 'dateMating')}
                       className={styles.datepicker}
                       value={dateMating}
                       icon="calendar"
                       popperPlacement="bottom-end"
                      />
                    </SearchTableBodyCell>

                    <SearchTableBodyCell canClick={true} className={cx({ 'table-cell': true })}>
                      <DatepickerField
                       name="start"
                       onChange={(value) => props.handleDateChange(value, id, 'start')}
                       className={styles.datepicker}
                       value={start}
                       icon="calendar"
                       popperPlacement="bottom-end"
                      />
                    </SearchTableBodyCell>

                    <SearchTableBodyCell canClick={true} className={cx({ 'table-cell': true })}>
                      <DatepickerField
                       name="projectedLamb"
                       onChange={(value) => props.handleDateChange(value, id, 'projectedLamb')}
                       className={styles.datepicker}
                       value={projectedLamb}
                       icon="calendar"
                       popperPlacement="bottom-end"
                      />
                    </SearchTableBodyCell>

                    <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                      {daysForLamb}
                    </SearchTableBodyCell>

                    <SearchTableBodyCell canClick={true} className={cx({ 'table-cell': true })}>
                      <DatepickerField
                       name="actualLamb"
                       onChange={(value) => props.handleDateChange(value, id, 'actualLamb')}
                       className={styles.datepicker}
                       value={actualLamb}
                       icon="calendar"
                       popperPlacement="bottom-end"
                      />
                    </SearchTableBodyCell>
                    
                    <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                      {totalChildren}
                    </SearchTableBodyCell>
                    <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                      {deadChildren}
                    </SearchTableBodyCell>
                    <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                      {goatMale}
                    </SearchTableBodyCell>
                    <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                      {goatFemale}
                    </SearchTableBodyCell>
                  </tr>
                );
              })}
            </SearchTableBody>
          </SearchTable>
    
          <div className={styles.footer}>{props.children}</div>
        </section>
      );
};
export default LambSearchTable2;