import { camelizeKeys, decamelizeKeys } from 'humps';

import axios from '../config/axios';

const transformers = {
  transformRequest: [(data) => decamelizeKeys(data), ...axios.defaults.transformRequest],
  transformResponse: [...axios.defaults.transformResponse, (data) => camelizeKeys(data)],
};

export default async (...args) => axios(...args, { ...transformers });
