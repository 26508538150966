import React from 'react';
import cn from 'classnames';

import FieldDatePicker from '../../../../components/FieldDatePicker/FieldDatePicker';

import styles from './DatepickerTableHeadCell.module.css';

function DatepickerTableHeadCell(props) {
  return (
    <th className={cn(styles.cell, props.className)}>
      {props.children}

      <FieldDatePicker
        name={props.name}
        onChange={props.actions.setFieldValue}
        value={props.value}
        className={styles.field}
        iconClassName={styles.icon}
        icon={props.icon}
        popperPlacement={props.popperPlacement}
        actions={props.actions}
      />
    </th>
  );
}

export default DatepickerTableHeadCell;
