import React from 'react';
import cn from 'classnames/bind';

import MultimediaStub from '../../components/MultimediaStub/MultimediaStub';
import ActionButton from '../../components/ActionButton/ActionButton';

import styles from './ResearchVideo.module.css';
import api, { files } from '../../../../utils/api';
import * as errorMessages from '../../../../utils/error-messages';

const cx = cn.bind(styles);

function ResearchVideo(props) {
  const [error, setError] = React.useState('');
  const [fileUrl, setFileUrl] = React.useState('');

  function handleImageChange(e) {
    e.preventDefault();

    const formData = new FormData();
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);

    if (!file?.name.match(/.(webm|ogv|mp4|m4v|mov|mjped)$/i)) {
      setError(errorMessages.invalidVideo);
      return;
    }

    setError('');

    formData.append('source', file);

    if (props.isCreate) {
      // setFileUrl(url);
      // props.actions.setFieldValue('video', url);

      files.video(formData).then(({ data }) => {
        setFileUrl(url);
        props.actions.setFieldValue('videoId', data.id);
        props.actions.setFieldValue('video', data.source);
      });
    }

    if (!props.isCreate) {
      // setFileUrl(url);
      // props.actions.setFieldValue('video', url);

      files.video(formData).then(({ data }) => {
        setFileUrl(url);
        api.patch(`/research/${props.uuid}/`, { videoId: data.id }).then(({ data }) => {
          props.actions.setFieldValue('video', data.video);
        });
      });
    }
  }

  function handleDeleteImageClick() {
    if (props.isCreate) {
      props.actions.setFieldValue('video', null);
      props.actions.setFieldValue('videoID', null);
    }

    if (!props.isCreate) {
      api
        .patch(`/research/${props.values.id}/`, { videoId: null })
        .then(() => props.actions.setFieldValue('video', null));
    }
  }

  return (
    <div className={cx({ wrapper: true })}>
      <div className={cx({ container: true, 'container-empty': !props.values.video })}>
        {props.values.video ? (
          //eslint-disable-next-line
          <video controls className={cx({ video: true })}>
            {props.values.video?.formats?.[0] ? (
              props.values.video?.formats?.map(({ file, format, progress }, i) => {
                if (progress === 100) return <source key={i} src={file} type={`video/${format}`} />;
                return null;
              })
            ) : (
              <source src={fileUrl ? fileUrl : props.values.video.source} />
            )}
          </video>
        ) : (
          <MultimediaStub
            iconName="video"
            id="video-upload"
            onChange={handleImageChange}
            isEdit={props.isEdit}
            title={props.isEdit ? 'Загрузить видео' : 'Видео отсутствует'}
            iconTitile="Загрузить видео"
          />
        )}

        {props.values.video && props.isEdit && (
          <ActionButton
            className={cx({ 'btn-remove': true })}
            icon="trash"
            role="button"
            type="button"
            onClick={handleDeleteImageClick}
          >
            Удалить
          </ActionButton>
        )}
      </div>

      {error && <p className={cx({ error: true })}>{error}</p>}
    </div>
  );
}

export default ResearchVideo;
