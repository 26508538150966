import React from 'react';

import BlockWrapper from '../../containers/BlockWrapper/BlockWrapper';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import ChangeAvatarButton from '../ChangeAvatarButton/ChangeAvatarButton';

import styles from './ProfileAvatarBlock.module.css';
import * as errorMessages from '../../../../utils/error-messages';
import { files, users } from '../../../../utils/api';
import { consoleLog } from '../../../../utils/helpers';

function ProfileAvatarBlock() {
  const MAX_IMAGE_SIZE = 1e7;

  const fileInput = React.createRef();
  const [imagesList, setImagesList] = React.useState({});
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    users.profile.info.get().then(({ data }) => {
      if (data.avatar) {
        setImagesList({
          small: data.avatar.small,
          medium: data.avatar.medium,
          large: data.avatar.large,
        });
      }
    });
  }, []);

  function handleFileChange() {
    const formData = new FormData();
    const file = fileInput.current.files[0];

    if (!file?.name.match(/.(jpe?g|png)$/i)) {
      setError(errorMessages.invalidImage);

      return;
    } else {
      setError('');
    }

    if (file?.size >= MAX_IMAGE_SIZE) {
      setError(errorMessages.largeFile);

      return;
    } else {
      setError('');
    }

    formData.append('source', file);

    files
      .images(formData)
      .then(({ data }) => {
        setImagesList({
          small: data.small,
          medium: data.medium,
          large: data.large,
        });

        users.profile.info.change({ avatarId: data.id });
      })
      .catch((err) => consoleLog(err));
  }

  return (
    <BlockWrapper className={styles.wrapper}>
      <h2 className={styles.title}>Фотография</h2>

      <form className={styles.form} noValidate onChange={handleFileChange}>
        <ProfileAvatar
          title="Изображение пользователя"
          className={styles.avatar}
          imagesList={imagesList}
        />

        <ChangeAvatarButton fileInput={fileInput} />
      </form>

      {error && <p className={styles.error}>{error}</p>}
    </BlockWrapper>
  );
}

export default ProfileAvatarBlock;
