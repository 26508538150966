import axios from 'axios';

import store from '../store';
import { alertInfo, consoleInfo } from '../utils/helpers';
import { clearCookies } from '../utils/cookie';

let token = store.getState().logIn.token;

store.subscribe(() => {
  token = store.getState().logIn.token;
});

const instance = axios.create({
  baseURL: '/api/',
});

instance.interceptors.request.use((config) => {
  if (token !== null) {
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    consoleInfo('response', response);

    return response;
  },

  (error) => {
    const { response } = error;

    consoleInfo('error', response);
    alertInfo('error', response);

    if (response.status === 401) {
      clearCookies();
    }

    return Promise.reject(error);
  },
);

export default instance;
