import * as errorMessages from './error-messages';

export const camelize = (str) => str.replace(/([-_]\w)/g, (substr) => substr[1].toUpperCase());

export const getErrorCode = (errors) => {
  if (errors) return camelize(errors[0].code);
  else return '';
};
export const getAsyncErrorMessage = (errors) => {
 
  let message = '';
  if (errors) {
    errors[1].data.lines.forEach((obj) => {
      if (obj.hasOwnProperty("name")) {
       message = obj.name[0].detail;
      }
    })
  }
  return message;
}

const getErrorByCode = (code) => {
  switch (code) {
    case 'passwordNotValidChars':
      return errorMessages.passwordNotValidChars;
    case 'invalidCredentials':
      return errorMessages.invalidCredentials;
    case 'emailNotFound':
      return errorMessages.emailNotFound;
    case 'passwordTooCommon':
      return errorMessages.passwordTooCommon;
    case 'invalidPhone':
      return errorMessages.invalidPhone;
    case 'invalidPassword':
      return errorMessages.invalidPassword;
    default:
      return code;
  }
};

export const asyncErrors = (errors) => {
  const code = getErrorCode(errors);
  const error = getErrorByCode(code);

  return error;
};

export const asyncErrorsFromCode = (code) => {
  const error = getErrorByCode(code);

  return error;
};
