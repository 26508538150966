import React from 'react';
import useSWR from '@zeit/swr';
import cn from 'classnames/bind';

import ActionButton from '../ActionButton/ActionButton';

import styles from './ResearchesHeader.module.css';
import axios from '../../../../utils/axios';
import { isAdminOrModer } from '../../../../utils/user-role';

const cx = cn.bind(styles);

function ResearchesHeader(props) {
  const { data: user } = useSWR('/users/me/', axios);

  function handleReloadClick() {
    document.location.reload(false);
  }

  return (
    <div className={cx({ header: true }, props.className)}>
      <h1 className={cx({ title: true })}>Лабораторные исследования</h1>

      <div className={cx({ buttons: true })}>
        {isAdminOrModer(user?.data?.role) && (
          <ActionButton
            className={cx({ button: true, 'button-add': true })}
            role="link"
            icon="plus"
            fill="var(--white)"
            to="/laboratory-research/create"
          >
            Добавить исследование
          </ActionButton>
        )}

        {!!props.researches?.results && (
          <ActionButton
            className={cx({ button: true, 'button-reload': true })}
            role="button"
            icon="reload"
            onClick={handleReloadClick}
          >
            Обновить
          </ActionButton>
        )}
      </div>
    </div>
  );
}

export default ResearchesHeader;
