import React from 'react';
import Scrollbar from 'react-scrollbars-custom';
import cn from 'classnames/bind';
import { useSize } from 'react-hook-size';

import styles from './SearchTable.module.css';

const cx = cn.bind(styles);

function SearchTable(props) {
  const ref = React.useRef(null);
  let { height } = useSize(ref);

  return (
    <Scrollbar noScrollY style={{ height: height + 1 }}>
      <div className={cx({ 'table-wrapper': true }, props.className)}>
        <table ref={ref} className={styles.table}>
          {props.children}
        </table>
      </div>
    </Scrollbar>
  );
}

export default SearchTable;
