import React, { memo } from "react"
import GalleryField from "../GalleryField/GalleryField"

const DetailedGalleryField = memo((props) => {
    const {
        id,
        setFieldValue,
        isEditable,
        detailedPhotos
    } = props
    return (
        <GalleryField
            id={id}
            setFieldValue={setFieldValue}
            isEditable={isEditable}
            galleryFieldValue={detailedPhotos}
            galleryPhotosIdField={'detailedPhotosId'}
            galleryFieldName={'detailedPhotos'}
            fieldName={'Детальные фото'}
        />
    )
})
export default DetailedGalleryField