import React from 'react';
import cn from 'classnames/bind';

import NavList from '../NavList/NavList';
import ButtonClose from '../../../../components/ButtonClose/ButtonClose';

import styles from './Sidebar.module.css';

const cx = cn.bind(styles);

const Sidebar = React.forwardRef((props, ref) => {
  return (
    <div className={cx({ overlay: true, 'overlay-open': props.isOpen })}>
      <aside ref={ref} className={cx({ sidebar: true })}>
        <ButtonClose onClick={props.onClose} className={cx({ 'button-close': true })} />

        <NavList onLinkClick={props.onClose} className={cx({ navigation: true })} />
      </aside>
    </div>
  );
});

export default Sidebar;
