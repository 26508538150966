import React from 'react';
import cn from 'classnames/bind';

import ResearchImage from '../../components/ResearchImage/ResearchImage';
import ResearchVideo from '../../components/ResearchVideo/ResearchVideo';

import styles from './ResearchMultimedia.module.css';

const cx = cn.bind(styles);

function ResearchMultimedia(props) {
  return (
    <div className={cx({ container: true })}>
      <section className={cx({ wrapper: true })}>
        <ResearchImage
          isEdit={props.isEdit}
          isCreate={props.isCreate}
          values={props.values}
          errors={props.errors}
          actions={props.actions}
          uuid={props.uuid}
        />

        <ResearchVideo
          isEdit={props.isEdit}
          isCreate={props.isCreate}
          values={props.values}
          errors={props.errors}
          actions={props.actions}
          uuid={props.uuid}
        />
      </section>
    </div>
  );
}

export default ResearchMultimedia;
