import React, {useState} from "react";
import cn from 'classnames/bind';
import { Formik, Form } from "formik";
import Container from "../../components/Container/Container";
import LambHeader from "../../components/LambHeader/LambHeader";
import styles from './Lamb2.module.css';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import PageSizeSelect from '../../components/PageSizeSelect/PageSizeSelect';

import useLamb from "../../../../hooks/api/use-lamb";
import { getPageCount } from '../../../../utils/helpers';
import LambSearchTable2 from "../../components/LambSearchTable2/LambSearchTable2";
import { convertDateToYMD } from "../../../../utils/dates";
import LambTableTotal from "../../components/LambTableTotal/LambTableTotal";


const cx = cn.bind(styles);
function Lamb2(props){

  const initialValues = {
    id: '',
    father: '',
    dateMating: '',
    start: '',
    projectedLamb: '',
    daysForLamb: '',
    actualLamb: '',
    totalChildren: '',
    deadChildren: '',
    goatMale: '',
    goatFemale: '',
    columnColor: '',
    note: '',
    house: '',
    ills: '',
    vaccinations: '',
    lambings: '',
    matings: '',
    condFrom: '',
    condTo: '',
    tag: '',
    chip: '',
    name: '',
    emrId: '',
    birthDate: '',
    modified: '',
    orderBy: '',
    page: '1',
    pageSize: '20',
  };
  const {goats, goatsTotal, setGoats} = useLamb();
  const [noteValue, setNoteValue] = useState(null);

  function handleSubmit(values, actions) {
    setGoats(values, actions);
  }

  function handlePageClick(data, values, actions) {
    setGoats({ ...values, page: data.selected + 1 }, actions);
  }

  function handleSelectChange({ name, value, setFieldValue }, values, actions) {
    setFieldValue(name, value);
    setGoats({ ...values, pageSize: value }, actions);
  }
  function handleDateChange(value, id, field){
    const formatDate = convertDateToYMD(value);
    setNoteValue({
      id,
      [field]: formatDate
    })
  }

  return (
    <Container className={styles.container} wrapperClassName={styles.wrapper}>
        <LambHeader className={styles.header} />
        {goats?.results ? (
          <div className={cn(styles['tables-wrapper'])}>

            <Formik 
              initialValues={initialValues}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
              render={({values, ...actions}) => (
                <Form className={styles.form} noValidate>
                  <LambSearchTable2
                    goats={goats}
                    history={props.history}
                    values={values}
                    actions={actions}
                    onFilter={handleSubmit}
                    handleDateChange={handleDateChange}
            
                  >
                    <>
                      <div>
                        {goats.results.length ? (
                          <CustomPagination
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            pageCount={getPageCount(goats.count, values.pageSize)}
                            onPageChange={(data) => handlePageClick(data, values, actions)}
                          />
                        ) : null}
                      </div>
    
                      <PageSizeSelect
                        name="pageSize"
                        values={values}
                        onChange={(e) => handleSelectChange(e, values, actions)}
                        {...actions}
                      />
                    </>
                  </LambSearchTable2>
                </Form>
              )}
            />
            <LambTableTotal goatsTotal={goatsTotal} />
          </div>
        ) : (<p className={cx({ 'empty-results': true })}>Список коз пуст</p>)}
    </Container>
  )
};
export default Lamb2;
