import React from 'react';
import useSWR from '@zeit/swr';
import cn from 'classnames/bind';

import ActionButton from '../ActionButton/ActionButton';

import styles from './EMRHeader.module.css';
import axios from '../../../../utils/axios';
import { isAdminOrModer } from '../../../../utils/user-role';

const cx = cn.bind(styles);

function EMRHeader(props) {
  const { data: user } = useSWR('/users/me/', axios);
  const { data: emr } = useSWR('/emr/', axios);

  function handleReloadClick() {
    document.location.reload(false);
  }

  return (
    <div className={cn(styles.header, props.className)}>
      <h1 className={styles.title}>Реестр ЭМК</h1>

      <div className={styles.buttons}>
        {isAdminOrModer(user?.data?.role) && (
          <ActionButton
            className={cx({ button: true, 'button-add': true })}
            role="link"
            icon="plus"
            fill="var(--white)"
            to="/electronic-medical-record/create"
          >
            Добавить ЭМК
          </ActionButton>
        )}

        {!!emr?.data?.results.length && (
          <ActionButton
            className={cx({ button: true, 'button-reload': true })}
            role="button"
            icon="reload"
            onClick={handleReloadClick}
          >
            Обновить
          </ActionButton>
        )}
      </div>
    </div>
  );
}

export default EMRHeader;
