import React from 'react';

import BaseModal from '../../../../components/BaseModal/BaseModal';
import ButtonClose from '../../../../components/ButtonClose/ButtonClose';

import styles from './ResetPasswordModal.module.css';

const ResetPasswordModal = React.forwardRef((props, ref) => {
  return (
    <BaseModal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      shouldCloseOnOverlayClick={true}
      className={styles.modal}
      ref={ref}
      target={props.target}
    >
      <ButtonClose onClick={props.onClose} className={styles.btn} stroke="var(--dark-gray)" />

      <p className={styles.text}>
        Инструкции для восстановления пароля отправлены на указанный email адрес
      </p>
    </BaseModal>
  );
});

export default ResetPasswordModal;
