import React from 'react';
import cn from 'classnames/bind';
import useScrollLock from '../../../../hooks/use-scroll-lock';
import useSWR from '@zeit/swr';
import { Link } from 'react-router-dom';
import { isAdminOrModer } from '../../../../utils/user-role';
import axios from '../../../../utils/axios';

import EMRBindingModal from '../EMRBindingModal/EMRBindingModal';
import GoatBindingModal from '../GoatBindingModal/GoatBindingModal';
import ActionModal from '../ActionModal/ActionModal';

import styles from './BindingBlock.module.css';

const cx = cn.bind(styles);

function BindingBlock(props) {
  const [isBindingModalOpen, setIsBindingModalOpen] = React.useState(false);
  const [isUnbindModalOpen, setIsUnbindModalOpen] = React.useState(false);
  const { targetRef, targetElement, enableBodyScroll, clearAllBodyScrollLocks } = useScrollLock();
  const value = props.values[props.name];
  const { data: user } = useSWR('/users/me/', axios);
  const isPersonal = isAdminOrModer(user?.data?.role);

  function handleBindModalClose() {
    setIsBindingModalOpen(false);
    clearAllBodyScrollLocks();
  }

  function handleUnbindModalClose() {
    setIsUnbindModalOpen(false);
    enableBodyScroll(targetElement);
  }

  return (
    <>
      <div className={cx({ wrapper: true }, props.className)}>
        <p className={cx({ text: true })}>
          <span className={cx({ title: true })}>
            {props.role === 'goat' ? (value ? 'ЭМК №' : 'ЭМК') : ''}
            {props.role === 'emr' ? (value?.name ? '' : 'Карточка') : ''}
          </span>

          {props.role === 'goat' && value && ' '}

          {value && (
            <span className={cx({ number: true })}>
              <Link
                to={`../../${props.url}/${props.role === 'emr' ? value?.id : value}`}
                className={cx({ link: true })}
              >
                {props.role === 'emr' ? value?.name : value}
              </Link>
            </span>
          )}
        </p>

        {isPersonal && (
          <div className={cx({ buttons: true })}>
            {!props.isBinded ? (
              <>
                <BindingButton
                  role="link"
                  to={`../../${props.url}/create`}
                  state={props.values.id} // link emr to special goat
                  text="Завести"
                  onClick={props.onMake}
                  className={cx({ 'button-make': true }, props.buttonClassName)}
                />
                <BindingButton
                  text="Привязать"
                  onClick={() => setIsBindingModalOpen(true)}
                  className={cx({ 'button-bind': true }, props.buttonClassName)}
                />
              </>
            ) : (
              <BindingButton
                text="Отвязать"
                onClick={() => setIsUnbindModalOpen(true)}
                className={cx({ 'button-unbind': true }, props.buttonClassName)}
              />
            )}
          </div>
        )}
      </div>

      {props.role === 'goat' && (
        <EMRBindingModal
          isOpen={isBindingModalOpen}
          onClose={() => setIsBindingModalOpen(false)}
          role={props.role}
          title={props.modalTitle}
          onBindClick={(id) => props.onBindClick(id, handleBindModalClose)}
        />
      )}

      {props.role === 'emr' && (
        <GoatBindingModal
          isOpen={isBindingModalOpen}
          onClose={() => setIsBindingModalOpen(false)}
          role={props.role}
          title={props.modalTitle}
          onBindClick={(id) => props.onBindClick(id, handleBindModalClose)}
        />
      )}

      <ActionModal
        isOpen={isUnbindModalOpen}
        onClose={handleUnbindModalClose}
        title={`Вы действительно хотите отвязать ${props.role === 'goat' ? 'ЭМК' : 'карточку'}?`}
        btnAcceptText="Отвязать"
        btnDeclineText="Отмена"
        btnAcceptDanger
        onAccept={() => props.onUnbindClick(handleUnbindModalClose)}
        ref={targetRef}
        target={targetElement}
      />
    </>
  );
}

function BindingButton(props) {
  if (props.role === 'link') {
    return (
      <Link to={{pathname: props.to, state:{goatId:props.state}}} className={cx({ button: true }, props.className)}>
        {props.text}
      </Link>
    );
  }

  return (
    <button type="button" onClick={props.onClick} className={cx({ button: true }, props.className)}>
      {props.text}
    </button>
  );
}

export default BindingBlock;
