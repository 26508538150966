import api from '../utils/api';
import { convertFormDates } from '../utils/dates';

export default {
  get(uuid) {
    return api.get(`/goats/${uuid}/`);
  },

  create(values) {
    return api.post('/goats/', convertFormDates(values, ['birthDate','deathDate','dateMating','borkedDate','date']));
  },

  edit(uuid, values) {
    return api.patch(`/goats/${uuid}/`, convertFormDates(values, ['birthDate','deathDate','dateMating','borkedDate','date']));

  },

  remove(uuid) {
    return api.delete(`/goats/${uuid}/`);
  },
};
