import React from 'react';
import { FieldArray, Field } from 'formik';
import cn from 'classnames/bind';

import BlockWrapper from '../../containers/BlockWrapper/BlockWrapper';
import DatesSelect from '../DatesSelect/DatesSelect';

import styles from './CardMilkAmounts.module.css';
import * as regExp from '../../../../utils/regular-expressions';

const cx = cn.bind(styles);

function createSelectDateOptions(from, to) {
  let dates = [];

  for (let i = from; i <= to; i++) {
    dates.push({ value: i, label: i });
  }

  return dates.sort((a, b) => b.value - a.value);
}

const checkYearExists = (arr, year) => arr.find((item) => !!(item.year === year));

function CardMilkAmounts(props) {
  const date = new Date();
  const currentYear = date.getUTCFullYear();
  const selectOptions = createSelectDateOptions(2004, currentYear);
  const { values } = props;

  const [selectedYear, setSelectedYear] = React.useState(currentYear);

  function handleChange(value) {
    setSelectedYear(value);
  }

  function handleAddTableClick(arrayHelpers) {
    arrayHelpers.push({ year: selectedYear, winter: '', spring: '', summer: '', autumn: '' });
  }

  return (
    <BlockWrapper className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Кол-во удоя</h2>

        <DatesSelect
          className={styles.select}
          defaultValue={[{ value: currentYear, label: currentYear }]}
          options={selectOptions}
          label="Даты удоя"
          onChange={({ value }) => handleChange(value)}
        />
      </div>

      <FieldArray
        name="milkAmounts"
        render={(arrayHelpers) => (
          <>
            {checkYearExists(values.milkAmounts, selectedYear) && (
              <Table>
                {values.milkAmounts.map(({ year }, index) => (
                  <Rows
                    key={index}
                    index={index}
                    year={year}
                    selectedYear={selectedYear}
                    isEditable={props.isEditable}
                    placeholder="Пусто"
                    setFieldValue={props.setFieldValue}
                    disabled={!props.isEditable}
                    values={values}
                  />
                ))}
              </Table>
            )}

            {props.isEditable && !checkYearExists(values.milkAmounts, selectedYear) && (
              <div className={cx({ 'btn-container': true })}>
                <button
                  type="button"
                  className={cx({ 'add-btn': true })}
                  onClick={() => handleAddTableClick(arrayHelpers)}
                >
                  Добавить
                </button>
              </div>
            )}

            {!props.isEditable && !checkYearExists(values.milkAmounts, selectedYear) && (
              <Table>
                <Rows placeholder="Пусто" isEditable={props.isEditable} disabled />
              </Table>
            )}
          </>
        )}
      />
    </BlockWrapper>
  );
}

function Table(props) {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={cx({ cell: true, 'head-cell': true })}>Сезон</th>
          <th className={cx({ cell: true, 'head-cell': true })}>Литры</th>
        </tr>
      </thead>

      <tbody>{props.children}</tbody>
    </table>
  );
}

function Rows(props) {
  const content = (key) => props.values?.milkAmounts?.[props.index]?.[key];
  const disabled = props.disabled;
  const seasons = [
    { title: 'Зима', key: 'winter' },
    { title: 'Весна', key: 'spring' },
    { title: 'Лето', key: 'summer' },
    { title: 'Осень', key: 'autumn' },
  ];

  const rows = seasons.map(({ title, key }) => (
    <tr
      key={key}
      className={cx({ 'content-row': true, 'visible-row': props.year === props.selectedYear })}
    >
      <td className={styles.cell}>{title}</td>
      <td className={cx({ 'editable-cell': true })}>
        <Field
          name={`milkAmounts[${props.index}].${key}`}
          placeholder={props.placeholder}
          type="text"
          className={cx({
            field: true,
            'field-editable': !disabled,
            'field-disabled': disabled,
            'field-with-content': !!content(key),
          })}
          disabled={disabled}
          onChange={(e) => {
            const value = e.target.value;

            if (regExp.weightNum.test(value)) {
              props.setFieldValue(`milkAmounts[${props.index}].${key}`, value);
            }
          }}
        />
      </td>
    </tr>
  ));

  return rows;
}

export default CardMilkAmounts;
