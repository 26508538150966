import React from 'react';
import styles from './AvatarLoader.module.css'
import cn from 'classnames/bind';
const cx = cn.bind(styles);
export function AvatarLoader(props) {
   const { className } = props;

   return (
      <div className={cn(styles.AvatarLoader, className)}>
         <div className={cn(styles.loader)}>
            <div className={cn(styles['lds-ring'])}>
               <div />
               <div />
               <div />
               <div />
            </div>
         </div>
      </div>
   );
}