import React from 'react';
import cn from 'classnames/bind';
import { Field } from 'formik';

import Sprite from '../../../../components/Sprite';

import styles from './FilterField.module.css';

const cx = cn.bind(styles);

function FilterField(props) {
  return (
    <div className={cn(styles.wrapper, props.className)}>
      <div className={cx({ 'icon-wrapper': true })}>
        <Sprite className={cn(styles.icon, props.iconClassName)} name={props.icon} role="img" />
      </div>

      <Field
        type="search"
        name={props.name}
        onChange={(e) => {
          props.actions.handleChange(e);
          setTimeout(props.actions.submitForm, 200);
        }}
        className={styles.field}
      />
    </div>
  );
}

export default FilterField;
