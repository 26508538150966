import React from 'react';
import { Form } from 'formik';

import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';

import styles from './ProfileInfoForm.module.css';
import { users } from '../../../../utils/api';
import { strToPhone } from '../../../../utils/phone-parser';

function ProfileInfoForm({ touched, errors, isSubmitting, setValues }) {
  React.useEffect(() => {
    users.profile.info.get().then(({ data }) => {
      setValues({
        fullName: data.fullName,
        phone: data.phone === '' ? data.phone : strToPhone(data.phone),
      });
    });
  }, [setValues]);

  return (
    <Form className={styles.form} noValidate>
      <CustomFieldSet
        label="Фамилия Имя Отчество"
        name="fullName"
        type="text"
        error={touched.fullName && errors.fullName}
      />

      <CustomFieldSet
        label="Контактный телефон"
        type="tel"
        name="phone"
        error={touched.phone && errors.phone}
      />

      <SubmitButton className={styles.btn} isSubmitting={isSubmitting}>
        Сохранить
      </SubmitButton>
    </Form>
  );
}

export default ProfileInfoForm;
