import { useState, useEffect } from 'react';
import { decamelizeKeys } from 'humps';

import api from '../../utils/api';
import { convertFormDates } from '../../utils/dates';

export default () => {
  const [state, setState] = useState({});

  useEffect(() => {
    api.get('/logs/?page_size=20').then(({ data }) => setState(data));
  }, []);

  return {
    logs: state,

    setLogs: async (values, { setValues }) => {
      const keys = ['created', 'modified'];

      setValues(values);

      values = values |> ((_) => convertFormDates(_, keys)) |> decamelizeKeys;

      return await api
        .get('/logs/', {
          params: convertFormDates(values, keys),
        })
        .then(({ data }) => setState(data));
    },
  };
};
