import React from 'react';
import cn from 'classnames/bind';

import ActionButton from '../ActionButton/ActionButton';

import styles from './UsersHeader.module.css';

const cx = cn.bind(styles);

function UsersHeader(props) {
  return (
    <div className={cn(styles.header, props.className)}>
      <h1 className={styles.title}>Пользователи</h1>

      <ActionButton
        type="button"
        className={cx({ button: true, 'button-add': true })}
        role="button"
        icon="plus"
        fill="var(--white)"
        onClick={props.onClick}
      >
        Создать пользователя
      </ActionButton>
    </div>
  );
}

export default UsersHeader;
