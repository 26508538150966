import React from 'react';
import ReactPaginate from 'react-paginate';
import cn from 'classnames/bind';

import Sprite from '../../../../components/Sprite';

import styles from './CustomPagination.module.css';

const cx = cn.bind(styles);

function CustomPagination(props) {
  return (
    <ReactPaginate
      previousLabel={<ArrowIcon />}
      nextLabel={<ArrowIcon toLeft />}
      breakLabel={<DotsIcon />}
      pageCount={props.pageCount}
      marginPagesDisplayed={props.marginPagesDisplayed}
      pageRangeDisplayed={props.pageRangeDisplayed}
      onPageChange={props.onPageChange}
      containerClassName={cx({ list: true }, props.className)}
    />
  );
}

function ArrowIcon(props) {
  return (
    <Sprite
      name="pagination-arrow"
      role="img"
      className={cx({ arrow: true, 'arrow-left': props.toLeft })}
    />
  );
}

function DotsIcon() {
  return (
    <ul className={styles.dots}>
      <li className={styles.dot} />
      <li className={styles.dot} />
      <li className={styles.dot} />
    </ul>
  );
}

export default CustomPagination;
