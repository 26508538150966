import { useEffect, useState } from "react";
import api from '../../utils/api';

export default () => {
  const [state, setState] = useState({});

  useEffect(() => {
    api.get('goats/get_stats/').then(({ data }) => setState(data));
  }, []);
  return {
    goatsStat: state
  }
};