import { LOG_IN } from '../constants';
import { getCookie } from '../../utils/cookie';

const token = getCookie('token');

const initialState = {
  token,
};

function logIn(state = initialState, action) {
  switch (action.type) {
    case LOG_IN:
      return {
        ...state,
        token: action.token,
      };
    default:
      return state;
  }
}

export default logIn;
