import React, { useEffect, useState, useRef, memo } from 'react';
import cn from 'classnames/bind';
import styles from './GalleryField.module.css';
import GoatContentModal from '../GoatContentModal/GoatContentModal';
import { Field } from 'formik';
import * as errorMessages from '../../../../utils/error-messages';
import api, { files } from '../../../../utils/api';
import { Slider } from '../Slider/Slider';
import Sprite from '../../../../components/Sprite';
import { convertDateToDMY } from '../../../../utils/dates';

const cx = cn.bind(styles);
const FileField = memo((props) => {
    const {
        setError,
        error,
        setFieldValue,
        fieldName,
        galleryPhotosIdField,
        galleryFieldValue,
        galleryFieldName,

    } = props
    const MAX_IMAGE_SIZE = 1e7;
    const [status, setStatus] = useState('init') // init / loading /  success / error
    const [isSaved, setIsSaved] = useState(false)
    const [previewsImages, setPreviewsImages] = useState([
        ...galleryFieldValue,
    ])
    const handleImageChange = (e) => {
        e.preventDefault();

        const formData = new FormData();
        const file = e.target.files[0];
        const currentDate = new Date().toISOString()
        if (!file?.name.match(/.(jpe?g|png)$/i)) {
            setError(errorMessages.invalidImage);
            setStatus('error')

            return;
        }

        if (file?.size >= MAX_IMAGE_SIZE) {
            setError(errorMessages.largeFile);
            setStatus('error')
            return;
        }

        formData.append('source', file);
        formData.append('created', currentDate)
        setError('');
        setStatus('loading')
        if (isSaved) {
            setIsSaved(false)
        }

        files.images(formData).then((res) => {
            if (res.status === 201) {
                setStatus('success')
            }
            setPreviewsImages((prev) => [...prev, res.data])
        });
    }
    const onApproveClick = () => {
        const imagesIds = previewsImages.map(image => image.id)
        setFieldValue(galleryPhotosIdField, imagesIds)
        setIsSaved(true)
    }
    const onClearClick = (id) => {
        setPreviewsImages((prev) => {
            const copyState = [...prev]
            const filteredGallary = copyState.filter((image) => image.id !== id)
            return filteredGallary
        })
        setStatus('success')
        setError('');
    }

    return (
        <tr className={styles.row}>
            <th className={cx({ 'row-title': true, 'row-read': true })}>{fieldName}</th>
            <td className={cx({ cell: true })}>
                <input
                    id="image-upload"
                    type="file"
                    name={galleryFieldName}
                    onChange={handleImageChange}
                    className={cx({ 'file-input': true, 'disabled': status === 'loading' })}
                    disabled={status === 'loading'}
                />

                <button
                    className={cx(
                        {
                            button: true,
                            'button-show': true,
                            'disabled': status === 'loading' || error || status === 'init',
                            'saved': isSaved
                        })}
                    type="button"
                    onClick={onApproveClick}
                    disabled={status === 'loading' || status === 'init' || error}
                >
                    {isSaved ? 'Сохранено' : 'Сохранить'}
                </button>
                <div className={styles['preview-wrapper']}>
                    {previewsImages.length > 0 && previewsImages.map((file) => (
                        <div
                            key={file.id} className={styles['preview']}
                        >
                            <div className={styles['preview-inner']}>
                                <img className={styles['preview-image']} src={file.small} alt="mini" />
                                <div
                                    className={cx({ 'icon-wrapper': true, })}
                                    onClick={() => onClearClick(file.id)}>
                                    <Sprite
                                        name="close-bold"
                                        role="img"
                                        className={cx({ 'close-icon': true })}
                                    />
                                </div>
                            </div>
                            <span className={styles['preview-date']}>{convertDateToDMY(file.created)}</span>
                        </div>
                    ))
                    }
                </div>
                {error && <p className={styles.error}>{error}</p>}
            </td>
        </tr>
    )
})
function GalleryField(props) {
    const {
        fieldName,
        galleryFieldName,
        setFieldValue,
        isEditable,
        galleryFieldValue,
        galleryPhotosIdField
    } = props
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isDeleted, setIsDeleted] = useState(false)
    const [error, setError] = React.useState('');
    
    if (galleryFieldValue.length > 0 && !isEditable) {
        return (
            <>
                <tr className={styles.row}>
                    <th className={cx({ 'row-title': true, 'row-read': true })}>{fieldName}</th>
                    <td className={cx({ cell: true })}>
                        <button
                            className={cx({ button: true, 'button-show': true })}
                            type="button"
                            onClick={() => setIsModalOpen(true)}
                        >
                            Показать
                        </button>
                    </td>
                </tr>
                <GoatContentModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                >
                    <Slider
                        totalSlides={galleryFieldValue.length}
                    >
                        {galleryFieldValue.map(photo => (
                            <div key={photo.id} className={styles['image-wrapper']}>
                                <span className={styles.date}>{convertDateToDMY(photo.created)}</span>
                                <picture >
                                    <source srcSet={photo.medium} media="(min-width: 768px)" width="512" height="512" />
                                    <img className={styles.image} media="(min-width: 320px)" src={photo.small} alt="Фотография козы" />
                                </picture>
                            </div>

                        ))}
                    </Slider>

                </GoatContentModal>
            </>
        )
    } else {
        if (isEditable) {
            return (
                <div className={styles.wrapper}>
                    <Field
                        name={galleryFieldName}
                        setError={setError}
                        error={error}
                        setFieldValue={setFieldValue}
                        component={FileField}
                        galleryPhotosIdField={galleryPhotosIdField}
                        galleryFieldValue={galleryFieldValue}
                        galleryFieldName={galleryFieldName}
                        fieldName={fieldName}


                    />
                </div>
            )
        }
    }
    return null
};
export default memo(GalleryField);