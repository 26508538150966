import React from 'react';

import TextField from '../../../../components/TextField/TextField';
import PhoneField from '../PhoneField/PhoneField';
import PasswordField from '../../../../components/PasswordField/PasswordField';

import styles from './CustomFieldSet.module.css';

function CustomFieldSet(props) {
  return (
    <>
      <p className={styles.label}>{props.label}</p>

      {props.type === 'text' && (
        <TextField
          name={props.name}
          error={props.error}
          fieldClassName={styles.field}
          errorClassName={styles.error}
        />
      )}

      {props.type === 'tel' && (
        <PhoneField
          name={props.name}
          error={props.error}
          fieldClassName={styles.field}
          errorClassName={styles.error}
        />
      )}

      {props.type === 'password' && (
        <PasswordField
          name={props.name}
          error={props.error}
          fieldClassName={styles.field}
          errorClassName={styles.error}
          onBlur={props.onBlur}
        />
      )}

      {props.type === 'custom' && props.children}
    </>
  );
}

export default CustomFieldSet;
