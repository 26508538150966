import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import Sprite from '../../../../components/Sprite';

import styles from './ActionButton.module.css';

function ActionButton(props) {
  if (props.role === 'link') {
    return (
      <Link to={props.to} className={cn(styles.btn, props.className)} onClick={props.onClick}>
        <Sprite fill={props.fill} stroke={props.stroke} role="img" name={props.icon} />{' '}
        <p className={styles.text}>{props.children}</p>
      </Link>
    );
  }

  if (props.role === 'button') {
    return (
      <button className={cn(styles.btn, props.className)} type={props.type} onClick={props.onClick}>
        <Sprite fill={props.fill} stroke={props.stroke} role="img" name={props.icon} />{' '}
        <p className={styles.text}>{props.children}</p>
      </button>
    );
  }

  if (props.role === 'note') {
    return (
      <button className={cn(styles.btn, props.className)} type={props.type} onClick={props.onClick}>
        <Sprite fill={props.fill} stroke={props.stroke} role="img" name={props.icon} />
      </button>
    );
  }
}

export default ActionButton;
