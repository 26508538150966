import React from 'react';
import { Field, ErrorMessage } from 'formik';
import cn from 'classnames/bind';

import Sprite from '../Sprite';

import styles from './PasswordField.module.css';

const cx = cn.bind(styles);

function PasswordField(props) {
  const [fieldType, setFieldType] = React.useState('password');
  const [isFieldFocus, setIsFieldFocus] = React.useState(false);

  function handleClick(props) {
    if (fieldType === 'password') setFieldType('text');
    else setFieldType('password');
  }

  function handleBlur(e) {
    if (props.removeSpacesOnBlur) {
      const value = (e.target.value || '').split(' ').join('');

      props.setFieldValue(props.name, value);
      props.handleBlur(e);
    }
  }

  const title = fieldType === 'password' ? 'Показать пароль' : ' Скрыть пароль';

  return (
    <>
      <div
        className={cx(
          {
            wrapper: true,
            'wrapper-error': !!props.error,
            'wrapper-focused': isFieldFocus,
          },
          props.fieldClassName,
        )}
      >
        <Field
          name={props.name}
          type={fieldType}
          id={props.id}
          onFocus={() => setIsFieldFocus(true)}
          onBlur={(e) => {
            props.onBlur(e);
            handleBlur(e);
            setIsFieldFocus(false);
          }}
          className={styles.field}
        />

        <button type="button" onClick={handleClick} className={styles.btn}>
          <Sprite
            name={fieldType === 'password' ? 'eye-close' : 'eye-open'}
            role="img"
            focusable="false"
            id="switch-password-visibility"
            title={title}
            className={styles.icon}
          />
        </button>
      </div>

      <ErrorMessage
        name={props.name}
        component="p"
        className={cn(styles.error, props.errorClassName)}
      />
    </>
  );
}

export default PasswordField;
