import React from "react";
import Leaf from "../Leaf/Leaf";
import styles from "./LeafBranch.module.css";
function LeafBranch({leaf, id, tag, children}){
  
    return (
      <li className={styles.node}>
          <div className={styles.child}>
            {leaf && <Leaf tag={tag} id={id} name={leaf}/>}
          </div>
          {children}
      </li>
    )
  };
  export default LeafBranch;