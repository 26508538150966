import React from "react";
import styles from "./Leaf.module.css";
import goatImg from "../../../../assets/images/test-goat.jpg"
import { trimGoatName } from "../../../../utils/helpers";
import {useHistory } from "react-router-dom";

function Leaf({name, id, tag, avatar}){
  let history = useHistory();
  
  const handleCardClick =(id) => {
    history.push(`/goat/${id}`)
  }

  return (
    <div className={styles.leaf} onClick={() => handleCardClick(id)} >
        <div className={styles.inner}>
           <div className={styles.image}>
              <img src={goatImg} alt="козочка/козлик" />
           </div>
           <h4 className={styles.title} data-tooltip={name}>{trimGoatName(name)}</h4>
           {tag !== undefined &&  <span className={styles.tag}>{tag}</span> }
          
        </div>
    </div> 
  )
};
export default Leaf;
