import React from 'react';

import Container from '../../components/Container/Container';
import PageTitle from '../../components/PageTitle/PageTitle';
import ProfileAvatarBlock from '../../components/ProfileAvatarBlock/ProfileAvatarBlock';
import ProfileInfoBlock from '../../components/ProfileInfoBlock/ProfileInfoBlock';
import ProfilePasswordBlock from '../../components/ProfilePasswordBlock/ProfilePasswordBlock';

import styles from './Profile.module.css';

function Profile() {
  return (
    <Container wrapperClassName={styles.wrapper}>
      <PageTitle title="Профиль" />

      <div className={styles.grid}>
        <ProfileAvatarBlock />

        <ProfileInfoBlock />

        <ProfilePasswordBlock />
      </div>
    </Container>
  );
}

export default Profile;
