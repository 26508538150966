import React, { memo } from "react"
import GalleryField from "../GalleryField/GalleryField"

const AdopterGalleryField = memo((props) => {
    const {
        id,
        setFieldValue,
        isEditable,
        ownerPhotos
    } = props
    return (
        <GalleryField
            id={id}
            setFieldValue={setFieldValue}
            isEditable={isEditable}
            galleryFieldValue={ownerPhotos}
            galleryPhotosIdField={'ownerPhotosId'}
            galleryFieldName={'ownerPhotos'}
            fieldName={'Фото от собственника'}
        />
    )
})
export default AdopterGalleryField