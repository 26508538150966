import { DateTime } from 'luxon';
import { isObject } from './helpers';

export const convertSingleDate = (date, format) => {
  if (date instanceof Date) date = DateTime.fromISO(date.toISOString()).toFormat(format);
  if (date === '') return null;

  return date;
};

export const convertDateToYMD = (date) => convertSingleDate(date, 'yyyy-LL-dd');

export const convertDateToDMY = (date) => {
  date = DateTime.fromISO(date).toFormat('dd.LL.yyyy');

  if (date === '') return null;

  return date;
};

const isNotDateObject = (date) =>
  date !== '' && date !== null && !(date instanceof Date) && date.includes('-');

// normal date
export const convertFormDates = (obj, searchKeys) => {
  // eslint-disable-next-line
  Object.entries(obj).map(([objKey, objValue]) => {
    for (const searchKey of searchKeys) {
      if (searchKey === objKey) {
        if (isNotDateObject(objValue)) obj = { ...obj, ...{ [objKey]: objValue } };
        else obj = { ...obj, ...{ [objKey]: convertDateToYMD(objValue) } };
      }
    }

    if (Array.isArray(objValue)) {
      // eslint-disable-next-line
      objValue.map((arrItem) => {
        // eslint-disable-next-line
        Object.keys(arrItem).map((key) => {
          for (const searchKey of searchKeys) {
            if (searchKey === key) {
              if (!isNotDateObject(arrItem[key])) arrItem[key] = convertDateToYMD(arrItem[key]);
            }
          }

          obj = { ...obj, ...{ [objKey]: [...objValue] } };
        });
      });
    }

    if (isObject(objValue)) {
      // eslint-disable-next-line
      Object.keys(objValue).map((key) => {
        for (const searchKey of searchKeys) {
          if (searchKey === key) {
            if (!isNotDateObject(objValue[key])) objValue[key] = convertDateToYMD(objValue[key]);
          }
        }

        obj = { ...obj, ...{ [objKey]: { ...objValue } } };
      });
    }
  });

  return obj;
};

// date with time
// ills object converter
const offset = DateTime.local().offset / 60 + 1;
export const transformTimeDate = (datetime, format, plus = offset, minus = 0) => {
  if (datetime === '' || datetime === null) return null;

  if (format === 'dmy') {
    datetime = DateTime.fromISO(datetime)
      .plus({ hours: plus })
      .minus({ hours: minus })
      .toFormat('dd.LL.yyyy');
  }

  if (format === 'dmyt') {
    datetime = DateTime.fromISO(datetime)
      .plus({ hours: plus })
      .minus({ hours: minus })
      .toFormat('dd.LL.yyyy HH:mm');
  }

  if (format === 'ymd') {
    let [date, time] = datetime.split(' ');
    const [day, month, year] = date.split('.');
    const [hour, minute] = time.split(':');

    datetime = DateTime.fromObject({
      year,
      month,
      day,
      hour,
      minute,
    }).toISO();
  }

  return datetime;
};

export const convertObjDate = (obj, type) => {
  // eslint-disable-next-line
  Object.entries(obj).map(([key, value]) => {
    if (key === 'date') {
      if (value === '') obj = { ...obj, [key]: null };

      if (type === 'req') {
        const date = transformTimeDate(value, 'ymd');
        obj = { ...obj, [key]: date };
      }

      if (type === 'res') {
        const date = transformTimeDate(value, 'dmy');
        obj = { ...obj, [key]: date };
      }
    }
  });

  return obj;
};

export const convertArrOfObjDate = (arr, type) => {
  arr.map((obj) => convertObjDate(obj, type));
  console.log(arr);

  return arr;
};

// dates sorting
const getDateTime = (date) => new Date(date).getTime();
export const sortLowerDates = (arr) =>
  arr.sort((a, b) => getDateTime(a.date) - getDateTime(b.date));
export const sortHigherDates = (arr) =>
  arr.sort((a, b) => getDateTime(b.date) - getDateTime(a.date));

export const notificationDateInfo = (date) => {
  const offset = DateTime.local().offset / 60 + 5;
  date = DateTime.fromISO(date, { locale: 'ru' })
    .plus({ hours: offset })
    .toFormat('HH:mm dd MMM yyyy');

  return date;
};
