import React from 'react';
import Modal from 'react-modal';
import cn from 'classnames/bind';
import { Formik, Form } from 'formik';

import ButtonClose from '../../../../components/ButtonClose/ButtonClose';
import UserFieldSet from '../UserFieldSet/UserFieldSet';

import styles from './CreateUserModal.module.css';
import useUsers from '../../../../hooks/api/use-users';
import { CreateUsersSchema } from '../../../../utils/validations';

const cx = cn.bind(styles);
const initialValues = {
  email: '',
  password: '',
  fullName: '',
  phone: '',
  role: 'user',
};

Modal.setAppElement('#root');

const CreateUserModal = React.forwardRef((props, ref) => {
  const { createUsers } = useUsers();
  function handleSubmit(values, actions) {
    createUsers(values, actions, props.outerActions, props.onClose);
  }

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onClose}
        shouldCloseOnOverlayClick
        closeTimeoutMS={300}
        className={cx({ modal: true })}
        overlayClassName={cx({ overlay: true })}
        ref={ref}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={CreateUsersSchema}
          render={({ values, touched, errors, ...actions }) => (
            <Form>
              <section className={styles.wrapper}>
                <div className={styles.header}>
                  <p className={styles.title}>Создание пользователя</p>

                  <ButtonClose
                    onClick={props.onClose}
                    stroke="var(--dark-gray)"
                    className={cx({ 'btn-close': true })}
                  />
                </div>

                <div className={cx({ content: true })}>
                  <UserFieldSet
                    title="Email"
                    type="email"
                    name="email"
                    touched={touched}
                    errors={errors}
                  />

                  <UserFieldSet
                    title="Пароль"
                    type="password"
                    name="password"
                    touched={touched}
                    errors={errors}
                    actions={actions}
                  />

                  <UserFieldSet
                    title="Фамилия Имя Отчество"
                    type="text"
                    name="fullName"
                    touched={touched}
                    errors={errors}
                  />

                  <UserFieldSet
                    title="Контактный телефон"
                    type="phone"
                    name="phone"
                    touched={touched}
                    errors={errors}
                  />

                  <UserFieldSet
                    title="Права доступа"
                    type="select"
                    name="role"
                    values={values}
                    touched={touched}
                    errors={errors}
                    actions={actions}
                  />

                  <div className={cx({ buttons: true })}>
                    <button type="submit" className={cx({ 'btn-create': true })}>
                      Создать
                    </button>

                    <button
                      type="button"
                      onClick={props.onClose}
                      className={cx({ 'btn-cancel': true })}
                    >
                      Отменить
                    </button>
                  </div>
                </div>
              </section>
            </Form>
          )}
        />
      </Modal>
    </>
  );
});

export default CreateUserModal;
