import React from 'react';
import cn from 'classnames/bind';

import NotificationItem from '../NotificationItem/NotificationItem';
import Checkbox from '../../components/Checkbox/Checkbox';
import Sprite from '../../../../components/Sprite';

import styles from './PageNotificationsList.module.css';
import api from '../../../../utils/api';
import { compareArrays } from '../../../../utils/helpers';

const cx = cn.bind(styles);

function PageNotificationsList(props) {
  const isResultsExists = props.notifications?.results?.[0];
  const [indexes, setIndexes] = React.useState([]);

  const compare = compareArrays(indexes, setAllIndexes());
  const getIndexes = (arr) => arr.map((item) => item.replace(/[^0-9]/g, ''));

  function findIndex(index) {
    const isIndexExists = indexes.find((item) => item === index);

    if (isIndexExists) return true;
    else return '';
  }

  function checkIndexes(index) {
    let idx = [...indexes];
    const isIndexExists = idx.find((item) => item === index);

    if (isIndexExists) idx = idx.filter((item) => item !== index);
    else idx = [...idx, index];

    setIndexes(idx);
  }

  function setAllIndexes() {
    let indexes = [];

    props.notifications.results &&
      props.notifications.results.map((_, index) => (indexes = [...indexes, `notify-${index}`]));

    return indexes;
  }

  function handleMainCheckboxClick() {
    const idx = indexes.length;

    if (idx) setIndexes([]);
    if (!idx) setIndexes(setAllIndexes());
  }

  function handleCheckboxClick(index) {
    return checkIndexes(`notify-${index}`);
  }

  function handleViewClick() {
    setIndexes([]);

    const indexesArr = getIndexes(indexes);
    const results = props.notifications.results;

    let list = [];
    let ids = [];

    for (let i = 0; i < results.length; i++) {
      if (!(indexesArr.map(Number).indexOf(i) === -1)) {
        ids.push(results[i].id);

        list.push({
          ...results[i],
          isView: true,
        });
      } else {
        list.push(results[i]);
      }
    }

    props.setNotifications({ ...props.notifications, results: list });

    api.post('/notifications/view/', { ids });
  }

  return (
    <div className={cx({ wrapper: true })}>
      {isResultsExists ? (
        <>
          <div className={cx({ header: true })}>
            <Checkbox
              type={compare ? 'check' : 'dash'}
              onClick={handleMainCheckboxClick}
              checked={indexes.length ? true : ''}
            />

            {indexes.length ? (
              <button
                type="button"
                className={cx({ 'check-button': true })}
                onClick={handleViewClick}
              >
                <Sprite name="eye-open" role="img" />
                Просмотрено
              </button>
            ) : null}
          </div>

          <ul className={cx({ list: true })}>
            {props.notifications?.results?.map(
              ({ authorName, actionTarget, actionType, created, isView }, i) => {
                const { type, objectId, name, deleted } = actionTarget;

                return (
                  <li className={cx({ 'list-item': true, 'list-item-unread': !isView })} key={i}>
                    <div className={cx({ actions: true })}>
                      <Checkbox
                        onClick={() => handleCheckboxClick(i)}
                        checked={findIndex(`notify-${i}`)}
                      />
                    </div>

                    <NotificationItem
                      className={cx({ content: true })}
                      authorName={authorName}
                      type={type}
                      objectId={objectId}
                      name={name}
                      actionType={actionType}
                      created={created}
                      isView={isView}
                      deleted={deleted}
                    />
                  </li>
                );
              },
            )}
          </ul>
        </>
      ) : (
        <p className={cx({ 'empty-results': true })}>Результаты не найдены</p>
      )}
    </div>
  );
}

export default PageNotificationsList;
