export function setCookie(key, value, time) {
  const date = new Date();
  let expires = '';

  if (time) {
    date.setTime(date.getTime() + time);
    expires = `expires=${date.toUTCString()}`;
  }

  document.cookie = `${key}=${value};${expires};path=/`;
}

export function getCookie(key) {
  const name = `${key}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }

    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return null;
}

export function clearCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const position = cookie.indexOf('=');
    const name = position > -1 ? cookie.substr(0, position) : cookie;

    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
  }
}
