import React from 'react';
import cn from 'classnames';

import styles from './BlockWrapper.module.css';

function BlockWrapper(props) {
  return <section className={cn(styles.wrapper, props.className)}>{props.children}</section>;
}

export default BlockWrapper;
