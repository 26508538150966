// НЕ ИСПОЛЬЗУЕТСЯ БОЛЬШЕ
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';

import Container from '../../components/Container/Container';
import LambSearchTable from '../../components/LambSearchTable/LambSearchTable';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import PageSizeSelect from '../../components/PageSizeSelect/PageSizeSelect';
import LambHeader from '../../components/LambHeader/LambHeader';

import useLamb from '../../../../hooks/api/use-lamb';
import { getPageCount } from '../../../../utils/helpers';

import api from '../../../../utils/api';

import styles from './Lamb.module.css';
import cn from 'classnames/bind';
import { convertDateToYMD } from '../../../../utils/dates';
const cx = cn.bind(styles);

function Lamb(props) {
  const initialValues = {
    id: '',
    father: '',
    dateMating: '',
    start: '',
    projectedLamb: '',
    daysForLamb: '',
    actualLamb: '',
    totalChildren: '',
    deadChildren: '',
    goatMale: '',
    goatFemale: '',
    columnColor: '',
    note: '',
    house: '',
    ills: '',
    vaccinations: '',
    lambings: '',
    matings: '',
    condFrom: '',
    condTo: '',
    tag: '',
    chip: '',
    name: '',
    emrId: '',
    birthDate: '',
    modified: '',
    orderBy: '',
    page: '1',
    pageSize: '20',
  };

  const { goats, setGoats, updateGoats } = useLamb();
  const [idGoat, setIdGoat] = useState('');
  const [goatColor, setGoatColor] = useState(null);
  const [error, setError] = useState('');
  const [colorRow, setColorRow] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [noteValue, setNoteValue] = useState(null);
  const [note, setNote] = useState(null);
  const [noteRow, setNoteRow] = useState('');
  const [isHandleInput, setIsHandleInput] = useState(false);

  function handleSubmit(values, actions) {
    setGoats(values, actions);
  }

  function handlePageClick(data, values, actions) {
    setGoats({ ...values, page: data.selected + 1 }, actions);
    setPage(data.selected + 1);
  }

  function handleSelectChange({ name, value, setFieldValue }, values, actions) {
    setFieldValue(name, value);
    setGoats({ ...values, pageSize: value }, actions);
    setPageSize(value);
  }

  function colorChangeHandler(id, e) {
    setGoatColor({
      id,
      columnColor: e.hex,
    });
  }

  function handleClickColor(id, color) {
    if (color) {
      setGoatColor({
        id,
        columnColor: null,
      });
    }
    setIdGoat(id);
  }

  function handleChangeNote(e, id) {
    setNote({ id, text: e.currentTarget.value });
  }

  function getValueInput(e, id, field) {
    const value =
      note.text === '' &&
      (field === 'deadChildren' ||
        field === 'goatMale' ||
        field === 'goatFemale' ||
        field === 'leftMammaryGland' ||
        field === 'rightMammaryGland')
        ? 0
        : note.text;
    setNoteValue({
      id,
      [field]: value,
    });
    setIsHandleInput(true);
  }

  function handleInputNote(e, id, field) {
    if (note) getValueInput(e, id, field);
  }

  function handleEnterPressNote(e, id, field) {
    if (e.key === 'Enter' && note) getValueInput(e, id, field);
  }

  function handleDateChange(value, id, field) {
    const formatDate = convertDateToYMD(value);
    setNoteValue({
      id,
      [field]: formatDate,
    });
  }
  //отправляем на сервер новое примечание
  useEffect(() => {
    if (isHandleInput) {
      api
        .patch(`/table-view/${noteValue.id}/`, noteValue)
        .then((resp) => setNoteRow(resp.data))
        .catch((error) => setError('error'));
      setNoteValue(null);
      setNote(null);
      setIsHandleInput(false);
    }
  }, [noteValue, isHandleInput]);
  //отправляем на сервер выбранный цвет
  useEffect(() => {
    if (goatColor) {
      api
        .patch(`/table-view/${goatColor.id}/`, goatColor)
        .then((resp) => setColorRow(resp.data.columnColor))
        .catch((error) => setError(error));
      setIdGoat('');
    }
  }, [goatColor]);
  //грузим коз при изменении примечания
  useEffect(() => {
    if (noteRow || noteRow === null) {
      updateGoats(page, pageSize);
      setNoteRow('');
    }
  }, [noteRow, page, pageSize, updateGoats]);
  //грузим коз при изменении цвета
  useEffect(() => {
    if (colorRow || colorRow === null) {
      updateGoats(page, pageSize);
      setColorRow('');
    }
  }, [colorRow, page, pageSize, updateGoats]);

  return (
    <Container className={styles.container} wrapperClassName={styles.wrapper}>
      <LambHeader className={styles.header} />

      {goats?.results ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          render={({ values, ...actions }) => (
            <Form className={styles.form} noValidate>
              <LambSearchTable
                goats={goats}
                history={props.history}
                values={values}
                actions={actions}
                onFilter={handleSubmit}
                colorChangeHandler={colorChangeHandler}
                handleClickColor={handleClickColor}
                idGoat={idGoat}
                handleInputNote={handleInputNote}
                handleChangeNote={handleChangeNote}
                handleDateChange={handleDateChange}
                handleEnterPressNote={handleEnterPressNote}
              >
                <>
                  <div>
                    {goats.results.length ? (
                      <CustomPagination
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        pageCount={getPageCount(goats.count, values.pageSize)}
                        onPageChange={(data) => handlePageClick(data, values, actions)}
                      />
                    ) : null}
                  </div>

                  <PageSizeSelect
                    name="pageSize"
                    values={values}
                    onChange={(e) => handleSelectChange(e, values, actions)}
                    {...actions}
                  />
                </>
              </LambSearchTable>
            </Form>
          )}
        />
      ) : (
        <p className={cx({ 'empty-results': true })}>Список коз пуст</p>
      )}
      {error && <div>{error}</div>}
    </Container>
  );
}

export default Lamb;
