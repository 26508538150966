import React from 'react';
import cn from 'classnames/bind';
import { Link } from 'react-router-dom';

import NotificationItem from '../../components/NotificationItem/NotificationItem';
import Sprite from '../../../../components/Sprite';

import styles from './NotificationsMenu.module.css';
import useComponentVisible from '../../../../hooks/use-component-visible';
import useNotifications from '../../../../hooks/api/use-notifications';

const cx = cn.bind(styles);

function NotificationsMenu() {
  const { notifications, notificationsCount } = useNotifications();
  const filteredNotifications = notifications?.results?.filter(({ isView }) => isView === false);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const toggleMenu = () => setIsComponentVisible(!isComponentVisible);
  const isNotifyExists = notificationsCount > 0;

  function handleMenuClick(e) {
    const eventTag = e.target.tagName;

    if (eventTag === 'A') setIsComponentVisible(false);
    else setIsComponentVisible(true);
  }

  function content() {
    return (
      <>
        <div className={cx({ wrapper: true, 'wrapper-active': isComponentVisible })}>
          {isNotifyExists && (
            <p className={cx({ counter: true, 'counter-active': isComponentVisible })}>
              {notificationsCount}
            </p>
          )}

          <div>
            <Sprite className={cx({ icon: true })} name="bell" />
          </div>
        </div>

        {isComponentVisible && (
          <div className={cx({ menu: true })}>
            <div className={cx({ 'menu-content': true })}>
              <p className={cx({ 'menu-title': true })}>Уведомления</p>

              <ul className={cx({ list: true })}>
                {filteredNotifications
                  ?.slice(0, 3)
                  .map(({ authorName, actionTarget, actionType, created }, i) => {
                    const { type, objectId, name, deleted } = actionTarget;

                    return (
                      <li className={cx({ 'list-item': true })} key={i}>
                        <NotificationItem
                          className={cx({ content: true })}
                          authorName={authorName}
                          type={type}
                          objectId={objectId}
                          name={name}
                          actionType={actionType}
                          created={created}
                          isView={true}
                          deleted={deleted}
                        />
                      </li>
                    );
                  })}
              </ul>
            </div>

            <Link to="/notifications" className={cx({ link: true })} onClick={handleMenuClick}>
              Показать все
            </Link>
          </div>
        )}
      </>
    );
  }

  return isNotifyExists ? (
    //eslint-disable-next-line
    <div ref={ref} onClick={toggleMenu} className={cx({ container: true, 'container-active': isComponentVisible })}>
      {content()}
    </div>
  ) : (
    <Link className={cx({ container: true })} to="/notifications" onClick={handleMenuClick}>
      {content()}
    </Link>
  );
}

export default NotificationsMenu;
