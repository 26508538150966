import React from 'react';
import cn from 'classnames';

import styles from './Container.module.css';

function Container(props) {
  return (
    <div className={cn(styles.container, props.className)}>
      <div className={cn(styles.wrapper, props.wrapperClassName)}>{props.children}</div>
    </div>
  );
}

export default Container;
