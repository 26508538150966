import React from 'react';
import cn from 'classnames/bind';
import MaskedInput from 'react-text-mask';

import Sprite from '../../components/Sprite';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

import styles from './FieldDatePicker.module.css';

const cx = cn.bind(styles);

function FieldDatePicker(props) {
  return (
    <div className={cn(styles.wrapper, props.className)}>
      <div className={cx({ 'icon-wrapper': true })}>
        <Sprite className={cn(styles.icon, props.iconClassName)} name={props.icon} role="img" />
      </div>

      <CustomDatePicker
        name={props.name}
        // onChange={(value) => props.onChange(props.name, value)}
        onChange={(value) => {
          if (props.static) {
            props.onChange(props.name, value);
          } else {
            props.onChange(props.name, value);
            setTimeout(props.actions.submitForm, 200);
          }
        }}
        value={props.value}
        selectedMinDate={props.selectedMinDate}
        selectedMaxDate={props.selectedMaxDate}
        popperPlacement={props.popperPlacement}
        customInput={
          <MaskedInput
            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
            keepCharPositions
            guide={false}
            className={styles.field}
            type="search"
          />
        }
      />
    </div>
  );
}

export default FieldDatePicker;
