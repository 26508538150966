import React from 'react';
import { Formik, Form } from 'formik';
import cn from 'classnames/bind';

import Container from '../../components/Container/Container';
import UsersHeader from '../../components/UsersHeader/UsersHeader';
import UsersTable from '../../components/UsersTable/UsersTable';
import CreateUserModal from '../../components/CreateUserModal/CreateUserModal';

import styles from './Users.module.css';
import useUsers from '../../../../hooks/api/use-users';
import useScrollLock from '../../../../hooks/use-scroll-lock';

const cx = cn.bind(styles);

function Users() {
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const { users, setUsers, editUsers } = useUsers();
  const initialValues = {
    pageSize: 20,
    ...users,
  };
  const { targetRef, targetElement, disableBodyScroll, enableBodyScroll } = useScrollLock();

  function handleSubmit(values, actions) {
    for (const row of selectedRows) {
      const result = values.results[row];

      editUsers(result.id, result).then(() => {
        setSelectedRows([]);
        setIsEdit(false);
        actions.setValues(values);
        actions.setSubmitting(false);
      });
    }
  }

  function handlePageClick(data, values, actions) {
    setUsers({ ...values, page: data.selected + 1 }, actions);
  }

  function handleSelectChange({ name, value, setFieldValue }, values, actions) {
    setFieldValue(name, value);
    setUsers({ ...values, pageSize: value }, actions);
  }

  return (
    <Container className={styles.container} wrapperClassName={styles.wrapper}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        render={({ values, ...actions }) => (
          <Form noValidate>
            <UsersHeader
              className={cx({ header: true })}
              onClick={() => {
                setIsCreateModalOpen(true);
                disableBodyScroll(targetElement);
              }}
            />

            {values.results ? (
              <UsersTable
                values={values}
                actions={actions}
                isCreateModalOpen={isCreateModalOpen}
                onPageChange={handlePageClick}
                onSelectChange={handleSelectChange}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
              />
            ) : (
              <p className={cx({ 'empty-results': true })}>Список пользователей пуст</p>
            )}

            <CreateUserModal
              isOpen={isCreateModalOpen}
              outerActions={actions}
              onClose={() => {
                setIsCreateModalOpen(false);
                enableBodyScroll(targetElement);
              }}
              ref={targetRef}
            />
          </Form>
        )}
      />
    </Container>
  );
}

export default Users;
