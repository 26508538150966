import React from 'react';
import { Route, Redirect } from 'react-router';

function PrivateRoute(props) {
  if (props.isAccess) {
    return <Route {...props} />;
  } else {
    return <Redirect to={props.redirectTo} />;
  }
}

export default PrivateRoute;
