import React from 'react';
import cn from 'classnames';

import FilterField from '../FilterField/FilterField';

import styles from './SearchTableHeadCell.module.css';

function SearchTableHeadCell(props) {
  return (
    <th className={cn(styles.cell, props.className)}>
      {props.children}

      <FilterField
        className={styles.field}
        iconClassName={styles.icon}
        icon={props.icon}
        name={props.name}
        actions={props.actions}
      />
    </th>
  );
}

export default SearchTableHeadCell;
