import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import Layout from '../components/Layout/Layout';
import Login from '../pages/Login/Login';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ChangePassword from '../pages/ChangePassword/ChangePassword';

function Auth() {
  const token = useSelector((state) => state.logIn.token);

  if (token !== null) {
    return <Redirect to="/" />;
  }

  return (
    <Layout>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/reset-password" exact component={ResetPassword} />
        <Route path="/reset-password/confirm/:token" component={ChangePassword} />

        <Redirect to="/login" />
      </Switch>
    </Layout>
  );
}

export default Auth;
