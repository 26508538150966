import React from 'react';
import cn from 'classnames/bind';
import MaskedInput from 'react-text-mask';
import Textarea from 'react-textarea-autosize';
import { Field } from 'formik';
import { Link } from 'react-router-dom';

import DatepickerField from '../../components/DatepickerField/DatepickerField';

import styles from './ResearchesInfo.module.css';
import * as regExp from '../../../../utils/regular-expressions';
import { convertDateToDMY } from '../../../../utils/dates';

const cx = cn.bind(styles);

function ResearchesInfo(props) {
  const mask = Array(15).fill(/\d/);
  // const mask = [/\d/, /\d/, /\d/, /\d/];
  const { setFieldValue } = props.actions;

  return (
    <div>
      <section className={cx({ wrapper: true })}>
        <h2 className={cx({ title: true })}>Основная информация</h2>

        <FieldWrapper
          title="Дата"
          isEdit={props.isEdit}
          name="date"
          values={props.values}
          errors={props.errors}
          touched={props.actions.touched}
        >
          <CustomDatePicker
            name="date"
            value={props.values.date}
            onChange={setFieldValue}
            touched={props.actions.touched}
          />
        </FieldWrapper>

        <FieldWrapper
          title="Название"
          isEdit={props.isEdit}
          name="title"
          values={props.values}
          errors={props.errors}
          touched={props.actions.touched}
        >
          <CustomField
            name="title"
            errors={props.errors}
            maxLength="128"
            touched={props.actions.touched}
            setFieldValue={setFieldValue}
          />
        </FieldWrapper>

        <FieldWrapper
          title="Вид животного"
          isEdit={props.isEdit}
          name="species"
          values={props.values}
          errors={props.errors}
          touched={props.actions.touched}
        >
          <CustomField
            name="species"
            errors={props.errors}
            maxLength="128"
            touched={props.actions.touched}
            setFieldValue={setFieldValue}
          />
        </FieldWrapper>

        <FieldWrapper
          title="Кличка"
          isEdit={props.isEdit}
          name="goatName"
          values={props.values}
          errors={props.errors}
          touched={props.actions.touched}
        >
          <CustomField
            name="goatName"
            errors={props.errors}
            maxLength="128"
            touched={props.actions.touched}
            setFieldValue={setFieldValue}
            onValidate={(value) => regExp.newOnlyLetAndNum.test(value)}
          />
        </FieldWrapper>

        <FieldWrapper
          title="Номер чипа"
          isEdit={props.isEdit}
          name="goatChip"
          values={props.values}
          errors={props.errors}
          touched={props.actions.touched}
        >
          <CustomField
            name="goatChip"
            errors={props.errors}
            mask={mask}
            guide={false}
            className={cx({ 'field-cheap': true })}
            touched={props.actions.touched}
            setFieldValue={setFieldValue}
          />
        </FieldWrapper>

        <FieldWrapper
          title="Результаты исследования"
          isEdit={props.isEdit}
          name="conclusion"
          values={props.values}
          errors={props.errors}
          touched={props.actions.touched}
        >
          <CustomTextArea name="conclusion" />
        </FieldWrapper>
      </section>
    </div>
  );
}

function FieldWrapper(props) {
  const value = props.values[props.name];
  const error = props.errors[props.name];
  const touched = props.touched[props.name];

  const content = () => {
    if (props.name === 'date' && value) return convertDateToDMY(value);
    if (props.name === 'goatName' && props.values.goatId && value)
      return (
        <Link className={cx({ link: true })} to={`../../goat/${props.values.goatId}`}>
          {value}
        </Link>
      );

    return value;
  };

  return (
    <div className={cx({ 'contet-row': true })}>
      <p className={cx({ 'field-title': true, 'field-title-edit': props.isEdit })}>{props.title}</p>

      <div className={cx({ 'field-wrapper': true })}>
        {props.isEdit ? props.children : <p className={cx({ 'field-text': true })}>{content()}</p>}
        {error && touched && <p className={cx({ 'text-error': true })}>{error}</p>}
      </div>
    </div>
  );
}

function CustomDatePicker(props) {
  return (
    <Field
      name={props.name}
      render={({ field }) => (
        <DatepickerField
          {...field}
          className={cx({ field: true, 'field-date': true })}
          value={props.value}
          onChange={props.onChange}
        />
      )}
    />
  );
}

function CustomField(props) {
  const touched = props.touched[props.name];
  const error = props.errors[props.name];

  return (
    <>
      {props.onValidate ? (
        <Field
          name={props.name}
          className={cx({ field: true, 'field-error': error && touched }, props.className)}
          placeholder={props.placeholder}
          type="text"
          maxLength={props.maxLength}
          onChange={(e) => {
            const value = e.target.value;

            if (props.onValidate) {
              if (props.onValidate(value)) {
                props.setFieldValue(props.name, value);
              }
            } else {
              props.setFieldValue(props.name, value);
            }
          }}
        />
      ) : (
        <Field
          name={props.name}
          render={({ field }) => (
            <MaskedInput
              {...field}
              mask={props.mask || false}
              keepCharPositions
              guide={props.guide}
              className={cx({ field: true, 'field-error': error && touched }, props.className)}
              placeholder={props.placeholder}
              type="text"
              maxLength={props.maxLength}
              onChange={(e) => {
                const value = e.target.value;

                if (props.onValidate) {
                  if (props.onValidate(value)) {
                    props.setFieldValue(props.name, value);
                  }
                } else {
                  props.setFieldValue(props.name, value);
                }
              }}
            />
          )}
        />
      )}
    </>
  );
}

function CustomTextArea(props) {
  return (
    <Field
      name={props.name}
      render={({ field }) => (
        <Textarea className={cx({ textarea: true })} {...field} maxLength={props.maxLength} />
      )}
    />
  );
}

export default ResearchesInfo;
