import { useState, useEffect } from 'react';

import api from '../../utils/api';
import { notify } from '../../components/Toast/Toast';
import { phoneToStr } from '../../utils/phone-parser';
import * as errors from '../../utils/error-messages';

export default () => {
  const [state, setState] = useState({});

  useEffect(() => {
    api
      .get('/users/?page_size=20')
      // .then(({ data }) => setState({ ...data, page: '1', pageSize: '20' }));
      .then(({ data }) => setState(data));
  }, []);

  return {
    users: state,

    createUsers: async (values, actions, outerActions, onClose) => {
      values = { ...values, phone: phoneToStr(values.phone) };

      return await api
        .post('/users/', values)
        .then(async () => {
          await api.get(`/users/?page_size=${values.pageSize}/`).then(({ data }) => {
            outerActions.setValues(data);
          });

          actions.setSubmitting();
          notify('Пользователь успешно создан');
          onClose();
        })
        .catch(({ response }) => {
          const setError = (name, code, text) => {
            if (response.data?.[name]?.[0]?.code === code) actions.setFieldError(name, text);
          };

          setError('email', 'unique', errors.emailIsUsed);
          setError('password', 'password_too_common', errors.passwordTooCommon);
        });
    },

    changeUsers: async (values, actions) => {
      actions.setValues(values);

      return await api.patch('/users/', values).then(({ data }) => {
        setState(data);
      });
    },

    setUsers: async (values, actions) => {
      actions.setValues(values);

      return await api.get('/users/').then(({ data }) => {
        setState(data);
      });
    },

    deleteUsers: async (id) => {
      return await api.delete(`/users/${id}/`).then(() => {
        notify('Пользователь успешно удален');
      });
    },

    editUsers: async (id, value) => {
      value = { ...value, phone: phoneToStr(value.phone) };

      return await api.patch(`/users/${id}/`, value);
    },
  };
};
