import React from 'react';
import { Formik } from 'formik';
import cn from 'classnames';

import BlockWrapper from '../BlockWrapper/BlockWrapper';

import styles from './ProfileBlockWrapper.module.css';

function ProfileBlockWrapper(props) {
  return (
    <BlockWrapper className={cn(styles.wrapper, props.className)}>
      <h2 className={styles.title}>{props.title}</h2>

      <Formik
        initialValues={props.initialValues}
        validationSchema={props.validationSchema}
        onSubmit={props.onSubmit}
        render={props.render}
      />
    </BlockWrapper>
  );
}

export default ProfileBlockWrapper;
