import * as Yup from 'yup';

import * as errorMessages from './error-messages';
import * as regExp from './regular-expressions';

// Auth
// -----------------------------------------------------------------------------
export function LoginSchema() {
  return Yup.object().shape({
    email: Yup.string().required(errorMessages.blank),
    password: Yup.string().required(errorMessages.blank),
  });
}

export function ResetPasswordSchema() {
  return Yup.object().shape({
    email: Yup.string().required(errorMessages.blank),
  });
}

export function ChangePasswordSchema() {
  return Yup.object().shape({
    confirmedPassword: Yup.string()
      .matches(regExp.enLetter, { message: errorMessages.passwordEntirelyAlpha })
      .matches(regExp.numeric, { message: errorMessages.passwordEntirelyNumeric }),
    newPassword: Yup.string()
      .matches(regExp.enLetter, { message: errorMessages.passwordEntirelyAlpha })
      .matches(regExp.numeric, { message: errorMessages.passwordEntirelyNumeric }),
  });
}

// Profile
// -----------------------------------------------------------------------------
export function ProfileInfoSchema() {
  return Yup.object().shape({
    fullName: Yup.string()
      .required(errorMessages.blank)
      .max(256, errorMessages.maxLength)
      .matches(regExp.multiLetter, { message: errorMessages.invalidInput }),
  });
}

export function ProfilePasswordSchema() {
  return Yup.object().shape({
    currentPassword: Yup.string().required(errorMessages.blank),
    newPassword: Yup.string()
      .required(errorMessages.blank)
      .matches(regExp.enLetter, { message: errorMessages.passwordEntirelyAlpha })
      .matches(regExp.numeric, { message: errorMessages.passwordEntirelyNumeric }),
    confirmedPassword: Yup.string()
      .required(errorMessages.blank)
      .matches(regExp.enLetter, { message: errorMessages.passwordEntirelyAlpha })
      .matches(regExp.numeric, { message: errorMessages.passwordEntirelyNumeric }),
  });
}

// Lamb
// -----------------------------------------------------------------------------
export function GoatsSchema() {
  return Yup.object().shape({
    // tag: Yup.string().required(errorMessages.blank),
    name: Yup.string().required(errorMessages.blank),
    status: Yup.string().required(errorMessages.selectBlank),
    gender: Yup.string().required(errorMessages.selectBlank),
    weighing: Yup.array().of(
      Yup.object().shape({
        date: Yup.string()
          .nullable()
          .required(errorMessages.dateBlank),
        weight: Yup.string().required(errorMessages.weightBlank),
      }),
    ),
  });
}

// EMR
// -----------------------------------------------------------------------------
export function EMRSchema() {
  return Yup.object().shape({
    vaccinations: Yup.array().of(
      Yup.object().shape({
        date: Yup.string()
          .nullable()
          .required(errorMessages.dateBlank),
        dosage: Yup.string().required(errorMessages.doseBlank),
        drugTitle: Yup.string().required(errorMessages.drugBlank),
      }),
    ),
    antiparasitics: Yup.array().of(
      Yup.object().shape({
        date: Yup.string()
          .nullable()
          .required(errorMessages.dateBlank),
        dosage: Yup.string().required(errorMessages.doseBlank),
        drugTitle: Yup.string().required(errorMessages.drugBlank),
      }),
    ),
    ills: Yup.array().of(
      Yup.object().shape({
        dateFrom: Yup.string()
          .nullable()
          .required(errorMessages.illStartBlank),
        dateTo: Yup.string()
          .nullable()
          .required(errorMessages.illEndBlank),
        title: Yup.string().required(errorMessages.drugBlank),
      }),
    ),
    matings: Yup.array().of(
      Yup.object().shape({
        date: Yup.string()
          .nullable()
          .required(errorMessages.dateBlank),
        nameMale: Yup.string().required(errorMessages.tagBlank),
        nameFemale: Yup.string().required(errorMessages.tagBlank),
      }),
    ),
    lambings: Yup.array().of(
      Yup.object().shape({
        date: Yup.string()
          .nullable()
          .required(errorMessages.dateBlank),
        maleQuantity: Yup.string()
          .nullable()
          .required(errorMessages.quantityBlank),
        femaleQuantity: Yup.string()
          .nullable()
          .required(errorMessages.quantityBlank),
      }),
    ),
  });
}

// Create users
// -----------------------------------------------------------------------------
export function CreateUsersSchema() {
  return Yup.object().shape({
    email: Yup.string().required(errorMessages.blank),
    password: Yup.string().required(errorMessages.blank),
    fullName: Yup.string()
      .required(errorMessages.blank)
      .max(256, errorMessages.maxLength),
  });
}

// Diseases modal
// -----------------------------------------------------------------------------
export function DiseasesModalSchema() {
  return Yup.object().shape({
    name: Yup.string().required(errorMessages.blank),
  });
}

// Researches
// -----------------------------------------------------------------------------
export function ResearchesSchema() {
  return Yup.object().shape({
    title: Yup.string().required(errorMessages.blank),
    goatName: Yup.string().required(errorMessages.blank),
  });
}
