import React from 'react';
import cn from 'classnames/bind';
import { Field } from 'formik';

import Sprite from '../../../../components/Sprite';

import styles from './FilterCheckbox.module.css';

const cx = cn.bind(styles);

function FilterCheckbox(props) {
  return (
    <>
      <Field className={styles.checkbox} name={props.name} type="checkbox" />

      <button
        type="button"
        onClick={() => props.actions.setFieldValue(props.name, !props.values[props.name])}
        className={cx({
          button: true,
          'button-selected': props.values[props.name],
        })}
      >
        {props.children}{' '}
        {props.values[props.name] && (
          <Sprite
            className={styles.icon}
            name="close-bold"
            stroke="var(--white)"
            fill="white"
            role="img"
          />
        )}
      </button>
    </>
  );
}

export default FilterCheckbox;
