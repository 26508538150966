import React from 'react';
import cn from 'classnames/bind';
import { Link } from 'react-router-dom';

import styles from './NotificationItem.module.css';
import { notificationDateInfo } from '../../../../utils/dates';

const cx = cn.bind(styles);

const getFirstChar = (str) => str && str.charAt(0).toUpperCase();

const typeSwitcher = (type) => {
  switch (type) {
    case 'create':
      return 'добавил';
    case 'update':
      return 'внес изменения';
    case 'delete':
      return 'удалил';
    case 'comment':
      return 'оставил комментарий';
    default:
      return '';
  }
};

const targetSwitcher = (target, type) => {
  switch (target) {
    case 'emr':
      return 'ЭМК';
    case 'goat':
      return type === 'comment' ? 'к карточке козы' : 'карточку козы';
    case 'log':
      return type === 'comment' ? 'к журналу' : 'журнал';
    case 'research':
      return type === 'comment' ? 'к лабораторному исследованию' : 'лабораторное исследование';
    case 'ill':
      return 'болезнь в ЭМК';
    default:
      return '';
  }
};

const linkSwitcher = (link, type) => {
  switch (type) {
    case 'emr':
      return `/electronic-medical-record/${link}`;
    case 'goat':
      return `/goat/${link}`;
    case 'log':
      return `/log/${link}`;
    case 'research':
      return `/laboratory-research/${link}`;
    case 'ill':
      return `/electronic-medical-record/${link}`;
    default:
      return '';
  }
};

const nameSwitcher = (type, name) => (type === 'emr' || type === 'ill' ? `№ ${name}` : name);
const transformDate = (date) => notificationDateInfo(date);

function NotificationItem(props) {
  return (
    <div className={cx({ wrapper: true, 'wrapper-unread': !props.isView }, props.className)}>
      <div className={cx({ avatar: true })}>
        <span className={cx({ char: true })}>{getFirstChar(props.authorName)}</span>
      </div>

      <div className={cx({ content: true })}>
        <p className={cx({ title: true })}>
          Пользователь <span className={cx({ name: true })}>{props.authorName}</span>{' '}
          {typeSwitcher(props.actionType)} {targetSwitcher(props.type, props.actionType)}{' '}
          {props.objectId && !props.deleted ? (
            <Link className={cx({ link: true })} to={linkSwitcher(props.objectId, props.type)}>
              {nameSwitcher(props.type, props.name)}
            </Link>
          ) : (
            props.name
          )}
        </p>

        <p className={cx({ date: true })}>{transformDate(props.created)}</p>
      </div>
    </div>
  );
}

export default NotificationItem;
