import React from 'react';

import Sprite from '../../../../components/Sprite';

import styles from './ChangeAvatarButton.module.css';

function ChangeAvatarButton(props) {
  return (
    <>
      <input
        className={styles.input}
        id="avatar"
        type="file"
        ref={props.fileInput}
        props={props.onChange}
        accept=".jpg,.jpeg,.png"
      />

      <button type="button" className={styles.btn}>
        <label htmlFor="avatar" className={styles.label}>
          <Sprite name="camera-1" className={styles.icon} role="img" /> Изменить
        </label>
      </button>
    </>
  );
}

export default ChangeAvatarButton;
