import React from 'react';
import cn from 'classnames/bind';

import Spinner from '../Spinner';

import styles from './SubmitButton.module.css';

const cx = cn.bind(styles);

function SubmitButton(props) {
  return (
    <button
      className={cx(
        {
          btn: true,
          submitting: props.isSubmitting,
        },
        props.className,
      )}
      type="submit"
      disabled={props.isSubmitting}
    >
      {props.isSubmitting ? <Spinner className={styles.img} /> : props.children}
    </button>
  );
}

export default SubmitButton;
