import api from '../utils/api';
import { convertFormDates } from '../utils/dates';

// const isObjValuesEmpty = (obj) =>
//   Object.values(obj).every((value) => value === null || value === '');
// const removeEmptyObjFromArr = (arr) => arr.filter((obj) => !isObjValuesEmpty(obj));

const isObjValuesEmpty = (obj) =>
  Object.values(obj).every((value) => value === null || value === '');

const removeEmptyObjFromArr = (arr, isCeil) => {
  let newArr = arr;

  if (isCeil) {
    for (const obj of arr) {
      if (typeof obj !== 'object') continue;

      for (const k in obj) {
        if (!obj.hasOwnProperty(k)) continue;

        const v = obj[k];

        if (v === '') {
          obj[k] = null;
        }
      }
    }
  }

  return newArr.filter((obj) => !isObjValuesEmpty(obj));
};

export default {
  get(uuid) {
    return api.get(`/emr/${uuid}/`);
  },

  create(values, goatId) {
    values = {
      ...values,
      vaccinations: removeEmptyObjFromArr(values.vaccinations),
      antiparasitics: removeEmptyObjFromArr(values.antiparasitics),
      ills: removeEmptyObjFromArr(values.ills),
      matings: removeEmptyObjFromArr(values.matings),
      lambings: removeEmptyObjFromArr(values.lambings, true),
      feed: removeEmptyObjFromArr(values.feed),
      goatId
    };

    return api.post('/emr/', convertFormDates(values, ['date', 'dateTo', 'dateFrom']));
  },

  edit(uuid, values) {
    values = {
      ...values,
      vaccinations: removeEmptyObjFromArr(values.vaccinations),
      antiparasitics: removeEmptyObjFromArr(values.antiparasitics),
      ills: removeEmptyObjFromArr(values.ills),
      matings: removeEmptyObjFromArr(values.matings),
      lambings: removeEmptyObjFromArr(values.lambings, true),
      feed: removeEmptyObjFromArr(values.feed),
    };

    return api.patch(`/emr/${uuid}/`, convertFormDates(values, ['date', 'dateTo', 'dateFrom']));
  },

  remove(uuid) {
    return api.delete(`/emr/${uuid}/`);
  },
};
