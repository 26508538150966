export const isUser = (role) => role === 'user';
export const isAdmin = (role) => role === 'admin';
export const isModer = (role) => role === 'moderator';
export const isObserver = (role) => role === 'observer';

export const isAdminOrModer = (role) => {
  switch (role) {
    case 'admin':
      return true;
    case 'moderator':
      return true;
    default:
      return false;
  }
};
