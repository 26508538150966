import React from 'react';
import styles from './StatisticsBlock.module.css';
function StatisticsBlock({
  title,
  totalGoats,
  totalFemales,
  totalMales,
  totalKids6m,
  totalKids1y,
}){
  return (
    <div className={styles.block}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.wrapper}>
            <div className={styles.inner}>
                <h2 className={styles.subtitle}>
                  Всего коз: <span>{totalGoats}</span>
                </h2>
                <h3 className={styles.point}>
                  Всего козлов: <span>{totalMales}</span>
                </h3>
                <h3 className={styles.point}>
                  Всего самок: <span>{totalFemales}</span>
                </h3>
                <h3 className={styles.point}>
                  Всего козлят (до 6 мес): <span>{totalKids6m}</span>
                </h3>
                <h3 className={styles.point}>
                  Всего козлят (до 1 года): <span>{totalKids1y}</span>
                </h3>
            </div>
        </div>
    </div>
  );
};
export default StatisticsBlock;