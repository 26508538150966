import React from 'react';

import styles from './PageSizeSelect.module.css';

import CustomSelect from '../CustomSelect/CustomSelect';

const selectStyles = {
  backgroundColor: 'var(--white)',
  border: '1px solid #cddbe4',
  borderHover: '1px solid #b0c9d3',
};

const options = [{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 50, label: 50 }];

function PageSizeSelect(props) {
  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>Выводить по</p>

      <CustomSelect
        className={styles.select}
        name={props.name}
        onBlur={props.setFieldTouched}
        onChange={({ value }) =>
          props.onChange({ name: props.name, value, setFieldValue: props.setFieldValue })
        }
        value={props.values[props.name]}
        error={props.errors[props.name]}
        touched={props.touched[props.name]}
        options={options}
        styles={selectStyles}
        validate={props.validate}
        defaultValue={[{ value: 20, label: 20 }]}
        menuPortalTarget={props.menuPortalTarget}
        menuPlacement="top"
      />
    </div>
  );
}

export default PageSizeSelect;
