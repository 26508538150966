import React from 'react';
import cn from 'classnames/bind';
import { Formik, Form } from 'formik';

import Container from '../../components/Container/Container';
import LogsHeader from '../../components/LogsHeader/LogsHeader';
import LogsSearchTable from '../../components/LogsSearchTable/LogsSearchTable';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import PageSizeSelect from '../../components/PageSizeSelect/PageSizeSelect';

import useLogs from '../../../../hooks/api/use-logs';
import styles from './Logs.module.css';
import { isObjContains, getPageCount } from '../../../../utils/helpers';

const cx = cn.bind(styles);

function Logs(props) {
  const initialValues = {
    serialNumber: '',
    title: '',
    modified: '',
    created: '',
    orderBy: '',
    page: '1',
    pageSize: '20',
  };

  const { logs, setLogs } = useLogs();
  const isFilterActive = (values) => {
    if (isObjContains(values, ['serialNumber', 'title', 'modified', 'created'])) return true;
    else return false;
  };

  function handleSubmit(values, actions) {
    setLogs(values, actions);
  }

  function handlePageClick(data, values, actions) {
    setLogs({ ...values, page: data.selected + 1 }, actions);
  }

  function handleSelectChange({ name, value, setFieldValue }, values, actions) {
    setFieldValue(name, value);
    setLogs({ ...values, pageSize: value }, actions);
  }

  return (
    <Container className={styles.container} wrapperClassName={styles.wrapper}>
      <LogsHeader className={styles.header} logs={logs} />

      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        render={({ values, ...actions }) => (
          <Form className={styles.form} noValidate>
            {logs?.results?.[0] || isFilterActive(values) ? (
              <LogsSearchTable
                logs={logs}
                history={props.history}
                values={values}
                actions={actions}
                onFilter={handleSubmit}
              >
                <>
                  <div>
                    {logs.results.length ? (
                      <CustomPagination
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        pageCount={getPageCount(logs.count, values.pageSize)}
                        onPageChange={(data) => handlePageClick(data, values, actions)}
                      />
                    ) : null}
                  </div>

                  <PageSizeSelect
                    name="pageSize"
                    values={values}
                    onChange={(e) => handleSelectChange(e, values, actions)}
                    {...actions}
                  />
                </>
              </LogsSearchTable>
            ) : (
              <p className={cx({ 'empty-results': true })}>Список журналов пуст</p>
            )}
          </Form>
        )}
      />
    </Container>
  );
}

export default Logs;
