import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

let composedEnhancers = null;

if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
} else {
  composedEnhancers = compose;
}

const middlewares = [thunk];

const store = createStore(rootReducer, composedEnhancers(applyMiddleware(...middlewares)));

export default store;
