import React from 'react';
import cn from 'classnames/bind';

import SearchTable from '../../components/SearchTable/SearchTable';
import SearchTableHead from '../../components/SearchTableHead/SearchTableHead';
import SearchTableHeadCell from '../../components/SearchTableHeadCell/SearchTableHeadCell';
import SearchTableBody from '../../components/SearchTableBody/SearchTableBody';
import SearchTableBodyCell from '../../components/SearchTableBodyCell/SearchTableBodyCell';
import DatepickerTableHeadCell from '../../components/DatepickerTableHeadCell/DatepickerTableHeadCell';
import Sprite from '../../../../components/Sprite';

import styles from './EMRSearchTable.module.css';
import { convertDate } from '../../../../utils/helpers';

const cx = cn.bind(styles);

function EMRSearchTable(props) {
  function handleRowClick(id) {
    props.history.push(`/electronic-medical-record/${id}`);
  }

  function handleOrderClick(name, values, actions) {
    if (values.orderBy === name) {
      actions.setFieldValue('orderBy', `-${name}`);
      props.onFilter({ ...values, orderBy: `-${name}` }, actions);
    } else {
      actions.setFieldValue('orderBy', name);
      props.onFilter({ ...values, orderBy: name }, actions);
    }
  }

  return (
    <section className={styles.wrapper}>
      <SearchTable>
        <SearchTableHead rowClassName={cx({ 'table-row': true })}>
          <SearchTableHeadCell
            className={cx({ 'table-cell': true })}
            name="id"
            icon="magnifier"
            actions={props.actions}
          >
            <button
              type="button"
              icon="magnifier"
              onClick={() => handleOrderClick('id', props.values, props.actions)}
            >
              Номер ЭМК
              <Sprite
                name="sort-arrow"
                role="img"
                className={cx({ arrow: true, 'arrow-ordered': props.values.orderBy === '-id' })}
              />
            </button>
          </SearchTableHeadCell>

          <SearchTableHeadCell
            className={cx({ 'table-cell': true })}
            name="goatName"
            icon="magnifier"
            actions={props.actions}
          >
            Кличка
          </SearchTableHeadCell>

          <SearchTableHeadCell
            className={cx({ 'table-cell': true })}
            name="goatTag"
            icon="magnifier"
            actions={props.actions}
          >
            Номер бирки
          </SearchTableHeadCell>

          <DatepickerTableHeadCell
            className={cx({ 'table-cell': true })}
            name="modified"
            icon="calendar"
            actions={props.actions}
            value={props.values.modified}
          >
            Обновлено
          </DatepickerTableHeadCell>
        </SearchTableHead>

        <SearchTableBody results={props.emr.results.length}>
          {props.emr.results.map(({ id, goat, modified }) => {
            modified = convertDate(modified, 'd.LL.yyyy HH:mm');

            return (
              <tr
                key={id}
                onClick={() => handleRowClick(id)}
                className={cx({ 'table-row': true, 'table-body-row': true })}
              >
                <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                  {id}
                </SearchTableBodyCell>

                <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                  {goat?.name}
                </SearchTableBodyCell>

                <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                  {goat?.tag}
                </SearchTableBodyCell>

                <SearchTableBodyCell className={cx({ 'table-cell': true })}>
                  {modified}
                </SearchTableBodyCell>
              </tr>
            );
          })}
        </SearchTableBody>
      </SearchTable>

      <div className={styles.footer}>{props.children}</div>
    </section>
  );
}

export default EMRSearchTable;
