import React from 'react';
import cn from 'classnames';

import styles from './SearchTableBody.module.css';

function SearchTableBody(props) {
  return (
    <tbody className={cn(styles.body)}>
      {props.results ? (
        props.children
      ) : (
        <tr>
          <td className={cn(styles.cell)}>Результаты не найдены</td>
        </tr>
      )}
    </tbody>
  );
}

export default SearchTableBody;
