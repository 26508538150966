import React from 'react';
import cn from 'classnames/bind';
import Media from 'react-media';
import { useWindowSize } from '@react-hook/window-size';

import FilterCheckbox from '../FilterCheckbox/FilterCheckbox';
import FieldDatePicker from '../../../../components/FieldDatePicker/FieldDatePicker';

import styles from './HeadFilters.module.css';
import Sprite from '../../../../components/Sprite';

const cx = cn.bind(styles);

function HeadFilters(props) {
  const [width] = useWindowSize(768);

  function handleClearButton() {
    const values = props.values;

    props.actions.setValues({
      ...values,
      ills: false,
      vaccinations: false,
      lambings: false,
      matings: false,
      modifierFrom: '',
      modifierTo: '',
    });

    props.setState(
      {
        ...values,
        ills: false,
        vaccinations: false,
        lambings: false,
        matings: false,
        modifierFrom: '',
        modifierTo: '',
      },
      props.actions,
    );
  }

  return (
    <>
      <h3 className={cx({ 'filter-title': true })}>Фильтровать по</h3>

      <ul className={cx({ 'filter-list': true })}>
        <li className={cx({ 'filter-list-item': true })}>
          <FilterCheckbox name="ills" values={props.values} actions={props.actions}>
            Болезнь
          </FilterCheckbox>
        </li>

        <li className={cx({ 'filter-list-item': true })}>
          <FilterCheckbox name="vaccinations" values={props.values} actions={props.actions}>
            Вакцинация
          </FilterCheckbox>
        </li>

        <li className={cx({ 'filter-list-item': true })}>
          <FilterCheckbox name="lambings" values={props.values} actions={props.actions}>
            Окот
          </FilterCheckbox>
        </li>

        <li className={cx({ 'filter-list-item': true })}>
          <FilterCheckbox name="matings" values={props.values} actions={props.actions}>
            Случка
          </FilterCheckbox>
        </li>
      </ul>

      <h3 className={cx({ 'filter-title': true })}>Интервал</h3>

      <div className={styles.fields}>
        <FieldDatePicker
          name="modifierFrom"
          onChange={props.actions.setFieldValue}
          value={props.values.modifierFrom}
          className={styles.field}
          iconClassName={cx({ 'field-icon': true })}
          icon="calendar"
          selectedMaxDate={props.values.modifierTo}
          actions={props.actions}
          static
        />

        <Media query="(min-width: 992px)">
          <span className={styles.separator} />
        </Media>

        <FieldDatePicker
          name="modifierTo"
          onChange={props.actions.setFieldValue}
          value={props.values.modifierTo}
          className={styles.field}
          iconClassName={cx({ 'field-icon': true })}
          icon="calendar"
          selectedMinDate={props.values.modifierFrom}
          actions={props.actions}
          static
          popperPlacement={width <= 767 ? 'bottom-end' : 'bottom-start'}
        />
      </div>

      <div className={styles.buttons}>
        <button className={cx({ button: true, 'button-submit': true })} type="submit">
          Применить
        </button>

        <button
          onClick={handleClearButton}
          className={cx({ button: true, 'button-clear': true })}
          type="button"
        >
          <Sprite className={cx({ 'icon-clear': true })} name="clear" role="img" />
          Очистить
        </button>
      </div>
    </>
  );
}

export default HeadFilters;
