function stripTrailingSlash(str) {
  return str.endsWith('/') ? str.slice(0, -1) : str;
}

function getKeys(url, position) {
  return stripTrailingSlash(url)
    .split('/')
    .slice(position);
}

export function getUID(url) {
  const keys = getKeys(url, -2);

  return keys[0];
}

export function getToken(url) {
  const keys = getKeys(url, -2);

  return keys[1];
}

export const getCardUUID = (url) => {
  const keys = getKeys(url, -1);

  return keys?.[0];
};

export const getKeysFromUrl = (url, pos) => getKeys(url, pos);
