import React from 'react';
import cn from 'classnames/bind';
import { Formik } from 'formik';

import CardHeader from '../../components/CardHeader/CardHeader';
import ActionButton from '../../components/ActionButton/ActionButton';
import CommentsBlock from '../../components/CommentsBlock/CommentsBlock';
import ActionModal from '../../components/ActionModal/ActionModal';
import ResearchesInfo from '../../components/ResearchesInfo/ResearchesInfo';
import ResearchMultimedia from '../../components/ResearchMultimedia/ResearchMultimedia';

import styles from './LaboratoryResearch.module.css';
import useScrollLock from '../../../../hooks/use-scroll-lock';
import research from '../../../../api/research';
import { ResearchesSchema } from '../../../../utils/validations';
import { notify } from '../../../../components/Toast/Toast';
import { getCardUUID, getKeysFromUrl } from '../../../../utils/url-parser';
import { convertFormDates } from '../../../../utils/dates';
import { isObjContains } from '../../../../utils/helpers';

const cx = cn.bind(styles);

function checkCreateUrl(url) {
  const [value] = getKeysFromUrl(url, 2);
  return value === 'create';
}

function LaboratoryResearch(props) {
  const url = props.location.pathname;
  const uuid = getCardUUID(url);
  const isCreate = checkCreateUrl(url);
  const { targetRef, targetElement, enableBodyScroll } = useScrollLock();

  const [initialValues, setInitialValues] = React.useState({
    date: '',
    title: '',
    species: '',
    goatName: '',
    goatChip: '',
    conclusion: '',
    img: null,
    imgId: null,
    video: null,
    videoId: null,
  });

  React.useEffect(() => {
    if (!isCreate) {
      research
        .get(uuid)
        .then(({ data }) => {
          setInitialValues(convertFormDates(data, ['date']));
        })
        .catch(({ response }) => {
          if (response.status) props.history.push('/laboratory-researches');
        });
    }
  }, [isCreate, props.history, uuid]);

  const [isEdit, setIsEdit] = React.useState(isCreate);
  const [isCloseModalOpen, setIsCloseModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const handleSubmitForm = (values, { handleSubmit, setSubmitting }) => (e) => {
    e.preventDefault();
    handleSubmit();

    const contains = isObjContains(values, ['title', 'goatName']);

    if (!contains) notify('Пожалуйста, заполните необходимые поля');

    if (isEdit && !isCreate) {
      return research.edit(uuid, values).then(() => {
        props.history.push('/laboratory-researches');
        setSubmitting(false);
      });
    }

    if (isCreate) {
      return research.create(values).then(() => {
        setSubmitting(false);
        props.history.push('/laboratory-researches');
      });
    }
  };

  function handleRemoveCard() {
    research.remove(uuid).then(() => props.history.push('/laboratory-researches'));
    enableBodyScroll(targetElement);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={ResearchesSchema}
        enableReinitialize
        render={({ values, errors, ...actions }) => {
          return (
            <div className={styles.wrapper}>
              <div className={styles.container}>
                <form
                  noValidate
                  className={cx({ form: true, 'form-create': isCreate })}
                  onSubmit={handleSubmitForm(values, actions)}
                >
                  <CardHeader
                    className={styles.head}
                    title={values.title ? values.title : 'Новое лабораторное исследование'}
                    actions={
                      <>
                        {(isCreate || isEdit) && (
                          <ActionButton
                            className={cx({ 'btn-save': true })}
                            role="button"
                            icon="check-bold"
                            type="submit"
                          >
                            Сохранить
                          </ActionButton>
                        )}

                        {(isCreate || isEdit) && (
                          <ActionButton
                            className={cx({ 'btn-cancel': true })}
                            role="button"
                            icon="close-bold"
                            type="button"
                            fill="#0c1116"
                            onClick={() => setIsCloseModalOpen(true)}
                          >
                            Отменить
                          </ActionButton>
                        )}

                        {!isCreate && !isEdit && (
                          <ActionButton
                            className={cx({ 'btn-edit': true })}
                            role="button"
                            icon="pen"
                            type="button"
                            onClick={() => setIsEdit(true)}
                          >
                            Редактировать
                          </ActionButton>
                        )}

                        {!isCreate && !isEdit && (
                          <ActionButton
                            className={cx({ 'btn-remove': true })}
                            role="button"
                            icon="trash"
                            type="button"
                            onClick={() => setIsDeleteModalOpen(true)}
                          >
                            Удалить
                          </ActionButton>
                        )}
                      </>
                    }
                  />

                  <ResearchMultimedia
                    values={values}
                    errors={errors}
                    actions={actions}
                    isCreate={isCreate}
                    isEdit={isEdit}
                    uuid={uuid}
                  />

                  <ResearchesInfo
                    values={values}
                    errors={errors}
                    actions={actions}
                    isEdit={isEdit}
                  />
                </form>
              </div>

              {!isCreate && <CommentsBlock uuid={uuid} page="research" />}
            </div>
          );
        }}
      />

      <ActionModal
        isOpen={isCloseModalOpen}
        title="Закрыть документ?"
        btnAcceptText="Закрыть"
        btnDeclineText="Продолжить редактирование"
        onClose={() => {
          enableBodyScroll(targetElement);
          setIsCloseModalOpen(false);
        }}
        onAccept={() => {
          props.history.push('/laboratory-researches');
          enableBodyScroll(targetElement);
        }}
        ref={targetRef}
        target={targetElement}
      >
        Если вы закроете документ, внесенные изменения не сохранятся
      </ActionModal>

      <ActionModal
        isOpen={isDeleteModalOpen}
        title="Вы действительно хотите удалить выбранную карточку?"
        btnAcceptText="Удалить"
        btnDeclineText="Отмена"
        btnAcceptDanger
        onClose={() => {
          enableBodyScroll(targetElement);
          setIsDeleteModalOpen(false);
        }}
        onAccept={handleRemoveCard}
        ref={targetRef}
        target={targetElement}
      />
    </>
  );
}

export default LaboratoryResearch;
