import React from 'react';
import Modal from 'react-modal';
import MaskedInput from 'react-text-mask';
import Textarea from 'react-textarea-autosize';
import useSWR from '@zeit/swr';
import cn from 'classnames/bind';
import { Formik, Field } from 'formik';
import { DateTime } from 'luxon';

import Sprite from '../../../../components/Sprite';
import ButtonClose from '../../../../components/ButtonClose/ButtonClose';
import ActionModal from '../ActionModal/ActionModal';

import api from '../../../../utils/api';
import styles from './DiseasesModal.module.css';
import axios from '../../../../utils/axios';
import useScrollLock from '../../../../hooks/use-scroll-lock';
import { notify } from '../../../../components/Toast/Toast';
import { convertObjDate, transformTimeDate } from '../../../../utils/dates';
import { DiseasesModalSchema } from '../../../../utils/validations';
import { isAdminOrModer } from '../../../../utils/user-role';

const cx = cn.bind(styles);

Modal.setAppElement('#root');

const checkObjFieldFilled = (obj) => {
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(obj)) {
    if (value !== null && value !== '') return true;
  }
};

function DiseasesModal(props) {
  const { data: user } = useSWR('/users/me/', axios);
  const isPersonal = isAdminOrModer(user?.data?.role);
  const [initialValues, setInitialValues] = React.useState({
    name: '',
    date: null,
    clinicalSigns: '',
    diagnosis: '',
    therapy: '',
    recommendations: '',
    conclusion: '',
    modified: null,
  });

  const uuid = props.uuid;
  const illId = props.values.ills?.[props.index]?.id;

  React.useEffect(() => {
    if (!props.isCreate && props.index !== null && illId) {
      api.get(`/emr/${uuid}/ills/${illId}/`).then((value) => {
        setInitialValues(convertObjDate(value.data, 'res'));
      });
    } else {
      if (props.isCreate && props.values.ills[props.index]) {
        setInitialValues(props.values.ills[props.index]);
      } else {
        setInitialValues({
          name: '',
          date: null,
          clinicalSigns: '',
          diagnosis: '',
          conclusion: '',
          recommendations: '',
          modified: null,
        });
      }
    }
  }, [props.index, props.isCreate, props.uuid, props.values.ills, illId, uuid]);

  const [isEditableModalOpen, setIsEditableModalOpen] = React.useState(false);
  const toggleModalEditable = () => setIsEditableModalOpen(!isEditableModalOpen);

  const [isCloseModalOpen, setIsCloseModalOpen] = React.useState(false);
  const toggleModalClose = () => setIsCloseModalOpen(!isCloseModalOpen);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const toggleModalDelete = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const { targetRef, targetElement, disableBodyScroll, clearAllBodyScrollLocks } = useScrollLock();

  // main modal handle (editable mode)
  function handleEditableModalClose() {
    // props.onClose();
    setIsEditableModalOpen(false);
    // clearAllBodyScrollLocks();
    // props.setIndex(null);
  }

  function handleModalClose() {
    props.onClose();
    setIsEditableModalOpen(false);
    clearAllBodyScrollLocks();
    props.setIndex(null);
  }

  // diseases modal handles
  function handleDiseasesModalEdit() {
    toggleModalEditable();
  }

  function handleDiseasesModalDelete() {
    toggleModalDelete();
  }

  function handleDiseasesModalSave(values) {
    const { setFieldValue } = props.actions;
    const isFilled = checkObjFieldFilled(values);

    if (isFilled) {
      if (props.isCreate) {
        const ills = props.values.ills;

        if (props.values.ills?.[props.index]) {
          const newIlls = props.values.ills.map((ill, index) => {
            if (index === props.index) {
              ill = {
                ...values,
                date: transformTimeDate(values.date, 'ymd'),
                modified: DateTime.local().toFormat('dd.LL.yyyy HH:mm'),
              };
            }

            return ill;
          });

          setFieldValue('ills', newIlls);
        } else {
          setFieldValue('ills', [
            {
              ...values,
              date: transformTimeDate(values.date, 'ymd'),
              modified: DateTime.local().toFormat('dd.LL.yyyy HH:mm'),
            },
            ...ills,
          ]);
        }

        notify('Сведения о болезни успешно сохранены');
      } else {
        if (props.index === null || props.index === '-1') {
          api.post(`/emr/${uuid}/ills/`, convertObjDate(values, 'req')).then(({ data }) => {
            setFieldValue('ills', [data, ...props.values.ills]);
            notify('Сведения о болезни успешно сохранены');
          });
        } else {
          api.patch(`/emr/${uuid}/ills/${illId}/`, convertObjDate(values, 'req')).then(() => {
            api
              .get(`/emr/${uuid}/ills/`)
              .then(({ data }) => setFieldValue('ills', convertObjDate(data, 'res')));
            notify('Изменения успешно сохранены');
          });
        }
      }
    }

    if (props.index === '-1') handleModalClose();
    else handleEditableModalClose();
  }

  function handleDiseasesModalCancel(values) {
    if (checkObjFieldFilled(values)) {
      toggleModalClose();
    } else {
      if (props.index === '-1') handleModalClose();
      else handleEditableModalClose();
    }
  }

  // close modal handle
  function handleCloseModalAccept() {
    toggleModalClose();
    handleEditableModalClose();
  }

  // delete modal handle
  function handleDeleteModalAccept() {
    const { setFieldValue } = props.actions;

    if (props.index !== null) {
      api.delete(`/emr/${uuid}/ills/${illId}/`).then(() => {
        api.get(`/emr/${uuid}/ills/`).then(({ data }) => setFieldValue('ills', data));

        toggleModalDelete();
        handleModalClose();
        notify('Сведения о болезни успешно удалены');
      });
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={handleModalClose}
      onAfterOpen={() => disableBodyScroll(targetElement)}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={300}
      className={cx({ modal: true })}
      overlayClassName={cx({ overlay: true })}
      ref={targetRef}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={DiseasesModalSchema}
        enableReinitialize
        render={({ values, ...actions }) => (
          <>
            <section className={cx({ wrapper: true })}>
              <ButtonClose
                onClick={handleModalClose}
                className={cx({ 'btn-close': true })}
                stroke="var(--dark-gray)"
              />

              <h3 className={cx({ title: true })}>Лечебный процесс</h3>

              <ModalContent
                index={props.index}
                isEdit={props.index === '-1' || isEditableModalOpen}
                isCreate={props.isCreate}
                outerValues={props.values}
                values={values}
              />

              {isPersonal && (
                <ModalButtons
                  isEdit={props.index === '-1' || isEditableModalOpen}
                  onEdit={handleDiseasesModalEdit}
                  onDelete={handleDiseasesModalDelete}
                  onSave={() => handleDiseasesModalSave(values)}
                  onCancel={() => handleDiseasesModalCancel(values)}
                />
              )}
            </section>

            <ActionModal
              isOpen={isCloseModalOpen}
              onAccept={handleCloseModalAccept}
              onClose={toggleModalClose}
              title="Закрыть документ?"
              btnAcceptText="Закрыть"
              btnDeclineText="Продолжить редактирование"
            >
              Если вы закроете документ, внесенные изменения не сохранятся.
            </ActionModal>

            <ActionModal
              isOpen={isDeleteModalOpen}
              onAccept={handleDeleteModalAccept}
              onClose={toggleModalDelete}
              title="Вы действительно хотите удалить выбранную болезнь?"
              btnAcceptText="Удалить"
              btnDeclineText="Отмена"
              btnAcceptDanger
            />
          </>
        )}
      />
    </Modal>
  );
}

function ModalContent(props) {
  const mask = [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ':',
    /\d/,
    /\d/,
  ];

  return (
    <>
      <FieldWrapper
        title="Название"
        isEdit={props.isEdit}
        values={props.values}
        name={props.values.name}
      >
        <ModalField name="name" index={props.index} mask={false} maxLength="128" />
      </FieldWrapper>

      <FieldWrapper
        title="Дата приема"
        isEdit={props.isEdit}
        values={props.values}
        name={props.isCreate ? transformTimeDate(props.values.date, 'dmyt', 0) : props.values.date}
      >
        <ModalField name="date" index={props.index} mask={mask} guide />
      </FieldWrapper>

      <FieldWrapper
        title="Клинические признаки"
        isEdit={props.isEdit}
        values={props.values}
        name={props.values.clinicalSigns}
      >
        <ModalTextArea name="clinicalSigns" index={props.index} maxLength="256" />
      </FieldWrapper>

      <FieldWrapper
        title="Диагноз"
        isEdit={props.isEdit}
        values={props.values}
        name={props.values.diagnosis}
      >
        <ModalTextArea name="diagnosis" index={props.index} maxLength="128" />
      </FieldWrapper>

      {/* <FieldWrapper
        title="Заключение"
        isEdit={props.isEdit}
        values={props.values}
        name={props.values.conclusion}
      >
        <ModalTextArea name="conclusion" index={props.index} maxLength="256" />
      </FieldWrapper> */}

      <FieldWrapper
        title="Лечение"
        isEdit={props.isEdit}
        values={props.values}
        name={props.values.therapy}
      >
        <ModalTextArea name="therapy" index={props.index} maxLength="256" />
      </FieldWrapper>

      <FieldWrapper
        title="Рекомендации"
        isEdit={props.isEdit}
        values={props.values}
        name={props.values.recommendations}
      >
        <ModalTextArea name="recommendations" index={props.index} maxLength="256" />
      </FieldWrapper>

      <FieldWrapper
        title="Исход болезни"
        isEdit={props.isEdit}
        values={props.values}
        name={props.values.conclusion}
      >
        <ModalTextArea name="conclusion" index={props.index} maxLength="256" />
      </FieldWrapper>
    </>
  );
}

function FieldWrapper(props) {
  return (
    <div className={cx({ 'contet-row': true, 'contet-row-editable': props.isEdit })}>
      <p className={cx({ 'field-title': true, 'field-title-editable': props.isEdit })}>
        {props.title}
      </p>

      <div className={cx({ 'field-wrapper': true })}>
        {props.isEdit ? props.children : <p className={cx({ 'field-text': true })}>{props.name}</p>}
      </div>
    </div>
  );
}

function ModalField(props) {
  return (
    <Field
      name={props.name}
      render={({ field }) => (
        <MaskedInput
          {...field}
          mask={props.mask}
          keepCharPositions
          guide={props.guide}
          className={cx({ 'modal-field': true })}
          placeholder={props.placeholder}
          type="text"
          maxLength={props.maxLength}
        />
      )}
    />
  );
}

function ModalTextArea(props) {
  return (
    <Field
      name={props.name}
      render={({ field }) => (
        <Textarea
          className={cx({ 'modal-textarea': true })}
          {...field}
          maxLength={props.maxLength}
        />
      )}
    />
  );
}

function ModalButton(props) {
  return (
    <button
      className={cx({ 'modal-button': true, [`button-${props.icon}`]: !!props.icon })}
      type="button"
      onClick={props.onClick}
    >
      <Sprite fill={props.fill} stroke={props.stroke} role="img" name={props.icon} />{' '}
      <p className={cx({ 'button-text': true })}>{props.children}</p>
    </button>
  );
}

function ModalButtons(props) {
  return (
    <div className={cx({ buttons: true })}>
      {!props.isEdit ? (
        <>
          <ModalButton onClick={props.onEdit} icon="pen">
            Редактировать
          </ModalButton>
          <ModalButton onClick={props.onDelete} icon="trash">
            Удалить
          </ModalButton>
        </>
      ) : (
        <>
          <ModalButton onClick={props.onSave} icon="check-bold">
            Сохранить
          </ModalButton>
          <ModalButton onClick={props.onCancel} icon="close-bold">
            Отменить
          </ModalButton>
        </>
      )}
    </div>
  );
}

export default DiseasesModal;
