import React from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

function useScrollLock() {
  const targetRef = React.createRef();
  const [targetElement, setTargetElement] = React.useState(null);

  React.useEffect(() => {
    setTargetElement(targetRef.current);
  }, [targetRef]);

  return {
    targetRef,
    targetElement,
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
  };
}

export default useScrollLock;
