import React, { useState } from 'react';
import cn from 'classnames/bind';
import { Field } from 'formik';

import ActionButton from '../../components/ActionButton/ActionButton';
import Sprite from '../../../../components/Sprite';
import {AvatarLoader} from '../../../../components/AvatarLoader/AvatarLoader'

import styles from './CardPhoto.module.css';
import * as errorMessages from '../../../../utils/error-messages';
import api, { files } from '../../../../utils/api';

const cx = cn.bind(styles);

function FileField(props) {
  const MAX_IMAGE_SIZE = 1e7;
  const [isLoading, setIsLoading] = useState(false)

  function handleImageChange(e) {
    e.preventDefault();

    const formData = new FormData();
    const file = e.target.files[0];
    const currentDate = new Date().toISOString()

    if (!file?.name.match(/.(jpe?g|png)$/i)) {
      props.setError(errorMessages.invalidImage);

      return;
    }

    if (file?.size >= MAX_IMAGE_SIZE) {
      props.setError(errorMessages.largeFile);

      return;
    }

    formData.append('source', file);
    formData.append('created', currentDate)
    props.setError('');
    if (props.isCreate) {
      files.images(formData).then(({ data }) => {
        props.setFieldValue('avatarId', data.id);
        props.setFieldValue('avatar', data);
      });
    }

    if (!props.isCreate) {
      setIsLoading(true)
      files.images(formData)
      .then(({ data }) => {
        setIsLoading(false)
        props.setFieldValue('avatarId', data.id);
        props.setFieldValue('avatar', data);
      });
    }
  }

  return (
    <>
      <input
        id="image-upload"
        type="file"
        name={props.field.name}
        onChange={handleImageChange}
        className={cx({ 'file-input': true })}
      />

      <button type="button">
        {props.isEditable && !isLoading && (
          <label htmlFor="image-upload" className={cx({ 'btn-upload': true })}>
            <Sprite
              className={styles.icon}
              name="camera-2"
              rel="img"
              id="upload-image-icon"
              title="Загрузить фото"
            />
            Загрузить фото
          </label>
        )}

        {!props.isEditable && (
          <p className={cx({ 'btn-upload': true })}>
            <Sprite
              className={styles.icon}
              name="camera-2"
              rel="img"
              id="upload-image-icon"
              title="Загрузить фото"
            />
            Фото отсутствует
          </p>
        )}
      </button>
      {isLoading && (
          <AvatarLoader className={styles.loaderIcon} />
        )}
    </>
  );
}

function CardPhoto(props) {
  const [error, setError] = React.useState('');

  function handleDeleteAvatarClick() {
    if (props.isCreate) {
      props.setFieldValue('avatar', null);
      props.setFieldValue('avatarId', null);
    }

    if (!props.isCreate) {
      api
        .patch(`/goats/${props.values.id}/`, { avatarId: null })
        .then(() => {
          props.setFieldValue('avatar', null)
          props.setFieldValue('avatarId', null)
        });
    }
  }

  return (
    <div className={styles.wrapper}>
      {props.emr && <div className={styles.emr}>{props.emr}</div>}

      <div className={cx({ container: true, 'container-empty': !props.values?.avatar })}>
        {props.values?.avatar ? (
          <picture>
            <img className={styles.image} src={props.values.avatar.large} alt="Фотография козы" />
          </picture>
        ) : (
          <Field
            name="avatar"
            setError={setError}
            setFieldValue={props.setFieldValue}
            component={FileField}
            uuid={props.values.id}
            isCreate={props.isCreate}
            isEditable={props.isEditable}
          />
        )}

        {props.values?.avatar && props.isEditable && (
          <ActionButton
            className={cx({ 'btn-remove': true })}
            icon="trash"
            role="button"
            type="button"
            onClick={handleDeleteAvatarClick}
          >
            Удалить
          </ActionButton>
        )}
      </div>

      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
}

export default CardPhoto;
