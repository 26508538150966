import React from 'react';
import Media from 'react-media';
import cn from 'classnames/bind';
import { Formik } from 'formik';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import CardHeader from '../../components/CardHeader/CardHeader';
import ActionButton from '../../components/ActionButton/ActionButton';
import WeighingBlock from '../../components/WeighingBlock/WeighingBlock';
import CardMilkAmounts from '../../components/CardMilkAmounts/CardMilkAmounts';
import CardInfoBlock from '../../components/CardInfoBlock/CardInfoBlock';
import CommentsBlock from '../../components/CommentsBlock/CommentsBlock';
import CardPhoto from '../../components/CardPhoto/CardPhoto';
import BindingBlock from '../../components/BindingBlock/BindingBlock';
import ActionModal from '../../components/ActionModal/ActionModal';
import { notify } from '../../../../components/Toast/Toast';

import styles from './Goat.module.css';
import { GoatsSchema } from '../../../../utils/validations';
import goat from '../../../../api/goat';
import { getCardUUID, getKeysFromUrl } from '../../../../utils/url-parser';
import { convertFormDates } from '../../../../utils/dates';
import api, { goats } from '../../../../utils/api';
import useScrollLock from '../../../../hooks/use-scroll-lock';

const cx = cn.bind(styles);

function checkCreateUrl(url) {
  const [value] = getKeysFromUrl(url, 2);

  return value === 'create';
}

function Goat(props) {
  const url = props.location.pathname;
  const uuid = getCardUUID(url);
  const isCreate = checkCreateUrl(url);
  const { targetRef, targetElement, enableBodyScroll } = useScrollLock();

  const getValues = (uuid) => {
    const requests = [goat.get(uuid), goats.goats_tree(uuid)];
    return Promise.all(requests)
    .then((responses) => Promise.all(responses.map(({data}) => data)))
    .then(([goat, goatsTree]) => {
      
      const fatherObj = goatsTree.result[0].fatherObj;
      const motherObj = goatsTree.result[0].motherObj;
      setInitialValues({
        ...initialValues,
        ...convertFormDates(goat, ['birthDate','deathDate','dateMating','borkedDate','date']),
        fatherObj,
        motherObj,
        avatarId: goat?.avatar?.id
      })
    })
    
  }

  const [initialValues, setInitialValues] = React.useState({
    id: uuid,
    avatar: null,
    avatarId: '',
    tag: '',
    chip: '',
    name: '',
    gender: '',
    isCastrated: null,
    annotation: '',
    birthDate: null,
    dateMating: null,
    status: '',
    grownType: null,
    rejectedType: null,
    color: '',
    breed: '',
    father: '',
    fatherBreed: '',
    motherBreed: '',
    mother: '',
    // isDehydrated: null,
    isHornless: null,
    created: '',
    modified: '',
    weighing: [],
    milkAmounts: [],
    emr: '',
    isDead: null,
    deathСause: '',
    deathDate: '',
    isBorked: null,
    borkedCause: '',
    borkedDate: '',
    statusLive: null,
    fatherObj: null,
    motherObj: null,
    fatherGoat: null,
    motherGoat: null,
    isAdopted: null,
    adopter: '',
    ownerPhotos: [],
    detailedPhotos: []
  });

  React.useEffect(() => {
    if (!isCreate) {
      getValues(uuid).catch(({ response }) => {
        if (response.status) props.history.push('/goats');
      });
    }
    
  }, [isCreate, props.history, uuid]);
  
  const [isEditable, setIsEditable] = React.useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  function bindingBlock(formikProps) {
    return (
      <BindingBlock
        name="emr"
        modalTitle="Выберите номер ЭМК, непривязанный ни к одной карточке козы"
        values={formikProps.values}
        url="electronic-medical-record"
        history={props.history}
        isBinded={formikProps.values.emr}
        role="goat"
        onBindClick={handleBindCard}
        onUnbindClick={handleUnbindCard}
        className={cx({ emr: true })}
        buttonClassName={cx({ 'emr-button': true })}
      />
    );
  }

  const handleSubmitForm = (handleSubmit, values) => (e) => {
    e.preventDefault();
    handleSubmit();

    const isFieldsEmpty = !!!(values.name && values.status && values.gender);

    if (isFieldsEmpty) notify('Пожалуйста, заполните необходимые поля');
  };

  function handleRemoveCard() {
    goat.remove(uuid).then(() => props.history.push('/goats'));
    enableBodyScroll(targetElement);
  }

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  function handleBindCard(id, cb) {
    api.patch(`/goats/${uuid}/`, { emr: id }).then(() => {
      notify(`ЭМК успешно привязана`);
      setInitialValues({ ...initialValues, emr: id });
      cb();
    });
  }

  function handleUnbindCard(cb) {
    api.patch(`/goats/${uuid}/`, { emr: null }).then(() => {
      notify(`ЭМК успешно отвязана`);
      setInitialValues({ ...initialValues, emr: null });
      cb();
    });
  }
  
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={GoatsSchema}
        enableReinitialize
        onSubmit={(values, actions) => {
          if (isEditable) {
            return goat.edit(uuid, values).then(({ data }) => {
              if (values.emr) {
                api.patch(`/emr/${values.emr}/`, { goat: data.id });
              }

              actions.setSubmitting(false);
              props.history.push('/goats');
            });
          }

          if (isCreate) {
            return goat.create(values).then(() => {
              actions.setSubmitting(false);
              props.history.push('/goats');
            });
          }
        }}
        render={(formikProps) => {
          const name = formikProps.values.name;

          return (
            <div className={styles.wrapper}>
              <div className={styles.container}>
                {/* eslint-disable-next-line */}
                <form
                  noValidate
                  className={cx({ form: true, 'form-create': isCreate })}
                  onSubmit={handleSubmitForm(formikProps.handleSubmit, formikProps.values)}
                  onKeyDown={onKeyDown}
                >
                  <CardHeader
                    title={isCreate ? 'Новая карточка' : name}
                    label={
                      <Media query="(min-width: 768px) and (max-width: 1199px)">
                        {(matches) => !matches && !isCreate && bindingBlock(formikProps)}
                      </Media>
                      // !isCreate && (
                      //   <BindingBlock
                      //     name="emr"
                      //     modalTitle="Выберите номер ЭМК, непривязанный ни к одной карточке козы"
                      //     values={formikProps.values}
                      //     url="electronic-medical-record"
                      //     history={props.history}
                      //     isBinded={formikProps.values.emr}
                      //     role="goat"
                      //     onBindClick={handleBindCard}
                      //     onUnbindClick={handleUnbindCard}
                      //   />
                      // )
                    }
                    actions={
                      <>
                        {(isCreate || isEditable) && (
                          <ActionButton
                            className={cx({ 'btn-save': true })}
                            role="button"
                            icon="check-bold"
                            type="submit"
                          >
                            Сохранить
                          </ActionButton>
                        )}

                        {(isCreate || isEditable) && (
                          <ActionButton
                            className={cx({ 'btn-cancel': true })}
                            role="button"
                            icon="close-bold"
                            type="button"
                            fill="#0c1116"
                            onClick={() => setIsCloseModalOpen(true)}
                          >
                            Отменить
                          </ActionButton>
                        )}

                        {!isCreate && !isEditable && (
                          <ActionButton
                            className={cx({ 'btn-edit': true })}
                            role="button"
                            icon="pen"
                            type="button"
                            onClick={() => setIsEditable(true)}
                          >
                            Редактировать
                          </ActionButton>
                        )}

                        {!isCreate && !isEditable && (
                          <ActionButton
                            className={cx({ 'btn-remove': true })}
                            role="button"
                            icon="trash"
                            type="button"
                            onClick={() => setIsDeleteModalOpen(true)}
                          >
                            Удалить
                          </ActionButton>
                        )}
                      </>
                    }
                  />

                  <CardPhoto
                    emr={
                      <Media query="(min-width: 768px) and (max-width: 1199px)">
                        {(matches) => matches && bindingBlock(formikProps)}
                      </Media>
                    }
                    isEditable={isEditable || isCreate}
                    isCreate={isCreate}
                    {...formikProps}
                  />

                  <Media query="(max-width: 767px)">
                    {(matches) =>
                      matches ? (
                        <Tabs className={styles.tabs}>
                          <TabList>
                            <Tab>Информация</Tab>
                            <Tab>Удой</Tab>
                            <Tab>Взвешивания</Tab>
                          </TabList>

                          <TabPanel>
                            <CardInfoBlock isEditable={isEditable || isCreate} {...formikProps} />
                          </TabPanel>

                          <TabPanel>
                            <CardMilkAmounts isEditable={isEditable || isCreate} {...formikProps} />
                          </TabPanel>

                          <TabPanel>
                            <WeighingBlock isEditable={isEditable || isCreate} {...formikProps} />
                          </TabPanel>
                        </Tabs>
                      ) : (
                        <>
                          <div className={cx({ 'blocks-wrapper': true })}>
                            <div>
                              <CardMilkAmounts
                                isEditable={isEditable || isCreate}
                                {...formikProps}
                              />
                            </div>

                            <div>
                              <WeighingBlock isEditable={isEditable || isCreate} {...formikProps} />
                            </div>
                          </div>

                          <CardInfoBlock isEditable={isEditable || isCreate} {...formikProps} />
                        </>
                      )
                    }
                  </Media>
                </form>
              </div>

              {!isCreate && <CommentsBlock uuid={uuid} page="/goats" />}
            </div>
          );
        }}
      />

      <ActionModal
        isOpen={isCloseModalOpen}
        title="Закрыть документ?"
        btnAcceptText="Закрыть"
        btnDeclineText="Продолжить редактирование"
        onClose={() => {
          setIsCloseModalOpen(false);
          enableBodyScroll(targetElement);
        }}
        onAccept={() => {
          props.history.push('/goats');
          enableBodyScroll(targetElement);
        }}
        ref={targetRef}
        target={targetElement}
      >
        Если вы закроете документ, внесенные изменения не сохранятся
      </ActionModal>

      <ActionModal
        isOpen={isDeleteModalOpen}
        title="Вы действительно хотите удалить выбранную карточку?"
        btnAcceptText="Удалить"
        btnDeclineText="Отмена"
        onClose={() => {
          setIsDeleteModalOpen(false);
          enableBodyScroll(targetElement);
        }}
        onAccept={handleRemoveCard}
        ref={targetRef}
        target={targetElement}
      />
    </>
  );
}

export default Goat;
