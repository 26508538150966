import React from 'react';

import Header from '../Header/Header';

import styles from './Layout.module.css';

function Layout(props) {
  return (
    <div className={styles.wrapper}>
      <Header />

      <main className={styles.content}>{props.children}</main>
    </div>
  );
}

export default Layout;
