import React from 'react';
import cn from 'classnames/bind';
import { Formik, Form } from 'formik';

import Container from '../../components/Container/Container';
import ResearchesHeader from '../../components/ResearchesHeader/ResearchesHeader';
import ResearchesSearchTable from '../../components/ResearchesSearchTable/ResearchesSearchTable';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import PageSizeSelect from '../../components/PageSizeSelect/PageSizeSelect';

import styles from './LaboratoryResearches.module.css';
import useResearches from '../../../../hooks/api/use-researches';
import { isObjContains, getPageCount } from '../../../../utils/helpers';

const cx = cn.bind(styles);

function LaboratoryResearches(props) {
  const initialValues = {
    id: '',
    title: '',
    date: null,
    goatChip: '',
    goatName: '',
    modified: null,
    orderBy: '-modified',
    page: '1',
    pageSize: '20',
  };

  const { researches, setResearches } = useResearches();
  const isFilterActive = (values) => {
    if (isObjContains(values, ['id', 'title', 'date', 'goatChip', 'goatName', 'modified']))
      return true;
    else return false;
  };

  function handleSubmit(values, actions) {
    setResearches(values, actions);
  }

  function handlePageClick(data, values, actions) {
    setResearches({ ...values, page: data.selected + 1 }, actions);
  }

  function handleSelectChange({ name, value, setFieldValue }, values, actions) {
    setFieldValue(name, value);
    setResearches({ ...values, pageSize: value }, actions);
  }

  return (
    <Container className={styles.container} wrapperClassName={styles.wrapper}>
      <ResearchesHeader className={styles.header} researches={researches} />

      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        render={({ values, ...actions }) => (
          <Form className={styles.form} noValidate>
            {researches?.results?.[0] || isFilterActive(values) ? (
              <ResearchesSearchTable
                researches={researches}
                history={props.history}
                values={values}
                actions={actions}
                onFilter={handleSubmit}
              >
                <>
                  <div>
                    {researches.results.length ? (
                      <CustomPagination
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        pageCount={getPageCount(researches.count, values.pageSize)}
                        onPageChange={(data) => handlePageClick(data, values, actions)}
                      />
                    ) : null}
                  </div>

                  <PageSizeSelect
                    name="pageSize"
                    values={values}
                    onChange={(e) => handleSelectChange(e, values, actions)}
                    {...actions}
                  />
                </>
              </ResearchesSearchTable>
            ) : (
              <p className={cx({ 'empty-results': true })}>Список лабораторных исследований пуст</p>
            )}
          </Form>
        )}
      />
    </Container>
  );
}

export default LaboratoryResearches;
