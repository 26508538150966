import React from 'react';
import { Link } from 'react-router-dom';

import Sprite from '../../../../components/Sprite';

import styles from './Header.module.css';

function Header() {
  return (
    <header className={styles.header}>
      <Link to="/">
        <Sprite name="logo-auth" className={styles.img} focusable="true" role="img" />
      </Link>
    </header>
  );
}

export default Header;
