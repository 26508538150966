import { useState, useEffect } from 'react';
import { decamelizeKeys } from 'humps';
import qs from 'qs';

import api from '../../utils/api';

const targetTypeArr = (values) => {
  const emr = values.emr && 'emr';
  const goat = values.goat && 'goat';
  const log = values.log && 'log';
  const research = values.research && 'research';

  const arr = [emr, goat, log, research].filter(Boolean);

  return arr;
};

export default () => {
  const [state, setState] = useState({});
  const [count, setCount] = useState(0);

  useEffect(() => {
    api.get('/notifications/').then(({ data }) => setState(data));
    api.get('/notifications/count/').then(({ data }) => setCount(data.count));
  }, []);

  return {
    notifications: state,

    setNotifications: (values) => setState(values),

    submitNotifications: async (values, { setValues, setSubmitting }) => {
      setValues(values);

      let newValues = {
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
        targetType: targetTypeArr(values),
      };

      newValues = newValues |> decamelizeKeys;

      return await api
        .get('/notifications/', {
          params: newValues,
          paramsSerializer: (params) => {
            let str = qs.stringify(params, { arrayFormat: 'brackets' });
            str = str.replace(/%5B%5D/gi, '');
            return str;
          },
        })
        .then(({ data }) => {
          setState(data);
          setSubmitting(false);
        });
    },

    loadNotifications: async (values, { setValues, setSubmitting }) => {
      setValues(values);

      let newValues = {
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
        targetType: targetTypeArr(values),
      };

      const cursor = values.cursor;

      newValues = newValues |> decamelizeKeys;

      return await api
        .get('/notifications/', {
          params: newValues,
          paramsSerializer: (params) => {
            let str = qs.stringify(params, { arrayFormat: 'brackets' });
            str = str.replace(/%5B%5D/gi, '');
            return `${str}${cursor ? `&cursor=${cursor}` : ''}`;
          },
        })
        .then(({ data }) => {
          setState({ ...data, results: [...state?.results, ...data?.results] });
          setSubmitting(false);
        });
    },

    notificationsCount: count,
  };
};
