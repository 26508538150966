import { useState, useEffect } from 'react';
import { decamelizeKeys } from 'humps';

import api from '../../utils/api';
import { removeEmptyAttrsFromObj } from '../../utils/helpers';
import { convertFormDates } from '../../utils/dates';

export default () => {
  const [state, setState] = useState({});
  const [goatsTotal, setGoatsTotal] = useState({});

  const getGoats = (page = 1, pageSize = 20) => {
    api.get(`/table-view/?page_size=${pageSize}&page=${page}`).then(({ data }) => setState(data));
  }
  const getTotalData = () => {
    api.get(`/goats/get_stats/`).then(({data}) => setGoatsTotal(data));
  }
  useEffect(() => {
    getGoats();
    getTotalData();
  }, []);
  
  return {
    goats: state,
    goatsTotal,

    updateGoats: (page, pageSize) => getGoats(page, pageSize),

    setGoats: async (values, { setValues, setSubmitting }) => {
      const keys = [
        'name',
        'chip',
        'house',
        'father',
        'date_mating',
        'start',
        'projected_lamb',
        'days_for_lamb',
        'actual_lamb',
        'total_children',
        'dead_children',
        'goat_male',
        'goat_female'
      ];

      setValues(values);

      values =
        values |> removeEmptyAttrsFromObj |> ((_) => convertFormDates(_, keys)) |> decamelizeKeys;

      return await api
        .get('/table-view/', {
          params: convertFormDates(values, keys),
        })
        .then(({ data }) => {
          setState(data);
          setSubmitting(false);
        });
    },
  };
};
