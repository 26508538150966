import React from 'react';
import { Formik } from 'formik';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AuthForm from '../../containers/AuthForm/AuthForm';
import FieldSet from '../../components/FieldSet/FieldSet';
import TextField from '../../../../components/TextField/TextField';
import PasswordField from '../../../../components/PasswordField/PasswordField';
import CheckboxField from '../../components/CheckboxField/CheckboxField';

import styles from './Login.module.css';
import { users } from '../../../../utils/api';
import { asyncErrors } from '../../../../utils/async-errors';
import { logIn } from '../../../../store/actions/log-in';
import { LoginSchema } from '../../../../utils/validations';

function Login() {
  const initialValues = {
    email: '',
    password: '',
  };

  const token = useSelector((state) => state.logIn.token);
  const dispatch = useDispatch();

  const [isRemember, setIsRemember] = React.useState(false);

  function handleClick() {
    setIsRemember(!isRemember);
  }

  const [headError, setHeadError] = React.useState('');

  function handleSubmit(values, actions) {
    users
      .login({ ...values, isRemember })
      .then(({ token }) => {
        setHeadError('');
        actions.resetForm();
        dispatch(logIn(token));
      })
      .catch(({ response }) => {
        const error = asyncErrors(response?.data?.nonFieldErrors);

        setHeadError(error);
      })
      .finally(() => actions.setSubmitting(false));
  }

  if (token !== null) {
    return <Redirect to="/" />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      render={({ touched, errors, isSubmitting, handleBlur, setFieldValue }) => (
        <AuthForm title="Авторизация" btnText="Войти" isSubmitting={isSubmitting} error={headError}>
          <FieldSet label="Email" className={styles.fieldset}>
            <TextField
              name="email"
              type="email"
              error={touched.email && errors.email}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              removeSpacesOnBlur
            />
          </FieldSet>

          <FieldSet
            label="Пароль"
            className={styles.fieldset}
            link={
              <Link tabIndex="-1" className={styles.link} to="/reset-password">
                Забыли пароль?
              </Link>
            }
          >
            <PasswordField
              name="password"
              error={touched.password && errors.password}
              onBlur={handleBlur}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              removeSpacesOnBlur
            />
          </FieldSet>

          <CheckboxField
            iconName="checkmark"
            label="Запомнить меня"
            className={styles.checkbox}
            onClick={handleClick}
            isRemember={isRemember}
          />
        </AuthForm>
      )}
    />
  );
}

export default Login;
