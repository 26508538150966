import { useState, useEffect } from 'react';
import { decamelizeKeys } from 'humps';

import api from '../../utils/api';
import { convertFormDates } from '../../utils/dates';

export default () => {
  const [state, setState] = useState({});

  useEffect(() => {
    api.get('/research/?page_size=20').then(({ data }) => setState(data));
  }, []);

  return {
    researches: state,

    setResearches: async (values, { setValues }) => {
      const keys = ['date', 'modified'];

      setValues(values);

      values = values |> ((_) => convertFormDates(_, keys)) |> decamelizeKeys;

      return await api
        .get('/research/', {
          params: convertFormDates(values, keys),
        })
        .then(({ data }) => setState(data));
    },
  };
};
