export const enLetter = /[a-zA-Z]/;
export const multiLetter = /[a-zA-Zа-яА-Я\s]+$/;
export const numeric = /[0-9]/;
export const symbol = /[a-zA-Z0-9]/;
export const domain = /(([a-zA-Z\0-9-.]+\.)[a-zA-Z]{2,})/;

export const onlyNumeric = /[^0-9]+$/;
export const symbols = /[!@#$%^&*()-_+=;:,/?\\|`~[\]{}]+$/;
export const onlyLetAndNum = /[^A-zZ-zА-аЯ-я0-9\s]+$/;
export const newOnlyLetAndNum = /^[A-Za-zА-Яа-я0-9\s]*$/;
export const allowedElements = /^[A-Za-z0-9!@#$%^&*()-_+=;:,/?\\|`~[\]{}]+$/;
export const weightNum = /^[0-9]{0,3}([,.][0-9]{0,1})?$/;
export const dosage = /^[0-9]{0,4}([,./][0-9]{0,4})?$/;
export const multiLetterMatch = /^[A-Za-zА-Яа-я\s]*$/;
export const ruLetter = /^[А-Яа-я\s]*$/;
