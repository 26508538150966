import React from 'react';
import { Formik } from 'formik';

import AuthForm from '../../containers/AuthForm/AuthForm';
import FieldSet from '../../components/FieldSet/FieldSet';
import TextField from '../../../../components/TextField/TextField';
import ResetPasswordModal from '../../components/ResetPasswordModal/ResetPasswordModal';

import { users } from '../../../../utils/api';
import styles from './ResetPassword.module.css';
import useScrollLock from '../../../../hooks/use-scroll-lock';
import { ResetPasswordSchema } from '../../../../utils/validations';

function ResetPassword() {
  const initialValues = {
    email: '',
  };
  const { targetRef, targetElement, enableBodyScroll } = useScrollLock();
  const enableScroll = () => enableBodyScroll(targetElement);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  function handleClick() {
    setIsModalOpen(!isModalOpen);
    enableScroll();
  }

  function handleSubmit({ email }, actions) {
    users
      .password()
      .reset({ email })
      .then(() => {
        setIsModalOpen(true);
        actions.resetForm();
      })
      .catch(({ response }) => {
        const errors = response.data.email;

        actions.setFieldError('email', errors[0].detail);
      })
      .finally(() => actions.setSubmitting(false));
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={ResetPasswordSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        render={({ touched, errors, setFieldValue, handleBlur, isSubmitting }) => (
          <AuthForm
            title="Не можете войти в систему?"
            btnText="Отправить"
            isSubmitting={isSubmitting}
          >
            <p className={styles.text}>
              Введите свой зарегистрированный адрес электронной почты, на который будут высланы
              инструкции
            </p>

            <FieldSet label="Email" className={styles.fieldset}>
              <TextField
                name="email"
                type="email"
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                removeSpacesOnBlur
                error={touched.email && errors.email}
              />
            </FieldSet>
          </AuthForm>
        )}
      />

      <ResetPasswordModal
        isOpen={isModalOpen}
        onClose={handleClick}
        ref={targetRef}
        target={targetElement}
      />
    </>
  );
}

export default ResetPassword;
