import React from 'react';
import cn from 'classnames/bind';

import CommentActions from '../CommentActions/CommentActions';

import styles from './Comment.module.css';
import { convertDate } from '../../../../utils/helpers';

const cx = cn.bind(styles);

function Comment(props) {
  const date = convertDate(props.created, 'HH:mm d LLL yyyy');

  return (
    <article className={styles.wrapper}>
      <div className={styles.avatar}>
        <span className={styles.char}>{props.fullName.charAt(0).toUpperCase()}</span>
      </div>

      <p className={styles.name}>{props.fullName}</p>
      <p className={styles.date}>{date}</p>

      {props.isMenuVisible && (
        <CommentActions onRemoveClick={props.onRemoveClick} onEditClick={props.onEditClick} />
      )}

      <p className={styles.comment}>
        {props.text}{' '}
        {props.isEdited && <span className={cx({ 'comment-edited': true })}>(ред.)</span>}
      </p>
    </article>
  );
}

export default Comment;
