import React from "react";
import Modal from "react-modal";
import ButtonClose from "../../../../components/ButtonClose/ButtonClose";
import useScrollLock from "../../../../hooks/use-scroll-lock";
import cn from "classnames/bind";
import styles from "./GoatContentModal.module.css";
import { useLocation } from "react-router";

const cx = cn.bind(styles);

Modal.setAppElement('#root');

function GoatContentModal(props){
  const { disableBodyScroll, targetRef, targetElement, enableBodyScroll } = useScrollLock();
  const {pathname} = useLocation();

  function handleClose() {
    props.onClose();
    enableBodyScroll(targetElement);
  }
  React.useEffect(() => {
    enableBodyScroll(targetElement);
  }, [pathname, targetElement, enableBodyScroll])

  return (
    <Modal
     isOpen={props.isOpen}
     onRequestClose={handleClose}
     className={cx({ modal: true })}
     overlayClassName={cx({ overlay: true })}
     onAfterOpen={() => disableBodyScroll(targetElement)}
     shouldCloseOnOverlayClick
     ref={targetRef}

    >
      <ButtonClose
       onClick={handleClose}
       className={cx({ 'btn-close': true })}
       stroke="var(--dark-gray)"
      />
      {props.children}
    </Modal>
  )
};
export default GoatContentModal;