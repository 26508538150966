import React from 'react';
import Select, { components } from 'react-select';
import cn from 'classnames/bind';

import Sprite from '../../../../components/Sprite';

import styles from './DatesSelect.module.css';

const cx = cn.bind(styles);

const customStyles = {
  container: (provided) => ({
    ...provided,
    borderRadius: '5px',
  }),

  control: (provided, { isFocused }) => ({
    ...provided,
    height: '32px',
    border: '1px solid #cddbe4',
    borderRadius: isFocused ? '5px 5px 0 0' : '5px',
    borderBottomColor: isFocused && 'transparent !important',
    color: 'var(--black)',
    fontSize: '16px',
    backgroundColor: 'var(--white)',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #cddbe4',
      borderBottomColor: isFocused && 'transparent',
    },
  }),

  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    fontWeight: 500,
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 11px',
  }),

  menu: (provided) => ({
    ...provided,
    marginBlockStart: '-1px',
    padding: 0,
    borderRadius: '0 0 5px 5px',
    overflow: 'hidden',
    boxShadow: '0px 30px 30px rgba(5,26,35,0.08)',
    border: '1px solid #d2e1e7',
    borderTop: 0,
  }),

  menuList: (provided) => ({
    ...provided,
    padding: 0,
    '&::-webkit-scrollbar': {
      width: 0,
    },
  }),

  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    minHeight: '30px',
    fontSize: '14px',
    padding: '8px',
    color: 'var(--black)',
    backgroundColor: isSelected ? 'var(--gray)' : isFocused ? 'var(--gray)' : null,
    '&:active': {
      color: 'var(--black)',
      backgroundColor: 'var(--gray)',
    },
  }),
};

function DatesSelect(props) {
  const formRef = React.useRef();

  return (
    <Select
      className={props.className}
      defaultValue={props.defaultValue}
      options={props.options}
      label={props.label}
      isSearchable={false}
      components={{ DropdownIndicator }}
      styles={customStyles}
      // hideSelectedOptions
      ref={formRef}
      onMenuClose={() => formRef.current.blur()}
      onChange={props.onChange}
    />
  );
}

function CaretDownIcon(props) {
  return (
    <Sprite
      className={cx({ icon: true, 'icon-reverse': props.isOpen })}
      name="triangle"
      role="img"
      aria-labelledby="select-triangle"
      title="Открыть список"
    />
  );
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon isOpen={props.selectProps.menuIsOpen} />
    </components.DropdownIndicator>
  );
}

export default DatesSelect;
