import api from '../utils/api';
import { convertFormDates } from '../utils/dates';

export default {
  get(uuid) {
    return api.get(`/research/${uuid}/`);
  },

  create(values) {
    return api.post('/research/', convertFormDates(values, ['date']));
  },

  edit(uuid, values) {
    return api.patch(`/research/${uuid}/`, convertFormDates(values, ['date']));
  },

  remove(uuid) {
    return api.delete(`/research/${uuid}/`);
  },
};
