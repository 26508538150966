import React from 'react';
import cn from 'classnames/bind';

import styles from './Checkbox.module.css';

const cx = cn.bind(styles);

function Checkbox(props) {
  return (
    <p className={cx({ 'checkbox-wrapper': true }, props.className)}>
      <button
        type="button"
        className={cx({ checkmark: true, [props.type || 'check']: true, checked: props.checked })}
        onClick={props.onClick}
      />
    </p>
  );
}

export default Checkbox;
