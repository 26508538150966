import React from "react";
import styles from "./Branch.module.css";

function Branch({children}){
    if (children) {
      return (
        <ul className={styles.branch}>
          {children}
        </ul>
      )
    } else return null;
  };
  export default Branch;
