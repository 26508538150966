import React from 'react';
import cn from 'classnames';

import styles from './ErrorText.module.css';

function ErrorText(props) {
  return <p className={cn(styles.error, props.className)}>{props.children}</p>;
}

export default ErrorText;
