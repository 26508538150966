import React from 'react';
import Media from 'react-media';
import cn from 'classnames/bind';
import useSWR from '@zeit/swr';
import { Link } from 'react-router-dom';

import Container from '../Container/Container';
import Logo from '../Logo/Logo';
import NavList from '../NavList/NavList';
import Sprite from '../../../../components/Sprite';
import UserMenu from '../UserMenu/UserMenu';
import NotificationsMenu from '../NotificationsMenu/NotificationsMenu';

import styles from './Header.module.css';
import axios from '../../../../utils/axios';
import { isAdmin, isObserver } from '../../../../utils/user-role';

const cx = cn.bind(styles);

function Header(props) {
  const { data: user } = useSWR('/users/me/', axios);
  const userRole = user?.data?.role;

  return (
    <header className={styles.header}>
      <Container wrapperClassName={styles.wrapper}>
        <div className={cx({ 'left-block': true })}>
          <Link to="/">
            <Logo />
          </Link>

          <Media query="(max-width: 1170px)">
            <button
              type="button"
              onClick={props.onOpenSidebar}
              className={cx({ 'icon-open': true })}
            >
              <Sprite role="img" name="hamburger" title="Открыть меню" id="hamburger-menu" />
            </button>
          </Media>
        </div>

        <div className={cx({ 'right-block': true })}>
          <Media query="(min-width: 1170px)">
            <NavList />
          </Media>

          <div className={cx({ 'actions-wrapper': true })}>
            {isAdmin(userRole) && <NotificationsMenu />}
            {isObserver(userRole) && <NotificationsMenu />}

            <UserMenu history={props.history} />
          </div>
        </div>
      </Container>
    </header>
  );
}

export default Header;
