import React from 'react';
import cn from 'classnames/bind';

import Sprite from '../../../../components/Sprite';

import styles from './UserControlButton.module.css';

const cx = cn.bind(styles);

function UserControlButton(props) {
  return (
    <button
      type="button"
      className={cx({
        button: true,
        [`button-${props.name}`]: true,
        'button-visible': props.visible,
      })}
      onClick={props.onClick}
    >
      <Sprite
        className={cx({ icon: true, [`icon-${props.name}`]: true })}
        name={props.name}
        role="img"
      />
      {props.children}
    </button>
  );
}

export default UserControlButton;
