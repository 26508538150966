import React from 'react';
import { Link } from 'react-router-dom';

import BaseModal from '../../../../components/BaseModal/BaseModal';
import Sprite from '../../../../components/Sprite';

import styles from './ChangePasswordModal.module.css';
import useScrollLock from '../../../../hooks/use-scroll-lock';

function ChangePasswordModal(props) {
  const { targetRef, targetElement, enableBodyScroll } = useScrollLock();

  return (
    <BaseModal
      isOpen={props.isOpen}
      className={styles.modal}
      ref={targetRef}
      target={targetElement}
    >
      <div className={styles.mark}>
        <Sprite name="check" role="img" className={styles.icon} focusable="false" />
      </div>

      <p className={styles.text}>Пароль успешно изменен</p>

      <Link to="/login" className={styles.link} onClick={() => enableBodyScroll(targetElement)}>
        Войти
      </Link>
    </BaseModal>
  );
}

export default ChangePasswordModal;
