import React from 'react';
import Select, { components } from 'react-select';
import cn from 'classnames/bind';
import { Field } from 'formik';

import Sprite from '../../../../components/Sprite';

import styles from './CustomSelect.module.css';

const cx = cn.bind(styles);

const customStyles = ({
  touched,
  error,
  backgroundColor,
  border,
  borderHover,
  container,
  control,
  menu,
  option,
  menuPlacement,
}) => ({
  container: (provided) => ({
    ...provided,
    borderRadius: '5px',
    ...container,
  }),

  control: (provided, { isFocused }) => ({
    ...provided,
    height: '32px',
    border,
    borderRadius: isFocused ? (menuPlacement === 'top' ? '0 0 5px 5px' : '5px 5px 0 0') : '5px',
    borderBottomColor: isFocused
      ? menuPlacement === 'top'
        ? provided.borderBottomColor
        : 'transparent !important'
      : provided.borderTopColor,
    borderTopColor: isFocused
      ? menuPlacement === 'top'
        ? 'transparent !important'
        : provided.borderTopColor
      : provided.borderTopColor,
    borderColor: touched && error && 'var(--red) !important',
    color: 'var(--black)',
    fontSize: '16px',
    backgroundColor: touched && error ? '#faf5f6' : isFocused ? 'var(--white)' : backgroundColor,
    boxShadow: 'none',
    transition: '0.2s border-color ease-in-out',
    '&:hover': {
      border: !isFocused && borderHover,
      borderBottomColor: isFocused
        ? menuPlacement === 'top'
          ? provided.borderTopColor
          : 'transparent'
        : provided.borderTopColor,
    },
    '@media all and (min-width: 768px)': {
      fontSize: '18px',
      fontWeight: 400,
    },
    ...control,
  }),

  singleValue: (provided) => ({
    ...provided,
    margin: 0,
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 11px',
  }),

  menu: (provided) => ({
    ...provided,
    marginTop: '-1px',
    marginBottom: menuPlacement === 'top' ? 0 : provided.marginBottom,
    padding: 0,
    borderRadius: menuPlacement === 'top' ? '5px 5px 0 0' : '0 0 5px 5px',
    overflow: 'hidden',
    boxShadow: '0px 30px 30px rgba(5,26,35,0.08)',
    border: '1px solid #d2e1e7',
    borderColor: touched && error && 'var(--red)',
    borderTop: menuPlacement === 'top' ? provided.borderTop : 0,
    borderBottom: menuPlacement === 'top' ? 0 : provided.borderBottom,
    ...menu,
  }),

  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),

  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    minHeight: '30px',
    fontSize: '16px',
    padding: '8px',
    color: 'var(--black)',
    backgroundColor:
      touched && error ? '#faf5f6' : isSelected ? 'var(--gray)' : isFocused ? 'var(--gray)' : null,
    '&:hover': {
      color: touched && error ? 'var(--white)' : 'var(--black)',
      backgroundColor: touched && error ? 'var(--red)' : '#F0f5f8',
    },
    '&:active': {
      color: 'var(--black)',
      backgroundColor: 'var(--gray)',
    },
    '@media all and (min-width: 768px)': {
      fontSize: '18px',
    },
    ...option,
  }),
});

function CustomSelect(props) {
  const formRef = React.useRef();

  return (
    <Field
      name={props.name}
      validate={props.validate}
      component={() => (
        <Select
          className={props.className}
          defaultValue={props.defaultValue || [{ value: '', label: 'Не выбрано' }]}
          options={props.options}
          label={props.label}
          isSearchable={false}
          components={{ DropdownIndicator }}
          styles={customStyles({
            touched: props.touched,
            error: props.error,
            menuPlacement: props.menuPlacement,
            ...props.styles,
          })}
          // hideSelectedOptions
          menuPlacement={props.menuPlacement}
          ref={formRef}
          onMenuClose={() => formRef.current.blur()}
          value={props.options && props.options.find(({ value }) => value === props.value)}
          onChange={props.onChange}
          onBlur={() => props.onBlur(props.name, true)}
          menuPortalTarget={props.menuPortalTarget}
          menuIsOpen={props.menuIsOpen}
        />
      )}
    />
  );
}

function CaretDownIcon(props) {
  return (
    <Sprite
      className={cx({ icon: true, 'icon-reverse': props.isOpen })}
      name="triangle"
      role="img"
      aria-labelledby="select-triangle"
      title="Открыть список"
    />
  );
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon isOpen={props.selectProps.menuIsOpen} />
    </components.DropdownIndicator>
  );
}

export default CustomSelect;
