import React from "react";
import useGoatsStats from "../../../../hooks/api/use-goats-stats";
import Container from "../../components/Container/Container";
import StatisticsBlock from "../../components/StatisticsBlock/StatisticsBlock";
import styles from './Statistics.module.css';

function Statistics(props){
  const { goatsStat } = useGoatsStats();
  
  return (
    <Container className={styles.container} wrapperClassName={styles.wrapper}>
      <div className={styles['blocks-wrapper']}>
        <StatisticsBlock
          title="Живых"
          totalGoats={goatsStat.totalAliveGoats}
          totalFemales ={goatsStat.totalAliveFemales}
          totalMales={goatsStat.totalAliveMales}
          totalKids6m={goatsStat.totalAliveKids6m}
          totalKids1y={goatsStat.totalAliveKids1y}

        />
        <StatisticsBlock
          title="Мертвых"
          totalGoats={goatsStat.totalDeadGoats}
          totalFemales ={goatsStat.totalDeadFemales}
          totalMales={goatsStat.totalDeadMales}
          totalKids6m={goatsStat.totalDeadKids6m}
          totalKids1y={goatsStat.totalDeadKids1y}
        />
        <StatisticsBlock
         title="Хорошие руки"
         totalAdoptedFemales={goatsStat.totalAdoptedFemales}
         totalAdoptedMales={goatsStat.totalAdoptedMales}
         totalAdoptedKids6m={goatsStat.totalAdoptedKids}
        />
      </div>
    </Container>
  )
};
export default Statistics;