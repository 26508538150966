import React from 'react';
import DatePicker from 'react-datepicker/es';
import usePortal from 'react-useportal';
import { DateTime } from 'luxon';
import { ru } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';

import './CustomDatePicker.css';

function CustomDatePicker(props) {
  const currentDate = DateTime.local().toFormat('dd.LL.yyyy');
  const { Portal } = usePortal();
  // const pad = (val) => val.toString().padStart(2, '0');
  // const formatDate = (date) => {
  //   if (date) {
  //     const year = date.getFullYear();
  //     const month = pad(date.getMonth() + 1);
  //     const day = pad(date.getDate());

  //     console.log(day);

  //     return `${year}-${month}-${day}`;
  //   }

  //   return '';
  // };

  return (
    <DatePicker
      locale={ru}
      dateFormat="dd.MM.yyyy"
      selected={(props.value && new Date(props.value)) || null}
      onChange={props.onChange}
      // onChange={(value) => props.onChange(formatDate(value))}
      strictParsing
      placeholderText="ДД.ММ.ГГГГ"
      isClearable={false}
      minDate={props.selectedMinDate || new Date('2004-01-01')}
      maxDate={props.selectedMaxDate || null}
      customInput={props.customInput}
      showYearDropdown
      yearDropdownItemNumber={3}
      showMonthDropdown
      disabledKeyboardNavigation
      popperPlacement={props.popperPlacement}
      className={props.className}
      popperContainer={Portal}
      onBlur={props.onBlur}
      popperModifiers={{
        flip: {
          enabled: false,
        },
        // preventOverflow: {
        //   enabled: false,
        //   // escapeWithReference: false,
        // },
      }}
    >
      <p className="react-datepicker__current-date">Сегодня {currentDate}</p>
    </DatePicker>
  );
}

export default CustomDatePicker;
