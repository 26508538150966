import React from 'react';
import cn from 'classnames/bind';
import { Field } from 'formik';

import CustomSelect from '../../components/CustomSelect/CustomSelect';
import PhoneField from '../PhoneField/PhoneField';

import styles from './UserTableCell.module.css';
import { strToPhone } from '../../../../utils/phone-parser';

const cx = cn.bind(styles);

function UserTableCell(props) {
  const isTitle = props.role === 'title';
  const styles = cx({
    cell: true,
    'cell-title': isTitle,
    'cell-checkbox': props.checkbox,
    'cell-edit': props.edit,
    'cell-select': props.select,
  });
  const options = [
    { value: 'admin', label: 'Администратор' },
    { value: 'moderator', label: 'Модератор' },
    { value: 'user', label: 'Пользователь' },
  ];
  const content = () => {
    if (props.edit && props.role === 'select') {
      return (
        <CustomSelect
          name={props.name}
          onBlur={props.actions.setFieldTouched}
          onChange={({ value }) => props.actions.setFieldValue(props.name, value)}
          value={props.value}
          options={options}
          menuPortalTarget={document.body}
          styles={{
            container: {
              height: '100%',
            },
            control: {
              height: '100%',
              padding: '0 8px',
              border: 0,
              borderRadius: 0,
              fontSize: '16px !important',
            },
            menu: {
              border: 0,
            },
            option: {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
          }}
        />
      );
    }

    if (props.edit && props.role === 'input') {
      return (
        <Field type={props.type || 'text'} className={cx({ field: true })} name={props.name} />
      );
    }

    if (props.role === 'phone') {
      if (props.edit)
        return (
          <PhoneField
            className={cx({ field: true })}
            fieldClassName={cx({ 'field-phone': true })}
            name={props.name}
          />
        );
      else return props.children ? strToPhone(props.children) : props.children;
    }

    return props.children;
  };

  if (isTitle) {
    return <th className={styles}>{props.children}</th>;
  } else {
    return <td className={styles}>{content()}</td>;
  }
}

export default UserTableCell;
