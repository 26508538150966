import React from 'react';
import cn from 'classnames/bind';
import { Formik, Form } from 'formik';

import LogHeader from '../../components/LogHeader/LogHeader';
import ActionButton from '../../components/ActionButton/ActionButton';
import LogTable from '../../components/LogTable/LogTable';
import CommentsBlock from '../../components/CommentsBlock/CommentsBlock';
import ActionModal from '../../components/ActionModal/ActionModal';

import api from '../../../../utils/api';
import styles from './Log.module.css';
import useScrollLock from '../../../../hooks/use-scroll-lock';
import { getKeysFromUrl, getCardUUID } from '../../../../utils/url-parser';
import { convertDateToDMY } from '../../../../utils/dates';

const cx = cn.bind(styles);

const convertDateToYMD = (date) => {
  if (date === '' || date === null) return date;
  const [day, month, year] = date.split('.');
  return `${year}-${month}-${day}`;
};

function checkCreateUrl(url) {
  const [value] = getKeysFromUrl(url, 2);

  return value === 'create';
}

const transformDate = (values) => {
  const lines = values.lines.map((row) => {
    const cells = row.cells.map((cell, i) => {
      if (values.cols[i].type === 'date' && cell !== '' && cell !== null) {
        if (cell.includes('.')) return convertDateToYMD(cell);
        if (cell.includes('-')) return convertDateToDMY(cell);
      }

      return cell;
    });

    return { ...row, cells };
  });

  const data = {
    ...values,
    lines,
  };

  return data;
};

const table = {
  cols: [
    { name: 'Дата', type: 'date', readonly: true },
    { name: 'Количество обработанных голов', type: 'integer', readonly: true },
    { name: 'Название препарата', type: 'text', readonly: true },
    { name: 'Доза', type: 'text', readonly: true },
    { name: 'Дополнительная информация', type: 'text', readonly: true },
  ],
  lines: [
    { name: 'Витаминизация поголовья', cells: [null, null, null, null, null] },
    { name: 'Вакцинация', cells: [null, null, null, null, null] },
    { name: 'Противопаразитарные обработки', cells: [null, null, null, null, null] },
  ],
};

function Log(props) {
  const url = props.location.pathname;
  const uuid = getCardUUID(url);
  const isCreate = checkCreateUrl(url);
  const [initialValues, setInitialValues] = React.useState({
    title: '',
    ...table,
  });
  const [isEdit, setIsEdit] = React.useState(isCreate);
  const [isCloseModalOpen, setIsCloseModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const { targetRef, targetElement, enableBodyScroll } = useScrollLock();

  React.useEffect(() => {
    if (!isCreate) {
      api
        .get(`/logs/${uuid}/`)
        .then(({ data }) => setInitialValues(transformDate(data)))
        .catch(({ response }) => {
          if (response.status) props.history.push('/logs');
        });
    }
  }, [isCreate, isEdit, props.history, setInitialValues, uuid]);

  function handleSubmit(values, actions) {
    if (isCreate) {
      api.post('/logs/', transformDate(values)).then(() => {
        actions.setSubmitting(false);
        props.history.push('/logs');
      });
    } else {
      api.patch(`/logs/${uuid}/`, transformDate(values)).then(() => {
        actions.setSubmitting(false);
        props.history.push('/logs');
      });
    }
  }

  function handleRemoveCard() {
    api.delete(`/logs/${uuid}/`).then(() => props.history.push('/logs'));
    enableBodyScroll(targetElement);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          render={({ values, ...actions }) => (
            <>
              <Form className={cx({ form: true, 'form-create': isCreate })} noValidate>
                <LogHeader
                  name="title"
                  values={values}
                  className={styles.header}
                  actions={actions}
                  isEdit={isEdit}
                >
                  {(isCreate || isEdit) && (
                    <ActionButton
                      className={cx({ 'btn-save': true })}
                      role="button"
                      icon="check-bold"
                      type="submit"
                    >
                      Сохранить
                    </ActionButton>
                  )}

                  {(isCreate || isEdit) && (
                    <ActionButton
                      className={cx({ 'btn-cancel': true })}
                      role="button"
                      icon="close-bold"
                      type="button"
                      fill="#0c1116"
                      onClick={() => setIsCloseModalOpen(true)}
                    >
                      Отменить
                    </ActionButton>
                  )}

                  {!isCreate && !isEdit && (
                    <ActionButton
                      className={cx({ 'btn-edit': true })}
                      role="button"
                      icon="pen"
                      type="button"
                      onClick={() => setIsEdit(true)}
                    >
                      Редактировать
                    </ActionButton>
                  )}

                  {!isCreate && !isEdit && (
                    <ActionButton
                      className={cx({ 'btn-remove': true })}
                      role="button"
                      icon="trash"
                      type="button"
                      onClick={() => setIsDeleteModalOpen(true)}
                    >
                      Удалить
                    </ActionButton>
                  )}
                </LogHeader>

                <LogTable
                  values={values}
                  actions={actions}
                  initialValues={initialValues}
                  isEdit={isEdit}
                  table={table}
                />
              </Form>

              <ActionModal
                isOpen={isCloseModalOpen}
                title="Закрыть документ?"
                btnAcceptText="Закрыть"
                btnDeclineText="Продолжить редактирование"
                onClose={() => {
                  setIsCloseModalOpen(false);
                  enableBodyScroll(targetElement);
                }}
                onAccept={() => {
                  props.history.push('/logs');
                  enableBodyScroll(targetElement);
                }}
                ref={targetRef}
                target={targetElement}
              >
                Если вы закроете документ, внесенные изменения не сохранятся
              </ActionModal>

              <ActionModal
                isOpen={isDeleteModalOpen}
                title="Вы действительно хотите удалить выбранный журнал?"
                btnAcceptText="Удалить"
                btnDeclineText="Отмена"
                onClose={() => {
                  setIsDeleteModalOpen(false);
                  enableBodyScroll(targetElement);
                }}
                onAccept={handleRemoveCard}
                ref={targetRef}
                target={targetElement}
              />
            </>
          )}
        />
      </div>

      {!isCreate && <CommentsBlock uuid={uuid} page="/logs" />}
    </div>
  );
}

export default Log;
