import React from 'react';
import cn from 'classnames/bind';
import useSWR from '@zeit/swr';
import { Link } from 'react-router-dom';

import styles from './NavList.module.css';
import axios from '../../../../utils/axios';
import { isUser, isModer, isObserver } from '../../../../utils/user-role';

const cx = cn.bind(styles);

let links = [
  {text: 'Статистика', path: '/statistics'},
  { text: 'Окот', path: '/lamb' },
  { text: 'Список коз', path: '/goats' },
  { text: 'Реестр ЭМК', path: '/electronic-medical-records' },
  { text: 'Список журналов', path: '/logs' },
  { text: 'Лабораторные исследования', path: '/laboratory-researches' },
  { text: 'Пользователи', path: '/users' },
];

function NavList(props) {
  const { data: user } = useSWR('/users/me/', axios);
  const userRole = user?.data?.role;

  if (isUser(userRole) || isModer(userRole) || isObserver(userRole)) {
    links = links.slice(0, 4);
  }

  return (
    <ul className={cx({ list: true }, props.className)}>
      {links.map(({ text, path }, i) => (
        <li className={styles.item} key={i}>
          <Link to={path} onClick={props.onLinkClick} className={styles.link}>
            {text}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default NavList;
