import React from 'react';
import cn from 'classnames/bind';

import Sprite from '../../../../components/Sprite';

import styles from './CheckboxField.module.css';

const cx = cn.bind(styles);

function CheckboxField(props) {
  return (
    <div className={cn(styles.wrapper, props.className)}>
      <input type="checkbox" className={styles.field} value={props.isRemember} />

      <button
        onClick={props.onClick}
        type="button"
        className={cx({
          checkbox: true,
          'checkbox-checked': props.isRemember,
        })}
      >
        {props.isRemember && (
          <Sprite
            name={props.iconName}
            type="img"
            title={props.label}
            focusable="false"
            id="remember-me"
            className={styles.checkmark}
          />
        )}
      </button>

      <p className={styles.label}>{props.label}</p>
    </div>
  );
}

export default CheckboxField;
