import React from 'react';
import Media from 'react-media';
import cn from 'classnames';
import useSWR from '@zeit/swr';

import styles from './CardHeader.module.css';
import axios from '../../../../utils/axios';
import { isAdminOrModer } from '../../../../utils/user-role';

function CardHeader(props) {
  const { data: user } = useSWR('/users/me/', axios);
  const isPersonal = isAdminOrModer(user?.data?.role);

  return (
    <section className={cn(styles.header, props.className)}>
      <div className={styles.container}>
        <div className={styles.info}>
          <h1 className={cn(styles.title)}>{props.title}</h1>

          <Media query="(min-width: 768px)">{props.label}</Media>
        </div>

        {isPersonal && props.actions && <div className={styles.actions}>{props.actions}</div>}
      </div>

      <Media query="(max-width: 767px)">{props.label}</Media>
    </section>
  );
}

export default CardHeader;
